import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Select, Space, Col, Button, Table, Modal, Collapse, Divider } from 'antd';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import '../../../App.css'
import utilsFunction from '../../../utils/utilsFunction';
const { Panel } = Collapse;

var _ = require('lodash');
class PatientCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        data: []
      }],
      options: {},
      isChart: false,
      isGross: true,
      dataSource: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Count',
          dataIndex: 'sum',
          key: 'sum',
        },
        {
          title: '',
          dataIndex: 'result',
          key: 'result',
        },
      ],
      viewModal: false,
      drillDownData: [],
      grossData: []
    };
  }
  componentDidMount() {
    // console.log(this.props, "diddpatientnnndddddddd");
    this.setState({
      grossData: this.props.grossData,
      drillDownData: this.props.drillData
    })
    this.fnCalculateGrossTable(this.props.grossData)
    this.fnCalculateGraph(this.props.grossData)
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      this.setState({
        grossData: this.props.grossData,
        drillDownData: this.props.drillData
      })
      this.fnCalculateGrossTable(this.props.grossData)
      this.fnCalculateGraph(this.props.grossData)
    }
  }
  fnCalculateGraph = (countData) => {

    var filterData = countData.filter((cdata, index) => {
      return cdata.tag == 'Current'
    })

    var countValue = filterData.map(value => (+value.sum));
    var countType = filterData.map(value => value.patient_type);


    var data = [{
      data: countValue
    }]
    this.setState({
      series: data,
      options: {
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },

        tooltip: {
          x: {
            // formatter: function (val) {
            //   return ''
            // },
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          },
          y: {
            formatter: function (val) {
              return val.toLocaleString('en-IN')
            },
            title: {
              formatter: function (seriesName) {
                // return seriesName
                return ""
              }
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '35px',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },

        xaxis: {
          categories: countType,
          labels: {
            style: {
              colors: "green",
              fontSize: '12px'
            }
          }
        }
      },
    })
  }

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  fnCalculateGrossTable = (dataArr) => {

    var dataSource = [];
    var currentData = [], previousData = [];
    // console.log(dataArr, "dataaaarrr");
    dataArr.map((d, index) => {
      if (d.tag == 'Current') {
        currentData.push(d)
      } else {
        previousData.push(d)
      }

    })



    currentData.map((data, index) => {

      var matchData = [];
      previousData.map((old, index) => {

        if (data.patient_type == old.patient_type) {

          matchData.push(old)
        }
      })


      var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />
      // console.log(+data.sum, "jkkkkkkkk", matchData);
      if (matchData.length > 0 && +data.sum < matchData[0].sum) {
        result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
      } else if (matchData.length > 0 && +data.sum == matchData[0].sum) {
        result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
      }
      var dataObj = {
        key: index,
        name: data.patient_type,
        sum: (+data.sum).toLocaleString('en-IN'),
        result: result


      }
      dataSource.push(dataObj)
    })

    this.setState({
      dataSource: dataSource
    })
  }

  // uniqueKeyValues = (arr, key, year, qtr, month) => {

  //   if (key == 'qtr') {
  //     var yearData = arr.filter((obj) => { if (obj.year == year) return obj });
  //     return [... new Set(yearData.map((obj) => { return obj[key] }))];
  //   } else if (key == 'month') {
  //     var monthData = arr.filter((obj) => { if (obj.year == year && obj.qtr == qtr) return obj });
  //     return [... new Set(monthData.map((obj) => { return obj[key] }))];
  //   }
  //   else if (key == 'fn') {
  //     console.log(arr, "arrrrrrrrrkeyyy");
  //     var fnDAta = arr.filter((obj) => {
  //       if (obj.year == year && obj.qtr == qtr && obj.month == month) {
  //         return obj
  //       }
  //     });
  //     console.log(fnDAta, "fnnnnnndaa");
  //     return [... new Set(fnDAta.map((obj) => { return obj[key] }))];
  //   }
  //   return [... new Set(arr.map((obj) => { return obj[key] }))];
  // }


  fnCalculateDrillDownTable = () => {
    var drillArr = this.state.drillDownData.filter((data, index) => {
      return data.tag == 'Current'
    });
    var previosData = this.state.drillDownData.filter((data, index) => {
      return data.tag == 'Old'
    });
    console.log(drillArr, "drilllqqqllll111", this.state.drillDownData, previosData);
    var uniqueYearArr = utilsFunction.uniqueKeyValues(drillArr, 'year')
    console.log(drillArr, "drilllllllllll");
    var finalArr = [];
    var indexValue = 0;
    uniqueYearArr.map((year, index) => {
      indexValue++;

      var uniqueQTROfAYear = utilsFunction.uniqueKeyValues(drillArr, 'qtr', year);
      console.log(uniqueQTROfAYear, "yearrrrrrrrrrr");
      var yearParentObj = {
        key: indexValue,
        name: year,
        sum: (drillArr.reduce((total, obj) => obj.year == year && (+obj.sum) + total, 0)).toLocaleString('en-IN'),
        // result: <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />,
        children: []
      }
      var qtrDataArr = [];

      uniqueQTROfAYear.map((qtr, index) => {
        indexValue++;
        var qtrObj = {
          key: indexValue,
          name: qtr == 1 ? 'QTR1' : 'QTR2',
          sum: drillArr.reduce((total, obj) => obj.year == year && obj.qtr == qtr && (+obj.sum) + total, 0).toLocaleString('en-IN'),
          // result: <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />,
          children: []
        }

        var uniqueMonthOfAQTR = utilsFunction.uniqueKeyValues(drillArr, 'month', year, qtr);
        var qtrChild = []; // for month array
        uniqueMonthOfAQTR.sort().map((monthName, index) => {
          // console.log(obj.year, year, "aaa", obj.qtr, qtr, "uiuiui", obj.month, monthName);
          var monthTotal = 0;
          drillArr.map((obj, index) => {
            if (obj.year == year && obj.qtr == qtr && obj.month == monthName) {
              console.log(obj.sum, "objjjjjjjjjj");
              monthTotal += (+obj.sum)
            }
          })
          indexValue++;
          var monthObj = {
            key: indexValue,
            name: utilsFunction.getMonthName(monthName),

            sum: monthTotal,
            result: '',
            children: []
          }
          qtrChild.push(monthObj)

          var uniqueFNOfMonth = utilsFunction.uniqueKeyValues(drillArr, 'fn', year, qtr, monthName);
          console.log(uniqueFNOfMonth, "fnnnnnnnnnn");
          var FNArr = [];
          uniqueFNOfMonth.sort().map((fnValue, index) => {

            let gfg = _.sumBy(drillArr, function (o) {
              if (o.fn == fnValue)
                return +o.sum;
            });
            console.log(gfg, "dddddddddfffff");
            indexValue++;
            var FNObj = {
              key: indexValue,
              name: fnValue == 1 ? 'Fortnight1' : 'Fortnight2',
              sum: (_.sumBy(drillArr, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr && obj.month == monthName)
                  return +obj.sum;
              })).toLocaleString('en-IN'),
              result: '',
              children: []
            }
            var patientTypeArr = [];
            var currentPatientDAta = this.state.grossData.filter((d, index) => {
              return d.tag == 'Current'
            })
            console.log(this.props.countData, "lklklklklk");
            currentPatientDAta.map((patientType, index) => {
              indexValue++;
              var matchData = previosData.filter((data, index) => {
                if (data.patient_type == patientType.patient_type && data.fn == fnValue && data.year == year && data.qtr == qtr
                  && data.month == monthName) {
                  return data
                }
              })

              var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />
              console.log(matchData.length, "mmmmmmmmmmmmmmmmmmm", matchData.sum);
              var currentSum = _.sumBy(drillArr, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr
                  && obj.month == monthName && obj.patient_type == patientType.patient_type)
                  return +obj.sum;
              })
              if (matchData.length > 0 && currentSum < matchData[0].sum) {
                result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
              }
              if (matchData.length > 0 && currentSum == matchData[0].sum) {
                result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
              }
              var patientType1 = {
                key: indexValue,
                name: patientType.patient_type,
                sum: currentSum > 0 ? currentSum.toLocaleString('en-IN') : 0,
                result: result
              }
              patientTypeArr.push(patientType1)
            })
            FNObj.children = patientTypeArr;
            FNArr.push(FNObj)
          })
          monthObj.children = FNArr
        })

        qtrObj.children = qtrChild
        qtrDataArr.push(qtrObj)
      })
      yearParentObj.children = qtrDataArr


      finalArr.push(yearParentObj)
    })
    console.log(finalArr, "finallllll");
    this.setState({
      dataSource: finalArr
    })

  }


  render() {
    return (


      <div id="chart" style={{ minHeight: 200 }} >

        <Row style={{ marginBottom: 5 }}>
          <Col span={4} style={{ fontWeight: 'bold', fontSize: 15 }}>Patient Count</Col>
          {
            !this.state.isChart &&
            <>
              <Col span={2} >
                <Button className='buttonColor buttonColor2' type="link"

                  onClick={() => {
                    this.setState({
                      isGross: true
                    }, () => {

                      this.fnCalculateGrossTable(this.state.grossData)
                    })
                  }}
                >Gross</Button>
              </Col>
              <Col span={3} >
                <Button className='buttonColor buttonColor2' type="link"

                  onClick={() => {
                    this.setState({
                      isGross: false
                    }, () => {
                      this.fnCalculateDrillDownTable()
                    })
                  }}
                > Drill Down </Button>

              </Col>
            </>
          }

          {/* <Col span={1} offset={this.state.isChart ? 17 : 12}>

            <Button className='buttonColor' type="link" icon={<SearchOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>

          </Col> */}
          <Col span={1} offset={this.state.isChart ? 18 : 13}>
            <Button className='buttonColor' type="link" icon={<TableOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>
          </Col>
          <Col span={1} >
            <Button className='buttonColor' type="link"
              icon={<BarChartOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: true
                })
              }}
            ></Button>

          </Col>
        </Row>

        {
          this.state.isChart ? <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
            : <>
              <Table
                scroll={this.state.dataSource.length > 5 ? {
                  y: 240, scrollToFirstRowOnChange: true
                } : undefined} pagination={false}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                bordered
                dataSource={this.state.dataSource} columns={this.state.columns} size='small' />
            </>
        }

      </div>


    );
  }
}


export default PatientCount;