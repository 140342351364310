import React, { useEffect, useState } from "react";
import { Row, Select, Space, Col, Button, Table, DatePicker, Card } from "antd";
import {
  TableOutlined,
  BarChartOutlined,
  DislikeOutlined,
  LikeOutlined,
  SearchOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import "../../../../App.css";
import CollectionOfDay from "./CollectionOfDay";
import CollectionComparative from "./CollectionComparative";
import moment from "moment";
import GetApiData from "../../../../utils/GetApiData";
import RevenueClassification from "./RevenueClassification";
import DoctorwiseRevenue from "./DoctorwiseRevenue";
import Admission from "./Admission";
import Registration from "./Registration";
import RegistrationComparative from "./RegistrationComparative";
const CEODashboard = ({ apiHost, unitId, currencyInfo }) => {
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visitType_Arr, setVisitTypeArr] = useState([]);
  const [paymentMode_Arr, setPaymentModeArr] = useState([]);
  console.log('currencyInfo', currencyInfo)
  return (
    <>
      <Row gutter={[16, 24]}>
        <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            <CollectionOfDay
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visitTypeArr={visitType_Arr}
              paymentModeArr={paymentMode_Arr}
            ></CollectionOfDay>
          </Card>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            <CollectionComparative
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visit_TypeArr={visitType_Arr}
              paymentMode_Arr={paymentMode_Arr}
            ></CollectionComparative>
          </Card>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            {
              console.log(unitId, "uidggggggg")
            }
            <RevenueClassification
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visit_TypeArr={visitType_Arr}
              paymentMode_Arr={paymentMode_Arr}
            />
          </Card>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            <DoctorwiseRevenue
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visit_TypeArr={visitType_Arr}
              paymentMode_Arr={paymentMode_Arr}
            />
          </Card>
        </Col>
        {/* <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            <Admission
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visit_TypeArr={visitType_Arr}
              paymentMode_Arr={paymentMode_Arr}
            />
          </Card>
        </Col> */}
        {/* <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            <Registration
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visit_TypeArr={visitType_Arr}
              paymentMode_Arr={paymentMode_Arr}
            />
          </Card>
        </Col>
        <Col lg={24} md={24} sm={24}>
          <Card
            className={
              window.innerWidth < 651
                ? "marginBottom col-chart-bg moduleBorder"
                : "col-chart-bg moduleBorder"
            }
            hoverable
          >
            <RegistrationComparative
              apiHost={apiHost}
              fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
              unitId={unitId}
              currencyInfo={currencyInfo}
              visit_TypeArr={visitType_Arr}
              paymentMode_Arr={paymentMode_Arr}
            />
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default CEODashboard;
