import moment from 'moment';
const getDateRange = ({ fromDate, toDate, pickertype }) => {
    //console.log(pickertype, "tyyyyyy", moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'))
    // let datetype = 'YYYY-MM-DD';
    // if (pickertype == 'month') {
    //     datetype = 'YYYY-MM';
    // }
    let uniqueDateRangeArr = [];
    let start, end;

    if (pickertype == 'yesterday' || pickertype == 'date' || pickertype == "lastSevenDays") {
        start = moment(fromDate).format('YYYY-MM-DD')
        end = moment(toDate).format('YYYY-MM-DD');
        //console.log(start, end, "strtutils")
        if (start == end) {
            //console.log(start, end, "stend11")
            uniqueDateRangeArr.push(start);
            // //console.log(uniqueDateRangeArr, "qqqeee")
        }
        else {


            while (start <= end) {
                var newDate = moment(start).add(1, 'days').format('YYYY-MM-DD');
                // //console.log(newDate, "nnnewnextdate")
                uniqueDateRangeArr.push(start);
                start = newDate;
            }

        }
        // //console.log(uniqueDateRangeArr, "fnuniquearrr");

    } else if (pickertype == 'month') {
        start = moment(fromDate).format('YYYY-MM')
        end = moment(toDate).format('YYYY-MM');
        if (start == end) {
            uniqueDateRangeArr.push(moment(fromDate).format('YYYY-MM'));
        }
        else {
            while (start <= end) {

                var newDate1 = moment(start).add(1, 'months').format('YYYY-MM');
                // //console.log(newDate, "nnnewnextdate")
                uniqueDateRangeArr.push(start);
                start = newDate1;
            }
        }

    }
    //console.log(uniqueDateRangeArr, "utillllls")
    if (uniqueDateRangeArr.length > 0) {

        return uniqueDateRangeArr;
    }
}
function thousands_separators(num) {
    if (num > 0 || num !== null) {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // //console.log(num_parts, "nnnnnmmm")
        return num_parts.join(".");
    }
    return 0

}
const getQTR = (month) => {

    var QTRInfo = {
        "01": 1,
        "02": 1,
        "03": 1,
        "04": 2,
        "05": 2,
        "06": 2,
        "07": 3,
        "08": 3,
        "09": 3,
        "10": 4,
        "11": 4,
        "12": 4
    }
    console.log(month, QTRInfo[month], "monthhhhhh");
    return QTRInfo[month];
}

const getMonthName = (index) => {
    var monthArr = {
        1: 'January',
        2: 'February',
        3: 'MArch',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    }

    return monthArr[index]
}



// let apiHost = null;
const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
        var ascii = bString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

const uniqueKeyValues = (arr, key, year, qtr, month) => {

    if (key == 'qtr') {
        var yearData = arr.filter((obj) => { if (obj.year == year) return obj });
        return [... new Set(yearData.map((obj) => { return obj[key] }))];
    } else if (key == 'month') {
        var monthData = arr.filter((obj) => { if (obj.year == year && obj.qtr == qtr) return obj });
        return [... new Set(monthData.map((obj) => { return obj[key] }))];
    }
    else if (key == 'fn') {
        var fnDAta = arr.filter((obj) => { if (obj.year == year && obj.qtr == qtr && obj.month == month) return obj });
        return [... new Set(fnDAta.map((obj) => { return obj[key] }))];
    }
    return [... new Set(arr.map((obj) => { return obj[key] }))];
}
export default {
    getDateRange, thousands_separators, getQTR, getMonthName, base64ToArrayBuffer, uniqueKeyValues
}