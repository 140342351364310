import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Select, Space, Col, Button, Table, Modal, Collapse, Divider } from 'antd';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import '../../../App.css'
import moment from 'moment';
import utilsFunction from '../../../utils/utilsFunction';
const { Panel } = Collapse;
var _ = require('lodash');
class IpBedOccupancy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [],
      options: {},



      isChart: true,
      isGross: true,
      dataSource: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'No of Beds',
          dataIndex: 'noOfBeds',
          key: 'noOfBeds',
        },
        {
          title: 'Available bed days',
          dataIndex: 'availableBedDays',
          key: 'availableBedDays',
        },
        {
          title: 'Occupied Bed\n days',
          dataIndex: 'occupiedBedDays',
          key: 'occupiedBedDays',
        },
        {
          title: 'Occupancy %',
          dataIndex: 'occupancy',
          key: 'occupancy',
        },
        {
          title: 'ALOS(days)',
          dataIndex: 'alos_days',
          key: 'alos_days',
        },
        {
          title: '',
          dataIndex: 'result',
          key: 'result',
        },
      ],
      viewModal: false,
      fromDate: '',
      toDate: '',
      grossData: [],
      drillData: []
    };
  }

  componentDidMount() {
    // console.log(this.props, "diddddddeeeddddippppppp");
    this.setState({
      grossData: this.props.grossData,
      drillData: this.props.drillData,
      fromDate: this.props.fromDate,
      toDate: this.props.toDate
    })
    this.fnCalculateGrossTable(this.props.grossData, this.props.fromDate, this.props.toDate)
    // this.fnCalculateGraph(this.props.grossData)
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      this.setState({
        grossData: this.props.grossData,
        drillData: this.props.drillData,
        fromDate: this.props.fromDate,
        toDate: this.props.toDate
      })
      this.fnCalculateGrossTable(this.props.grossData, this.props.fromDate, this.props.toDate)
      // this.fnCalculateGraph(this.props.grossData)
    }
  }
  // uniqueKeyValues = (arr, key, year, qtr, month) => {

  //   if (key == 'qtr') {
  //     var yearData = arr.map((obj) => { if (obj.year == year) return obj });
  //     return [... new Set(yearData.map((obj) => { return obj[key] }))];
  //   } else if (key == 'month') {
  //     var monthData = arr.map((obj) => { if (obj.year == year && obj.qtr == qtr) return obj });
  //     return [... new Set(monthData.map((obj) => { return obj[key] }))];
  //   }
  //   else if (key == 'fn') {
  //     var fnDAta = arr.map((obj) => { if (obj.year == year && obj.qtr == qtr && obj.month == month) return obj });
  //     return [... new Set(fnDAta.map((obj) => { return obj[key] }))];
  //   }
  //   console.log([... new Set(arr.map((obj) => { return obj[key] }))], "ippppppp");
  //   return [... new Set(arr.map((obj) => { return obj[key] }))];
  // }
  fnCalculateGraph = (countData) => {
    // console.log(countData, "dsssssssssssss");


    // var alosValue = countData.map(value => (+value.alos_days));
    var alosValue = countData.map(value => (+value.alos_days));
    var occupancyPercentage = countData.map(value => (+value.occupancy));
    var countType = countData.map(value => value.name);
    // console.log(countType, "ooooooooooo");
    this.setState({
      series: [{
        name: 'ALOS(days)',

        type: 'column',
        data: alosValue
      }, {
        name: 'Occupancy %',
        type: 'line',
        data: occupancyPercentage
      }],
      options: {
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        chart: {
          height: 350,
          type: 'line',
        },
        plotOptions: {
          bar: {
            columnWidth: '35px'
          }
        },
        stroke: {
          width: [0, 4]
        },
        // title: {
        //   text: 'Traffic Sources'
        // },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],

        },
        labels: countType,

        yaxis: [{
          title: {
            text: 'ALOS(days)'
          },

        }, {
          opposite: true,
          title: {
            text: 'Occupancy %',

          }
        }]
      }
    })
  }

  fnCalculateGrossTable = (dataArr, fromDate, toDate) => {
    var dataSource = [];
    var currentData = [], previousData = []
    // console.log(dataArr, "ddddacallll");
    dataArr.map((d, index) => {
      if (d.tag == 'Current') {
        currentData.push(d)
      } else {
        previousData.push(d)
      }

    })

    // console.log(previousData, currentData, "aqwqwq");

    currentData.map((data, index) => {
      var matchData = [];
      previousData.length > 0 && previousData.map((old, index) => {

        if (data.billing_bed_desc == old.billing_bed_desc) {

          matchData.push(old)
        }
      })


      var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />
      console.log(result, "rrrrrrrrrrrr");
      if (matchData.length > 0 && +data.sum < matchData[0].sum) {
        result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
      }
      else if (matchData.length > 0 && +data.sum == matchData[0].sum) {
        result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
      }
      var startDate = moment(fromDate, "YYYY-MM-DD");
      var endDate = moment(toDate, "YYYY-MM-DD");

      var daysDiff = +endDate.diff(startDate, 'days') + 1;

      // console.log(fromDate, toDate, "datttttteeee", daysDiff);

      // var availableBedDays = date diff* no of bed;
      var availableBedDays = daysDiff * (+data.no_of_beds);
      // occupancyPercentage = occupied bed days * 100 / (date diff * no of bed)
      var occupancyPercentage = parseFloat((+data.occupied_bed_days) * 100 / (daysDiff * +data.no_of_beds)).toFixed(2)


      var dataObj = {
        key: index,
        name: data.billing_bed_desc,
        noOfBeds: +data.no_of_beds,
        availableBedDays: availableBedDays,
        occupiedBedDays: +data.occupied_bed_days,
        occupancy: +occupancyPercentage,
        alos_days: parseFloat((+data.alos) / (+data.no_of_beds)).toFixed(2),

        result: result
      }
      dataSource.push(dataObj)
    })
    this.fnCalculateGraph(dataSource)
    this.setState({
      dataSource: dataSource
    })
  }

  fnCalculateDrillDownTable = (fromDate, toDate) => {
    var drillArr = this.state.drillData.filter((data, index) => {
      return data.tag == 'Current'
    });
    var previosData = this.state.drillData.filter((data, index) => {
      return data.tag == 'Old'
    });
    // console.log(dataArr, "drilllqqqllll111", this.state);
    var uniqueYearArr = utilsFunction.uniqueKeyValues(drillArr, 'year')
    console.log(drillArr, "drilllllllippppllll");
    var finalArr = [];
    var indexValue = 0;
    uniqueYearArr.map((year, index) => {
      indexValue++;

      var uniqueQTROfAYear = utilsFunction.uniqueKeyValues(drillArr, 'qtr', year);
      var startDate = moment(fromDate, "YYYY-MM-DD");
      var endDate = moment(toDate, "YYYY-MM-DD");

      var daysDiff = +endDate.diff(startDate, 'days') + 1;


      var yearlyNo_of_Beds = drillArr.reduce((total, obj) => obj.year == year && (+obj.no_of_beds) + total, 0);
      var yearly_occupied_bed_days = drillArr.reduce((total, obj) => obj.year == year && (+obj.occupied_bed_days) + total, 0)
      var availableBedDays = daysDiff * (yearlyNo_of_Beds);


      var occupancyPercentage = parseFloat(yearly_occupied_bed_days * 100 /
        (daysDiff * yearlyNo_of_Beds)
      ).toFixed(2)


      var yearParentObj = {
        key: indexValue,
        name: year,

        noOfBeds: yearlyNo_of_Beds,
        availableBedDays: availableBedDays,
        occupiedBedDays: yearly_occupied_bed_days,
        occupancy: occupancyPercentage,

        alos_days: parseFloat((drillArr.reduce((total, obj) => obj.year == year && (+obj.alos) + total, 0)) /
          (yearlyNo_of_Beds)).toFixed(2),
        // result: <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />,
        children: []
      }
      var qtrDataArr = [];

      uniqueQTROfAYear.map((qtr, index) => {
        indexValue++;
        var QTRWiseNo_of_Beds = drillArr.reduce((total, obj) => obj.year == year && obj.qtr == qtr && (+obj.no_of_beds) + total, 0);
        var QTRWise_occupied_bed_days = drillArr.reduce((total, obj) => obj.year == year && obj.qtr == qtr && (+obj.occupied_bed_days) + total, 0)
        var QTRWise_availableBedDays = daysDiff * (QTRWiseNo_of_Beds);

        var QTRWiseoccupancyPercentage = parseFloat(QTRWise_occupied_bed_days * 100 /
          (daysDiff * QTRWiseNo_of_Beds)
        ).toFixed(2)

        var qtrObj = {
          key: indexValue,
          name: qtr == 1 ? 'QTR1' : 'QTR2',
          noOfBeds: QTRWiseNo_of_Beds,
          availableBedDays: QTRWise_availableBedDays,
          occupiedBedDays: QTRWise_occupied_bed_days,
          occupancy: QTRWiseoccupancyPercentage,
          alos_days: parseFloat((drillArr.reduce((total, obj) => obj.year == year &&
            obj.qtr == qtr && (+obj.alos) + total, 0)) /
            (QTRWiseNo_of_Beds)).toFixed(2),
          children: []
        }

        var uniqueMonthOfAQTR = utilsFunction.uniqueKeyValues(drillArr, 'month', year, qtr);
        var qtrChild = []; // for month array
        uniqueMonthOfAQTR.sort().map((monthName, index) => {
          indexValue++;
          var month_wiseNo_of_Beds = 0, monthWise_occupied_bed_days = 0, monthWise_availableBedDays = 0,
            monthWiseoccupancyPercentage = 0;
          drillArr.map((obj, index) => {
            if (obj.year == year && obj.qtr == qtr && obj.month == monthName) {
              month_wiseNo_of_Beds += (+obj.no_of_beds);
              monthWise_occupied_bed_days += (+obj.occupied_bed_days)
            }
          })
          // var month_wiseNo_of_Beds = drillArr.reduce((total, obj) => obj.year == year &&
          //   obj.qtr == qtr && obj.month == monthName && (+obj.no_of_beds) + total, 0);

          // var monthWise_occupied_bed_days = drillArr.reduce((total, obj) => obj.year == year
          //   && obj.qtr == qtr && obj.month == monthName && (+obj.occupied_bed_days) + total, 0)

          monthWise_availableBedDays = daysDiff * (month_wiseNo_of_Beds);

          monthWiseoccupancyPercentage = parseFloat(monthWise_occupied_bed_days * 100 /
            (daysDiff * month_wiseNo_of_Beds)
          ).toFixed(2)

          var monthObj = {
            key: indexValue,
            name: utilsFunction.getMonthName(monthName),
            noOfBeds: month_wiseNo_of_Beds,
            availableBedDays: monthWise_availableBedDays,
            occupiedBedDays: monthWise_occupied_bed_days,
            occupancy: monthWiseoccupancyPercentage,
            alos_days: parseFloat((drillArr.reduce((total, obj) => obj.year == year &&
              obj.qtr == qtr && (+obj.alos) + total, 0)) /
              (month_wiseNo_of_Beds)).toFixed(2),
            result: '',
            children: []
          }
          qtrChild.push(monthObj)

          var uniqueFNOfMonth = utilsFunction.uniqueKeyValues(drillArr, 'fn', year, qtr, monthName);
          // console.log(uniqueFNOfMonth, "fnnnnnnnnnn");
          var FNArr = [];
          uniqueFNOfMonth.map((fnValue, index) => {

            var fn_wiseNo_of_Beds = 0, fnWise_occupied_bed_days = 0;
            var fnWisetotalALOSDays_apivalue = 0;
            drillArr.map((obj, index) => {
              if (obj.year == year && obj.qtr == qtr && obj.month == monthName && obj.fn == fnValue) {
                console.log(obj, "bedddssss");
                fn_wiseNo_of_Beds += (+obj.no_of_beds);
                fnWise_occupied_bed_days += (+obj.occupied_bed_days);
                fnWisetotalALOSDays_apivalue += (+obj.alos);

              }
            })

            // console.log(fnWisetotalALOSDays_apivalue,"apiiiiiiiiiiiiiiiiiiii",fn_wiseNo_of_Beds,fnWisetotalALOSDays_apivalue/fn_wiseNo_of_Beds);


            var fnWise_availableBedDays = daysDiff * (fn_wiseNo_of_Beds);

            var fnWiseoccupancyPercentage = parseFloat(fnWise_occupied_bed_days * 100 /
              (daysDiff * fn_wiseNo_of_Beds)
            ).toFixed(2)





            indexValue++;
            var FNObj = {
              key: indexValue,
              name: fnValue == 1 ? 'Fortnight1' : 'Fortnight2',

              noOfBeds: fn_wiseNo_of_Beds,
              availableBedDays: fnWise_availableBedDays,
              occupiedBedDays: fnWise_occupied_bed_days,
              occupancy: fnWiseoccupancyPercentage,
              alos_days: parseFloat(fnWisetotalALOSDays_apivalue / fn_wiseNo_of_Beds).toFixed(2),
              result: '',
              children: []
            }
            var expensesTypeArr = [];
            var currentPatientDAta = this.state.grossData.filter((d, index) => {
              return d.tag == 'Current'
            })
            // console.log(this.props.countData, "lklklklklk");
            currentPatientDAta.map((expensesType, index) => {
              indexValue++;
              var matchData = previosData.filter((data, index) => {
                if (data.category == expensesType.category && data.fn == fnValue && data.year == year && data.qtr == qtr
                  && data.month == monthName) {
                  return data
                }
              })

              var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />
              // console.log(matchData.length, "mmmmmmmmmmmmmmmmmmm", matchData.sum);
              var currentSum = _.sumBy(drillArr, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr
                  && obj.month == monthName && obj.category == expensesType.category)
                  return +obj.sum;
              })
              if (matchData.length > 0 && currentSum < matchData[0].sum) {
                result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
              }
              if (matchData.length > 0 && currentSum == matchData[0].sum) {
                result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
              }
              var category_wiseNo_of_Beds = 0, categoryWise_occupied_bed_days = 0, categoryWiseALOSAPivalue = 0;

              drillArr.map((obj, index) => {
                if (obj.year == year && obj.qtr == qtr && obj.month == monthName && obj.fn == fnValue
                  && obj.billing_bed_desc == expensesType.billing_bed_desc
                ) {
                  console.log(obj, "bedddssss");
                  category_wiseNo_of_Beds += (+obj.no_of_beds);
                  categoryWise_occupied_bed_days += (+obj.occupied_bed_days);
                  categoryWiseALOSAPivalue += (+obj.alos);
                }
              })




              var categoryWise_availableBedDays = daysDiff * (category_wiseNo_of_Beds);

              var categoryWiseoccupancyPercentage = parseFloat(categoryWise_occupied_bed_days * 100 /
                (daysDiff * category_wiseNo_of_Beds)
              ).toFixed(2)
              console.log(expensesType, "opoeddddd");
              var expensesTypeObj = {
                key: indexValue,
                name: expensesType.billing_bed_desc,

                noOfBeds: category_wiseNo_of_Beds,
                availableBedDays: categoryWise_availableBedDays,
                occupiedBedDays: categoryWise_occupied_bed_days,
                occupancy: categoryWiseoccupancyPercentage,
                alos_days: parseFloat(categoryWiseALOSAPivalue / category_wiseNo_of_Beds).toFixed(2),
                result: result
              }
              expensesTypeArr.push(expensesTypeObj)
            })
            FNObj.children = expensesTypeArr;
            FNArr.push(FNObj)
          })
          monthObj.children = FNArr
        })

        qtrObj.children = qtrChild
        qtrDataArr.push(qtrObj)
      })
      yearParentObj.children = qtrDataArr


      finalArr.push(yearParentObj)
    })
    // console.log(finalArr, "finallllll");
    this.setState({
      dataSource: finalArr
    })
  }


  render() {
    return (


      <div id="chart" style={{ minHeight: 251 }}>

        <Row>
          <Col span={3} style={{ fontWeight: 'bold', fontSize: 15 }}>Ip Bed Occupancy</Col>
          {
            // !this.state.isChart &&
            <>
              <Col span={1}>
                <Button className='buttonColor buttonColor2' type="link"

                  onClick={() => {
                    this.setState({
                      isGross: true
                    }, () => {
                      this.fnCalculateGrossTable(this.state.grossData, this.state.fromDate, this.state.toDate)
                    })
                  }}
                >Gross</Button>
              </Col>
              <Col >
                <Button className='buttonColor buttonColor2' type="link"

                  onClick={() => {
                    this.setState({
                      isGross: false
                    }, () => {
                      this.fnCalculateDrillDownTable(this.state.fromDate, this.state.toDate)
                    })
                  }}
                > Drill Down </Button>

              </Col>
            </>
          }


        </Row>


        <Row>
          <Col span={11} >
            <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
          </Col>
          <Col span={12}
            offset={1}
          >
            <Table
              bordered scroll={this.state.dataSource.length > 5 ? {
                y: 250, scrollToFirstRowOnChange: true
              } : undefined} pagination={false}
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
              dataSource={this.state.dataSource} columns={this.state.columns} size='small' />
          </Col>
        </Row>
      </div>


    );
  }
}


export default IpBedOccupancy;