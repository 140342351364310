import React, { useState, useEffect } from 'react';
import Maincontainer from './Component/Header/Maincontainer'
// import FilterForm from "./FilterForm";
import './App.css';
import GetApiData from "./utils/GetApiData";
import Login from './Component/Login/Login';
import MisReport from './Component/Mis/MisReport';

const App = ({ _isAlreadyLogin = false, _isLogin = false }) => {

  const [unitList, setUnitList] = useState([]);
  // const [currencyInfo, setCurrencyInfo] = useState('₹');
  const [currencyInfo, setCurrencyInfo] = useState(null);
  const [organizationName, setORganisationName] = useState(null);
  const [organizationId, setOrganizationId] = useState();
  const windowHost = window.location.origin;
  const apiHost = windowHost.includes('localhost') ? 'http://localhost:8081' : windowHost;
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {

    fnGetOrgName()

  }, [])

  const fnGetOrgName = async () => {
    if (localStorage.getItem("isLogin") == 'yes') {
      setIsLogin(true)
    }

    await GetApiData.getOrganizationName({ apiHost }).
      then(async (data) => {
        setORganisationName(data && data[0] && data[0].organizationName ? data[0].organizationName : null)
        setOrganizationId(data[0].organizationId)
        await GetApiData.getUnitList({
          organizationId: data[0].organizationId,
          apiHost
        }).then(async (unitList) => {
          setUnitList(unitList);
          let unitId = unitList[0].unitid;
          await GetApiData.getCurrencyDetail({
            unitId,
            apiHost
          }).then((curr) => {

            setCurrencyInfo(curr)

          }).catch(e => {

          })
        })
      }).catch(e => {
        console.log();
      })
  }










  const fnLogout = () => {

    setIsLogin(false);

    localStorage.clear()

  }

  const fnLoginSuccess = () => {
    setIsLogin(true);
  }






  return (
    <>
      <>
        {/* <MisReport apiHost={apiHost} ></MisReport> */}

      </>
      {
        // localStorage.getItem("isLogin") == "no" && !_isAlreadyLogin && unitList.length > 0 &&
        (localStorage.getItem("isLogin") == "no" || isLogin == false) && organizationName !== null && unitList.length > 0 &&
        < Login
          organizationId={organizationId}
          organizationName={organizationName}
          apiHost={apiHost}
          unitList={unitList}

          fn_LoginSuccess={fnLoginSuccess}
        />

      }

      {
        localStorage.getItem("isLogin") == "yes" && isLogin && currencyInfo != null &&
        < div className="mainContianer">
          <Maincontainer fnLogout={fnLogout} unitList={unitList} apiHost={apiHost} currencyInfo={currencyInfo}>

          </Maincontainer>





        </div>
      }


    </>
  )
}

export default App;