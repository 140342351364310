import React, { useEffect, useState } from 'react';
import utilsFunction from '../../utils/utilsFunction';
import { Statistic, Row, Col, Card, Collapse, Select } from 'antd';
import { BellOutlined, DownCircleOutlined, UserOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import PatientCount from './New/PatientCount';
import '../../App.css'
import RevenueAndCollection from './New/RevenueAndCollection';
import Expenses from './New/Expenses';

import IpBedOccupancy from './New/IpBedOccupancy';
import PatientExperience from './New/PatientExperience';
import AegingAnalysis from './New/AegingAnalysis';
import TopTwenty from './New/TopTwenty';


const { Panel } = Collapse;



const DashboardOverview = ({ data, fromDate, toDate, apiHost, filterCase, unitId, currencyInfo }) => {
        // console.log(data, "lklklkkj");
        const [dataArr, setData] = useState(data)
        useEffect(() => {
                setData(data)
        }, [data])
        return (
                <>
                        {/* <div className={window.innerWidth < 651 ? "TopBox topBox" : "topBox"} >

                        </div>
                        <br></br> */}
                        <Row gutter={16}>
                                <Col span={24}>
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg" : "col-chart-bg"} hoverable>
                                                {/* <RevenueAndCollection grossData={dataArr.revenueCollectionData.gross}
                                                        drillData={dataArr.revenueCollectionData.drillDown}
                                                // RCData={data.revenueAndCollection}
                                                >

                                                </RevenueAndCollection> */}
                                                <TopTwenty fromDate={fromDate} toDate={toDate}
                                                        apiHost={apiHost} unitId={unitId} currencyInfo={currencyInfo}
                                                >

                                                </TopTwenty>

                                        </Card>
                                </Col>
                        </Row>
                        <br></br>
                        <Row gutter={16}>

                                <Col span={window.innerWidth < 651 ? 24 : 12} >
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg moduleBorder" : "col-chart-bg moduleBorder"} hoverable>

                                                <PatientCount grossData={dataArr.patientCountData.gross}
                                                        drillData={dataArr.patientCountData.drillDown}>
                                                                currencyInfo={currencyInfo}
                                                </PatientCount>


                                        </Card>
                                </Col>
                                <Col span={window.innerWidth < 651 ? 24 : 12}>
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg" : "col-chart-bg"} hoverable>
                                                <Expenses grossData={dataArr.expensesData.gross} drillData={dataArr.expensesData.drillDown}
                                                currencyInfo={currencyInfo}
                                                ></Expenses>

                                        </Card>
                                </Col>
                        </Row>
                        <br></br>
                        <Row gutter={16}>
                                <Col span={24}>
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg" : "col-chart-bg"} hoverable>
                                                <RevenueAndCollection grossData={dataArr.revenueCollectionData.gross}
                                                        drillData={dataArr.revenueCollectionData.drillDown}
                                                        currencyInfo={currencyInfo}
                                                // RCData={data.revenueAndCollection}
                                                >

                                                </RevenueAndCollection>


                                        </Card>
                                </Col>
                        </Row>
                        <br></br>
                        <Row gutter={16}>
                                <Col span={24}>
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg" : "col-chart-bg"} hoverable>
                                                <IpBedOccupancy grossData={dataArr.IpBedOccupancyData.gross}
                                                        drillData={dataArr.IpBedOccupancyData.drillDown}
                                                        fromDate={fromDate}
                                                        toDate={toDate}
                                                        currencyInfo={currencyInfo}
                                                >

                                                </IpBedOccupancy>


                                        </Card>
                                </Col>
                        </Row>
                        <br></br>
                        <Row gutter={16}>
                                <Col span={window.innerWidth < 651 ? 24 : 24}>
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg" : "col-chart-bg"} hoverable>
                                                <PatientExperience grossData={dataArr.patientExperianceData.gross}
                                                        drillData={dataArr.patientExperianceData.drillDown}
                                                        currencyInfo={currencyInfo}
                                                        >
                                                </PatientExperience>




                                        </Card>
                                </Col>
                        </Row>
                        <br></br>
                        <Row gutter={16}>
                                <Col span={window.innerWidth < 651 ? 24 : 24}>
                                        <Card className={window.innerWidth < 651 ? "marginBottom col-chart-bg" : "col-chart-bg"} hoverable>

                                                <AegingAnalysis apiHost={apiHost} filterCase={filterCase}
                                                        unitId={unitId}
                                                        currencyInfo={currencyInfo}
                                                >

                                                </AegingAnalysis>



                                        </Card>
                                </Col>
                        </Row>
                        <br></br>



                </>
        )
}
export default DashboardOverview