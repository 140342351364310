import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Select, Space, Col, Button, Table, Modal, Collapse, Divider, DatePicker } from 'antd';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import '../../../App.css'
import utilsFunction from '../../../utils/utilsFunction';
import moment from 'moment';
import GetApiData from '../../../utils/GetApiData';
import LoadingSvg from '../../../Layout/LoadingSvg';
const { Panel } = Collapse;
var _ = require('lodash');
class AegingAnalysis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [],
      options: {},
      isChart: false,
      isGross: true,
      dataSource: [],
      columns: [
        {
          title: 'Ageing Category',
          dataIndex: 'ageingCatetory',
          key: 'ageingCatetory',
        },
        {
          title: '0-30',
          dataIndex: '0-30',
          key: '0-30',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          ) 
        },
        {
          title: '30-60',
          dataIndex: '30-60',
          key: '30-60',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
        },
        {
          title: '60-90',
          dataIndex: '60-90',
          key: '60-90',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
        },
        {
          title: '90-120',
          dataIndex: '90-120',
          key: '90-120',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
        },
        {
          title: '120-360',
          dataIndex: '120-360',
          key: '120-360',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
        },
        {
          title: '360+',
          dataIndex: '360+',
          key: '360+',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
        },

      ],
      viewModal: false,
      grossData: [],
      drillData: [],
      selectedDate: moment(new Date()).format("YYYY-MM-DD"),
      filterCase: {},
      resAegingAnalysis: [],
      showLoader: false,
      chartData : {
        options: {
          chart: {
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          xaxis: {
            // categories: ['January', 'February', 'March', 'April', 'May'],
            categories: ['Customer (Receivable)', 'Supplier (Payable)']
            
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
          },
        },
        series: [
          {
            name: 'Dataset 1',
            data: [10, 20, 30, 40, 50],
          },
          {
            name: 'Dataset 2',
            data: [5, 15, 25, 35, 45],
          },
          {
            name: 'Dataset 3',
            data: [15, 25, 35, 45, 55],
          },
        ],
      }
    };
  }

  componentDidMount() {
    console.log(this.props.currencyInfo,"aeginnnnn");
    this.fnGetReport(this.state.selectedDate,)
  }



  onChange = (date, dateString) => {
    console.log(dateString, "seeeellll");
    this.setState({
      selectedDate: moment(date).format("YYYY-MM-DD")
    })
  };

  fnGetReport = async (selectedDate) => {
    // console.log("updattttttte", this.props, ...this.state.selectedDate)
    this.setState({
      showLoader: true
    })
    var propsData = this.props
    var resAegingAnalysis = await GetApiData.fnAegingAnalysis({ selectedDate, propsData });
    this.setState({
      resAegingAnalysis: resAegingAnalysis
    })
    this.fnCalculateGrossTable(resAegingAnalysis);


  }

  fnCalculateGraph = (dataArr, currency) => {
    console.log(dataArr,"dattaarrrr");
    var series = [
      {
        name: '0-30',
        data: [dataArr[0]['0-30'], dataArr[1]['0-30']]
      }, {
        name: '30-60',
        data: [dataArr[0]['30-60'], dataArr[1]['30-60']]
      },
      {
        name: '60-90',
        data: [dataArr[0]['60-90'], dataArr[1]['60-90']]
      },
      {
        name: '90-120',
        data: [dataArr[0]['90-120'], dataArr[1]['90-120']]
      },
      {
        name: '120-360',
        data: [dataArr[0]['120-360'], dataArr[1]['120-360']]
      },
      {
        name: '360+',
        data: [dataArr[0]['360+'], dataArr[1]['360+']]
      }
    ]

    var chartData = {
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          },
          barHeight: '35px',
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: ''
      },
      xaxis: {
        // categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        categories: ['Customer (Receivable)', 'Supplier (Payable)'],
        labels: {
          formatter: function (val) {
            // return val + "K"
            return val
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return currency +" "+ val
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
    var options = {
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          },
          barHeight: '35px',
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: ''
      },
      xaxis: {
        // categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        categories: ['Customer (Receivable)', 'Supplier (Payable)'],
        labels: {
          formatter: function (val) {
            // return val + "K"
            return val
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return currency +" "+ val
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    }

    this.setState({
      series: series,
      options: options,
      showLoader: false
    })
  }



  fnCalculateGrossTable = (dataArr) => {

    if (dataArr.company && dataArr.company.length > 0) {
      var thirty = 0, sixty = 0, ninty = 0, one_20 = 0, three_60 = 0, moreThan_360 = 0;
      var _thirty = 0, _sixty = 0, _ninty = 0, _one_20 = 0, _three_60 = 0, _moreThan_360 = 0;
      dataArr.company.map((data, index) => {
        thirty += (+data['0-30']);
        // console.log(data['360 +'], "dddddddddddddddaaa", data);

        sixty += data['30-60'];
        ninty += data['60-90'];
        one_20 += data['90-120'];
        three_60 += data['120-360'];
        moreThan_360 += data['360 +']
      })

      dataArr.supplier.map((data, index) => {
        _thirty += (+data['0-30']);
        // console.log(data['360 +'], "dddddddddddddddaaa", data);

        _sixty += data['30-60'];
        _ninty += data['60-90'];
        _one_20 += data['90-120'];
        _three_60 += data['120-360'];
        _moreThan_360 += data['360 +']
      })

      var dataSource = [
        {
          key: '1',
          ageingCatetory: 'Customer (Receivable)',
          '0-30': (thirty),
          '30-60': (sixty),
          '60-90': (ninty),
          '90-120': (one_20),
          '120-360': (three_60),
          '360+': (moreThan_360)
        },
        {
          key: '2',
          ageingCatetory: 'Supplier (Payable)',
          '0-30': _thirty,
          '30-60': _sixty,
          '60-90': _ninty,
          '90-120': _one_20,
          '120-360': _three_60,
          '360+': _moreThan_360
        },

      ]
      // console.log(dataSource, "lklkliiuiu");
      this.setState({
        dataSource: dataSource
      })
      // console.log(this.props,"jkyyuyuyuy");
      this.fnCalculateGraph(dataSource, this.props.currencyInfo)
    }





  }
  render() {
    return (


      <div id="chart" style={{ minHeight: 200 }}>

        <Row>
          <Col span={4} style={{ fontWeight: 'bold', fontSize: 15 }}>Ageing Analysis</Col>
          <Col>
            <DatePicker
              placeholder={this.state.selectedDate}
              onChange={this.onChange} />
            <Button
              onClick={() => { this.fnGetReport(this.state.selectedDate) }}
              className="secondDD thirdDD rangePicker"
              style={{
                width: 120, marginLeft: 15, background: '#23E4A3',
                fontWeight: 500
              }}
            >
              Get Report</Button>
          </Col>
         

          <Col
            // offset={this.state.isChart ? 1 : 17}
            // style={{ marginLeft: this.state.isChart ? '8%' : '58%' }}
            style={{ marginLeft: '58%' }}
          >
            <Button className='buttonColor' type="link" icon={<TableOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>
          </Col>
          <Col  >
            <Button className='buttonColor' type="link"
              icon={<BarChartOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: true
                })
              }}
            ></Button>

          </Col>

        </Row>
        <br></br>
        {
          console.log(this.state.options, this.state.series, "ÿyyyyyyuuu")
        }
        {
          this.state.showLoader ? <LoadingSvg /> :
            <>
              {
                this.state.isChart ? 
                <>
                <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
                <Chart options={this.state.chartData.options} series={this.state.chartData.series} type="bar" height={350} />
                </>
                  : <>
                    <Table scroll={this.state.dataSource.length > 5 ? {
                      y: 250, scrollToFirstRowOnChange: true
                    } : undefined} pagination={false} bordered rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'} dataSource={this.state.dataSource} columns={this.state.columns} size='small' />
                  </>
              }
            </>
        }


      </div>


    );
  }
}


export default AegingAnalysis;