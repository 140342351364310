import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row, Select, Space, Col, Button, Table, DatePicker, Checkbox,
} from "antd";
import { TableOutlined, BarChartOutlined } from "@ant-design/icons";
import "../../../../App.css";
import GetApiData from "../../../../utils/GetApiData";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;
const { RangePicker } = DatePicker;
const visitType = [
  {
    title: "Visit Type",
    dataIndex: "visitName",
    key: "visitName",
  },
  {
    title: "Medical",
    dataIndex: "isMedical",
    key: "isMedical",
  },
  {
    title: "Surgical",
    dataIndex: "isSurgical",
    key: "isSurgical",
  },
];
const paymentEntitlements = [
  {
    title: "Entitlements",
    dataIndex: "paymentMode",
    key: "paymentMode",
  },
  {
    title: "Revenue",
    dataIndex: "isMedical",
    key: "isMedical",
  },
];
const specialtyRevenueCol = [
  {
    title: "Specialty",
    dataIndex: "specialtyName",
    key: "specialtyName",
  },
  {
    title: "Revenue",
    dataIndex: "amount",
    key: "amount",
  },
];
const DepartmentRevenueCol = [
  {
    title: "Department",
    dataIndex: "departmentName",
    key: "departmentName",
  },
  {
    title: "Revenue",
    dataIndex: "amount",
    key: "amount",
  },
];
const RevenueClassification = ({ apiHost, unitId, currencyInfo }) => {
  const [currentView, setCurrentView] = useState("graphView");
  const [caseTypeChart, setCaseTypeChart] = useState({
    series: [
      // {
      //   name: "OP",
      //   data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      // },
    ],
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 20,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#5653FE",
          shadeTo: "light",
          shadeIntensity: 0.95,
        },
      },
      labels: ["OP", "IP", "Daycare", "ER"],
    },
  });


  const [specialtyChart, setSpecialtyChart] = useState({
    series: [44, 55, 13, 43],
    options: {
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      labels: ["Cash", "Insurance", "Corporate", "Other"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
    },
  });
  const [departmentChart, setDepartmentChart] = useState({
    series: [44, 55, 13, 43],
    options: {
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      labels: ["Cash", "Insurance", "Corporate", "Other"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
    },
  });
  const [deptId, setDeptId] = useState("")
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(7, "days").format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("DD-MM-YYYY"));

  const [visitTypeArr, setVisitTypeArr] = useState([]);


  const [paymentModeId, setPaymentModeId] = useState("");
  const [specialityId, setSpecialityId] = useState("");
  const [visitTypeData, setVisitTypeData] = useState([]);

  const [specialtyTableData, setSpecialtyTableData] = useState([]);
  const [departmentTableData, setDepartmentTableData] = useState([]);
  const [specialtyListDD, setSpecialtyListDD] = useState([]);

  const [paymentMethodListDD, setPaymentMethodListDD] = useState([]);
  const [visitTypeListArr, setVisitTypeListArr] = useState([]);

  const options = [
    {
      label: <strong>OP</strong>,
      value: 1,
    },
    {
      label: <strong>IP</strong>,
      value: 2,
    },

    {
      label: <strong>Day care</strong>,
      value: 3,
    },
    {
      label: <strong>ER</strong>,
      value: 4,
    },
  ];

  const handleSwitchView = (name) => {
    setCurrentView(name);
  };



  const fetchData = async () => {
    var curretCount = moment().weekday();
    console.log("curretCount", curretCount);
    // var pastToDate = moment(new Date()).subtract(curretCount, "days").format("YYYY-MM-DD");
    // var pastFromDate = moment(pastToDate).subtract(6, "days").format("YYYY-MM-DD");
    var currentToDate = moment(new Date()).format("YYYY-MM-DD");
    var currentFromDate = moment(new Date()).subtract(curretCount - 1, "days").format("YYYY-MM-DD");

    fetchAllRevenue(currentFromDate, currentToDate, unitId, visitTypeListArr, paymentModeId, specialityId);

  };
  const fnGetVisitTypeAndPaymentModeList = async () => {
    var res = await GetApiData.fnGetVisitListAndPaymentModeList({ apiHost });
    var data = res.paymentModeList.length > 0 ? res.paymentModeList : [];
    var sortedData = data.sort((a, b) =>
      a.payment_mode_code > b.payment_mode_code
        ? 1
        : b.payment_mode_code > a.payment_mode_code
          ? -1
          : 0
    );
    console.log(res.paymentModeList, "resssssssssskkk");
    if (res.paymentModeList) {
      setPaymentMethodListDD(sortedData);
    }
  };
  const fnGetSpecialtyList = async () => {
    console.log(unitId, "fngetunittt");
    var res = await GetApiData.getSpecialtyList({ apiHost, unitId });
    var data = res.specListMaster;
    var sortedData = data.sort((a, b) =>
      a.speciality_name > b.speciality_name ? 1
        : b.speciality_name > a.speciality_name ? -1
          : 0
    );
    console.log("sortedData", sortedData);
    setSpecialtyListDD(sortedData);
    console.log("res", res);
  };
  useEffect(() => {
    fnGetVisitTypeAndPaymentModeList();
    fnGetSpecialtyList();
  }, []);

  const fetchAllRevenue = async (fromDate, toDate, unitId, visitTypeId, paymentModeId, specialityId,
    deptId) => {
    console.log(visitTypeArr, "tyyyyyyvisttttt");
    try {
      var res = await GetApiData.fnGetAllRevenue({
        apiHost, fromDate, toDate, unitId, visitTypeId, paymentModeId, specialityId, deptId
      });
      var tempData = res.allRevenue;
      console.log("tempData", tempData);
      let visitTypeArr = [];

      let departMentArr = [];

      let specialityArrTable = [];

      var isMedical = 0, isSurgical = 0, totalSpecialitycount = 0, deptTotal = 0;
      tempData.map((curEl, index) => {
        /// visitType
        let item = visitTypeArr.findIndex(
          (i) => i.visitName == curEl.visit_type_code
        );

        if (item == -1) {
          if (curEl.is_surgical_code == 'N') {
            visitTypeArr.push({
              visitName: curEl.visit_type_code,
              isMedical: parseInt(curEl.Amount),
              isSurgical: 0,
              key: index,
            });
          } else {
            visitTypeArr.push({
              visitName: curEl.visit_type_code,
              isMedical: 0,
              isSurgical: parseInt(curEl.Amount),
              key: index,
            });
          }

        } else {

          if (curEl.is_surgical_code == "N") {
            visitTypeArr[item].isMedical += Math.round(+curEl.Amount);
          }
          else {
            visitTypeArr[item].isSurgical += Math.round(+curEl.Amount);
          }
        }

        if (curEl.is_surgical_code == "Y") {
          isSurgical += (+curEl.Amount);
        }
        else {
          isMedical += (+curEl.Amount);
          console.log(isMedical, "ismmmmmm");
        }


        // speciality

        var specilityIndex = specialityArrTable.findIndex(
          (i) => i.specialtyName == curEl.Speciality
        )

        if (specilityIndex == -1) {
          specialityArrTable.push({
            specialtyName: curEl.Speciality,
            amount: parseInt(+curEl.Amount)
          })
          totalSpecialitycount += curEl.Amount;
        }
        else {
          specialityArrTable[specilityIndex].amount += parseInt(+curEl.Amount);
          totalSpecialitycount += curEl.Amount;
        }

        // speciality end

        //departments
        var deptIndex = departMentArr.findIndex(
          (i) => i.departmentName == curEl.department
        )

        if (deptIndex == -1) {
          departMentArr.push({
            departmentName: curEl.department,
            amount: curEl.Amount
          })
        }
        else {
          departMentArr[deptIndex].amount += parseInt(+curEl.Amount)
        }
        deptTotal += (+curEl.Amount)


        //departments ends

      });


      specialityArrTable = specialityArrTable.sort((a, b) => b.amount - a.amount)
      visitTypeArr = visitTypeArr.sort((a, b) => b.isMedical - a.isMedical)
      departMentArr = departMentArr.sort((a, b) => b.amount - a.amount)
      // console.log(specialityArrTable, "specilllllk");
      // var visitTypeIsMedicalArr = visitTypeArr.map(obj => obj.isMedical);
      // var visitTypeIsSurgicalArr = visitTypeArr.map(obj => obj.isSurgical);
      var visitTypeNameArr = visitTypeArr.map(obj => obj.visitName);
      // console.log(visitTypeArr, visitTypeNameArr, "neeemmmmmm");
      var visitTypeSeries = [
        { name: "Medical", data: visitTypeArr.map(obj => obj.isMedical) },
        { name: "surgical", data: visitTypeArr.map(obj => obj.isSurgical) }
      ]

      setCaseTypeChart({
        series: visitTypeSeries,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: 30,
              barWidth: "5%",
              endingShape: "rounded",
              dataLabels: {
                position: "top", // top, center, bottom
                // formatter: function (val, opts) {
                //   return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
                // },
              },
            },
          },
          theme: {
            mode: "light",
            palette: `palette${Math.floor(Math.random() * 10) + 1}`,
            monochrome: {
              enabled: false,
              color: "#255aee",
              shadeTo: "light",
              shadeIntensity: 0.65,
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -18,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
            // formatter: function (val, opts) {
            //   return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            // },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: visitTypeNameArr,
            // title: {
            //   text: "Visit Types",
            // },
          },
          title: {
            text: "Case Type",
            align: "centre",
          },
          tooltip: {
            y: {
              // formatter: function (val) {
              //   return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
              // },
            },
          },
        },
      });
      visitTypeArr.push({
        visitName: "Total",
        isMedical: parseInt(isMedical),
        isSurgical: parseFloat(isSurgical).toFixed(0)
      })
      setVisitTypeData(visitTypeArr);



      setSpecialtyChart({
        series: specialityArrTable.map((item) => +item.amount),
        options: {
          theme: {
            mode: "light",
            palette: `palette${Math.floor(Math.random() * 10) + 1}`,
            monochrome: {
              enabled: false,
              color: "#255aee",
              shadeTo: "light",
              shadeIntensity: 0.65,
            },
          },
          legend: {
            position: "bottom",
          },
          labels: specialityArrTable.map((item) => item.specialtyName),
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          title: {
            text: "Specialty",
            align: "centre",
          },
          tooltip: {
            // y: {
            //   formatter: function (val) {
            //     return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            //   },
            // },
          },
        },
      });

      specialityArrTable.push({
        specialtyName: "Total",
        amount: parseInt(totalSpecialitycount)
      })

      setSpecialtyTableData(specialityArrTable)
      setDepartmentChart({
        series: departMentArr.map((item) => item.amount),
        options: {
          theme: {
            mode: "light",
            palette: `palette${Math.floor(Math.random() * 10) + 1}`,
            monochrome: {
              enabled: false,
              color: "#255aee",
              shadeTo: "light",
              shadeIntensity: 0.65,
            },
          },
          legend: {
            position: "bottom",
          },
          labels: departMentArr.map(
            (item) => item.departmentName
          ),
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          title: {
            text: "Department",
            align: "centre",
          },
          tooltip: {
            // y: {
            //   formatter: function (val) {
            //     return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            //   },
            // },
          },
        },
      });


      departMentArr.push({
        departmentName: "Total",
        amount: parseInt(deptTotal)
      })

      setDepartmentTableData(departMentArr);

    } catch (error) {
      console.log("Error ::", error);
    }
  };

  const handleSpecialtyChange = async (specialityId) => {
    console.log("specialityIddddd", specialityId, visitTypeListArr);
    setSpecialityId(specialityId);
    await fetchAllRevenue(fromDate, toDate, unitId, visitTypeListArr, paymentModeId,
      specialityId);
  };
  const handleRangePicker = async (value) => {
    const newFrmDate = moment(value[0]).format("YYYY-MM-DD");
    const newToDate = moment(value[1]).format("YYYY-MM-DD");
    setFromDate(newFrmDate);
    setToDate(newToDate)
    console.log("newFrmDatenewToDate", newFrmDate, "--", newToDate, visitTypeListArr);
    await fetchAllRevenue(
      newFrmDate, newToDate, unitId, visitTypeListArr, paymentModeId, specialityId
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onChangeCheckBox = (checkedValues) => {
    setVisitTypeListArr(checkedValues);
    console.log("checkedBoxqqqqqqq", checkedValues, caseTypeChart.options, caseTypeChart.series);

    fetchAllRevenue(fromDate, toDate, unitId, checkedValues, paymentModeId, specialityId, deptId);

  };
  return (
    <div>
      <Row align="top" justify="space-between" className="mb-2">
        <Col lg={8} md={8} sm={12} xs={12}>
          <div className=" table-chart-heading">Revenue Classification</div>
        </Col>
        <Col lg={14} md={16} sm={16} xs={24}>
          <Row justify="space-between" align="middle" style={{ marginRight: '12px' }}          >
            <Col lg={7} md={8} sm={6} xs={12}>
              <RangePicker
                placeholder={[fromDate, toDate]}
                onChange={(value) => {
                  handleRangePicker(value);
                  console.log("value", value);
                }}
              />
            </Col>
            <Col lg={7} md={8} sm={6} xs={12}>
              {specialtyListDD.length > 0 && (
                <Select
                  onChange={(value) => {
                    handleSpecialtyChange(value);
                  }}
                  placeholder="Select Specialty"
                  className="w-100"
                  style={{ textTransform: "capitalize" }}
                >
                  <Option value="">Select All </Option>
                  {specialtyListDD.map((u) => {
                    return (
                      <Option value={u.speciality_id} key={u.speciality_id}>
                        {u.speciality_name.charAt(0).toUpperCase() +
                          u.speciality_name.slice(1).toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col>
            <Col lg={7} md={8} sm={6} xs={12}>
              {specialtyListDD.length > 0 && (
                <Select
                  onChange={async (value) => {
                    setDeptId(value);
                    await fetchAllRevenue(fromDate, toDate, unitId, visitTypeListArr, paymentModeId,
                      specialityId, value);
                    // handleSpecialtyChange(value);
                  }}
                  placeholder="Select Department"
                  className="w-100"
                  style={{ textTransform: "capitalize" }}
                >
                  <Option value="">Select All </Option>
                  {specialtyListDD.map((u) => {
                    return (
                      <Option value={u.speciality_id} key={u.speciality_id}>
                        {u.speciality_name.charAt(0).toUpperCase() +
                          u.speciality_name.slice(1).toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col>

            <Col lg={2} md={8} sm={6} xs={12}>
              <Row align="middle"

              >
                <Col>

                  <Button
                    className="buttonColor"
                    onClick={() => {
                      handleSwitchView("tableView");
                    }}
                    type="link"
                    icon={<TableOutlined />}
                  />

                </Col>
                <Col>

                  <Button
                    className="buttonColor"
                    onClick={() => {
                      handleSwitchView("graphView");
                    }}
                    type="link"
                    icon={<BarChartOutlined />}
                  />

                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={10}></Col>
        <Col span={5}>
          <Checkbox.Group options={options} onChange={onChangeCheckBox} />
        </Col>
      </Row>
      {currentView === "tableView" && (
        <>
          <Row align="top" justify="space-around" gutter={[12, 12]}>
            <Col lg={8} md={8} sm={12} xs={12}>
              <div className="text-center mb-1 table-sub-heading">
                Visit Type
              </div>
              <Table
                columns={visitType}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                bordered
                size="small"
                dataSource={visitTypeData}
              />
            </Col>

            <Col lg={8} md={8} sm={12} xs={12}>
              <div className="text-center mb-1 table-sub-heading">
                Specialty Wise
              </div>
              {
                console.log(specialtyTableData, "spereveuuu")
              }
              <Table
                columns={specialtyRevenueCol}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                pagination={false}
                scroll={
                  specialtyTableData.length > 5
                    ? {
                      y: 233,
                      scrollToFirstRowOnChange: true,
                    }
                    : undefined
                }
                bordered
                size="small"
                dataSource={specialtyTableData}
              />
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <div className="text-center mb-1 table-sub-heading">
                Department Wise
              </div>
              {
                console.log(departmentTableData, "deptdattttttt")
              }
              <Table
                columns={DepartmentRevenueCol}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                pagination={false}

                scroll={
                  departmentTableData.length > 5
                    ? {
                      y: 233,
                      scrollToFirstRowOnChange: true,
                    }
                    : undefined
                }
                bordered
                size="small"
                dataSource={departmentTableData}
              />
            </Col>
          </Row>
        </>
      )}
      {currentView === "graphView" && (
        <>
          <Row align="top" justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              {
                console.log(caseTypeChart, "casetuuuu")
              }
              <ReactApexChart
                options={caseTypeChart.options}
                series={caseTypeChart.series}
                type="bar"
                width={450}
                height={450}
              />
            </Col>

            <Col lg={6} md={10} sm={12} xs={12}>
              {
                console.log(specialtyChart, "chartttttttttttt")
              }

              <ReactApexChart
                options={specialtyChart.options}
                series={specialtyChart.series}
                type="pie"
                width={450}
                height={450}
              />

              {specialtyTableData.length > 0 && (
                <Row>
                  <Col
                    offset={9}
                    style={{ color: "#000", fontWeight: 500, marginTop: 15 }}
                  >
                    Total :{" "}
                    {`${currencyInfo} ${parseInt(
                      `${specialtyTableData[specialtyTableData.length - 1]
                        .amount
                      }`
                    ).toLocaleString("en-IN")}`}
                    {/* {parseInt(
                      `${specialtyTableData[specialtyTableData.length - 1]
                        .isMedical
                      }`
                    )} */}
                  </Col>
                </Row>
              )}
            </Col>
            <Col lg={6} md={8} sm={12} xs={12}>
              <Row>
                <ReactApexChart
                  options={departmentChart.options}
                  series={departmentChart.series}
                  type="pie"
                  width={410}
                  height={400}
                />
              </Row>
              {departmentTableData.length > 0 && (
                <Row>
                  <Col
                    offset={9}
                    style={{ color: "#000", fontWeight: 500, marginTop: 15 }}
                  >
                    Total :{" "}
                    {`${currencyInfo} ${parseInt(
                      `${departmentTableData[departmentTableData.length - 1]
                        .amount
                      }`
                    ).toLocaleString("en-IN")}`}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default RevenueClassification;
