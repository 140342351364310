import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Select, Space, Col, Button, Table, Modal, Collapse, Divider } from 'antd';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import '../../../App.css'
import utilsFunction from '../../../utils/utilsFunction';
const { Panel } = Collapse;
var _ = require('lodash');
class PatientExperience extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [],
      options: {},
      isChart: true,
      isGross: true,
      dataSource: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Time in min',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: '',
          dataIndex: 'result',
          key: 'result',
        },
      ],
      grossData: [],
      drillData: []
    };
  }

  componentDidMount() {
    // console.log(this.props, "ddddacalllldiddddddeeeddddippppppp");
    this.setState({
      grossData: this.props.grossData,
      drillData: this.props.drillData,

    })
    this.fnCalculateGrossTable(this.props.grossData)

  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      this.setState({
        grossData: this.props.grossData,
        drillData: this.props.drillData,

      })
      this.fnCalculateGrossTable(this.props.grossData)

    }
  }

  fnCalculateGraph = (countData) => {

    var countValue = countData.map(value => (+value.count));
    var countType = countData.map(value => value.name);

    var categoriesArr = [];
    var n = 2;
    countType.map((catType, index) => {
      var a = catType.split(' ')
      var first = a.slice(0, n).join(' ')
      var second = a.slice(n).join(' ');
      categoriesArr.push([first, second])
    })
    // console.log(categoriesArr, "dsssssssssssss");
    // categories: [
    //   ['Average OP', 'Visit Length (Min)'],
    //   ['Average OP ', 'Wait time (Min)'],
    //   ['Average IP ', 'Acceptance Wait time', ' (Min)'],
    //   ['Average Discharge', ' Wait time (Min)'],
    //   ['Average Satisfaction', 'Rating (OP)  Avg.'],
    //   ['Average Satisfaction', ' Rating (IP) Avg.']
    // ],


    this.setState({
      series: [{
        data: countValue
      }],
      options: {
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },

        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },

        xaxis: {
          // categories: countType,
          categories: categoriesArr,

          labels: {
            style: {
              colors: "green",
              fontSize: '12px'
            }
          }
        }
      },
    })
  }

  // uniqueKeyValues = (arr, key, year, qtr, month) => {

  //   if (key == 'qtr') {
  //     var yearData = arr.map((obj) => { if (obj.year == year) return obj });
  //     return [... new Set(yearData.map((obj) => { return obj[key] }))];
  //   } else if (key == 'month') {
  //     var monthData = arr.map((obj) => { if (obj.year == year && obj.qtr == qtr) return obj });
  //     return [... new Set(monthData.map((obj) => { return obj[key] }))];
  //   }
  //   else if (key == 'fn') {
  //     var fnDAta = arr.map((obj) => { if (obj.year == year && obj.qtr == qtr && obj.month == month) return obj });
  //     return [... new Set(fnDAta.map((obj) => { return obj[key] }))];
  //   }
  //   return [... new Set(arr.map((obj) => { return obj[key] }))];
  // }

  fnCalculateGrossTable = (dataArr) => {
    var dataSource = [];
    var currentData = [], previousData = []
    // console.log(dataArr, "ddddacallll");
    dataArr.map((d, index) => {
      if (d.tag == 'Current') {
        currentData.push(d)
      } else {
        previousData.push(d)
      }

    })

    // console.log(previousData, currentData, "aqwqwq");

    currentData.map((data, index) => {
      var matchData = [];
      previousData.length > 0 && previousData.map((old, index) => {

        if (data.billing_bed_desc == old.billing_bed_desc) {

          matchData.push(old)
        }
      })


      var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />

      if (matchData.length > 0 && +data.sum < matchData[0].sum) {
        result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
      }
      else if (matchData.length > 0 && +data.sum == matchData[0].sum) {
        result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
      }


      var dataObj = {
        key: index,
        name: data.category,
        count: +data.sum,
        result: result
      }
      dataSource.push(dataObj)
    })
    this.fnCalculateGraph(dataSource)
    this.setState({
      dataSource: dataSource
    })
  }
  fnCalculateDrillDownTable = () => {
    var drillArr = this.state.drillData.filter((data, index) => {
      return data.tag == 'Current'
    });
    var previosData = this.state.drillData.filter((data, index) => {
      return data.tag == 'Old'
    });
    // console.log(dataArr, "drilllqqqllll111", this.state);
    var uniqueYearArr = utilsFunction.uniqueKeyValues(drillArr, 'year')
    console.log(drillArr, "drilllllllllll");
    var finalArr = [];
    var indexValue = 0;
    uniqueYearArr.map((year, index) => {
      indexValue++;

      var uniqueQTROfAYear = utilsFunction.uniqueKeyValues(drillArr, 'qtr', year);
      // console.log(uniqueQTROfAYear, "yearrrrrrrrrrr");
      var yearParentObj = {
        key: indexValue,
        name: year,
        count: drillArr.reduce((total, obj) => obj.year == year && (+obj.sum) + total, 0),

        // result: <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />,
        children: []
      }
      var qtrDataArr = [];

      uniqueQTROfAYear.map((qtr, index) => {
        indexValue++;
        var qtrObj = {
          key: indexValue,
          name: qtr == 1 ? 'QTR1' : 'QTR2',
          count: drillArr.reduce((total, obj) => obj.year == year && obj.qtr == qtr && (+obj.sum) + total, 0),
          // result: <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />,
          children: []
        }

        var uniqueMonthOfAQTR = utilsFunction.uniqueKeyValues(drillArr, 'month', year, qtr);
        var qtrChild = []; // for month array
        uniqueMonthOfAQTR.sort().map((monthName, index) => {
          indexValue++;
          var monthObj = {
            key: indexValue,
            name: utilsFunction.getMonthName(monthName),
            count: drillArr.reduce((total, obj) => obj.year == year && obj.qtr == qtr && obj.month == monthName && (+obj.sum) + total, 0),
            result: '',
            children: []
          }
          qtrChild.push(monthObj)

          var uniqueFNOfMonth = utilsFunction.uniqueKeyValues(drillArr, 'fn', year, qtr, monthName);
          // console.log(uniqueFNOfMonth, "fnnnnnnnnnn");
          var FNArr = [];
          uniqueFNOfMonth.sort().map((fnValue, index) => {

            let gfg = _.sumBy(drillArr, function (o) {
              if (o.fn == fnValue)
                return +o.sum;
            });
            // console.log(gfg, "dddddddddfffff");
            indexValue++;
            var FNObj = {
              key: indexValue,
              name: fnValue == 1 ? 'Fortnight1' : 'Fortnight2',
              count: _.sumBy(drillArr, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr && obj.month == monthName)
                  return +obj.sum;
              }),
              result: '',
              children: []
            }
            var expensesTypeArr = [];
            var currentPatientDAta = this.state.grossData.filter((d, index) => {
              return d.tag == 'Current'
            })
            // console.log(this.props.countData, "lklklklklk");
            currentPatientDAta.map((expensesType, index) => {
              indexValue++;
              var matchData = previosData.filter((data, index) => {
                if (data.category == expensesType.category && data.fn == fnValue && data.year == year && data.qtr == qtr
                  && data.month == monthName) {
                  return data
                }
              })

              var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />
              // console.log(matchData.length, "mmmmmmmmmmmmmmmmmmm", matchData.sum);
              var currentSum = _.sumBy(drillArr, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr
                  && obj.month == monthName && obj.category == expensesType.category)
                  return +obj.sum;
              })
              if (matchData.length > 0 && currentSum < matchData[0].sum) {
                result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
              }
              if (matchData.length > 0 && currentSum == matchData[0].sum) {
                result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
              }
              var expensesType = {
                key: indexValue,
                name: expensesType.category,
                count: currentSum,
                result: result
              }
              expensesTypeArr.push(expensesType)
            })
            FNObj.children = expensesTypeArr;
            FNArr.push(FNObj)
          })
          monthObj.children = FNArr
        })

        qtrObj.children = qtrChild
        qtrDataArr.push(qtrObj)
      })
      yearParentObj.children = qtrDataArr


      finalArr.push(yearParentObj)
    })
    // console.log(finalArr, "finallllll");
    this.setState({
      dataSource: finalArr
    })
  }


  render() {
    return (


      <div id="chart" style={{ minHeight: 251 }}>

        <Row>
          <Col span={3} style={{ fontWeight: 'bold', fontSize: 15 }}>Patient Experience</Col>
          {
            // !this.state.isChart &&
            <>
              <Col span={1} >
                <Button className='buttonColor buttonColor2' type="link"

                  onClick={() => {
                    this.setState({
                      isGross: true
                    }, () => {
                      this.fnCalculateGrossTable(this.state.grossData)
                    })
                  }}
                >Gross</Button>
              </Col>
              <Col  >
                <Button className='buttonColor buttonColor2' type="link"

                  onClick={() => {
                    this.setState({
                      isGross: false
                    }, () => {
                      this.fnCalculateDrillDownTable()
                    })
                  }}
                > Drill Down </Button>

              </Col>
            </>
          }

          {/* <Col span={1} offset={this.state.isChart ? 16 : 11}>

            <Button className='buttonColor' type="link" icon={<SearchOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>

          </Col> */}
          {/* <Col span={1} offset={this.state.isChart ? 17 : 12}>
            <Button className='buttonColor' type="link" icon={<TableOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>
          </Col>
          <Col span={1} >
            <Button className='buttonColor' type="link"
              icon={<BarChartOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: true
                })
              }}
            ></Button>

          </Col> */}
        </Row>
        <br></br>
        {/* {
          this.state.isChart ? <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
            : <>
              <Table dataSource={this.state.dataSource} columns={this.state.columns} size='small' />
            </>
        } */}
        <Row>
          <Col span={9}><Chart options={this.state.options} series={this.state.series} type="bar" height={350} /></Col>
          <Col span={14} offset={1}>
            <Table scroll={this.state.dataSource.length > 5 ? {
              y: 240, scrollToFirstRowOnChange: true
            } : undefined} pagination={false} bordered rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'} dataSource={this.state.dataSource} columns={this.state.columns} size='small' /></Col>
        </Row>
      </div>


    );
  }
}


export default PatientExperience;