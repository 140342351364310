import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { Row, Select, Space, Col, Button, Table, DatePicker } from "antd";
import {
  TableOutlined,
  BarChartOutlined,
  DislikeOutlined,
  LikeOutlined,
  SearchOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import "../../../../App.css";
import GetApiData from "../../../../utils/GetApiData";
import ReactApexChart from "react-apexcharts";

const paymentCol = [
  {
    title: "Payment modes",
    dataIndex: "paymentMode",
    key: "paymentMode",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Total amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
];
const { RangePicker } = DatePicker;
const specialityWiseCol = [
  {
    title: "Speciality",
    dataIndex: "speciality",
    key: "speciality",

    render: (text) => <a>{text}</a>,
  },
  {
    title: "Total amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
];

const serviceGroupWise = [
  {
    title: "Service group name",
    dataIndex: "serviceGroupName",
    key: "serviceGroupName",

    render: (text) => <a>{text}</a>,
  },
  {
    title: "Total amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
];

const { Option } = Select;

const CollectionOfDay = ({ apiHost, unitId, currencyInfo }) => {
  console.log("currencyInfo", currencyInfo);
  const barChartOption = {
    theme: {
      mode: "light",
      palette: `palette${Math.floor(Math.random() * 10) + 1}`,
      monochrome: {
        enabled: false,
        color: "#255aee",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: "55px",
        endingShape: "rounded",
      },
    },

    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    tooltip: {
      x: {
        // formatter: function (val) {
        //   return ''
        // },
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      y: {
        formatter: function (val) {
          return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
        },
        title: {
          formatter: function (seriesName) {
            // return seriesName
            return "";
          },
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "27px",
        distributed: true,
        // dataLabels: {
        //   position: "top", // top, center, bottom
        // },
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -18,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
      formatter: function (val, opts) {
        return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        // ['John', 'Doe'],
        // ['Joe', 'Smith'],
        // ['Jake', 'Williams'],
        // 'Amber',
        // ['Peter', 'Brown'],
        // ['Mary', 'Evans'],
        // ['David', 'Wilson'],
        // ['Lily', 'Roberts'],
      ],
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    theme: {
      mode: "light",
      palette: `palette${Math.floor(Math.random() * 10) + 1}`,
      monochrome: {
        enabled: false,
        color: "#255aee",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
  };
  const [isChart, setIsChart] = useState(true);
  const [paymentData, setPaymentData] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [serviceGroupData, setServiceGroupData] = useState([]);
  const [visitTypeArr, setVisitTypeArr] = useState([]);
  const [paymentModeArr, setPaymentModeArr] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");

  const [options1, setOptions1] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    theme: {
      mode: "light",
      palette: `palette${Math.floor(Math.random() * 10) + 1}`,
      monochrome: {
        enabled: false,
        color: "#255aee",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    title: {
      text: "Mode of Payments",
      align: "centre",
    },
    legend: {
      position: "bottom",
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  });
  const [series1, setSeries1] = useState([]);
  const [options2, setOptions2] = useState(barChartOption);
  const [series2, setSeries2] = useState([{ data: [] }]);
  const [series3, setSeries3] = useState([]);
  const [options3, setOptions3] = useState(barChartOption);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(7, "days").format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("DD-MM-YYYY"));

  useEffect(() => {
    fnGetData(selectedVisitType, selectedPaymentMode, fromDate, toDate);

    if (visitTypeArr.length == 0 && paymentModeArr.length == 0) {
      fnGetVisitTypeAndPaymentModeList();
    }
    // setTimeout(() => {
    //   setIsChart(true)
    // }, 2000)
    // fnGetPaymentMode();
  }, []);

  const fnGetData = async (selectedVisitType, selectedPaymentMode, fromDate, toDate) => {
    await fnGetCollection(selectedVisitType, selectedPaymentMode, fromDate, toDate);
  };

  const fnGetVisitTypeAndPaymentModeList = async () => {
    var res = await GetApiData.fnGetVisitListAndPaymentModeList({ apiHost });
    var data = res.paymentModeList.length > 0 ? res.paymentModeList : [];
    var visitData = res.visitTypeList.length > 0 ? res.visitTypeList : [];
    var sortedData = data.sort((a, b) =>
      a.payment_mode_code > b.payment_mode_code ? 1 : b.payment_mode_code > a.payment_mode_code
        ? -1 : 0
    );
    var sortedDataVisit = visitData.sort((a, b) =>
      a.visit_type_name > b.visit_type_name ? 1 : b.visit_type_name > a.visit_type_name ? -1 : 0
    );
    console.log(sortedDataVisit, "sortedDataVisit");
    console.log(sortedData, "sortedData");
    if (res.paymentModeList) {
      setPaymentModeArr(sortedData);
      setVisitTypeArr(sortedDataVisit);
    }
  };

  const fnGetCollection = async (visitTypeId, paymentModeId, fromDate, toDate) => {
    console.log(visitTypeId, paymentModeId, "infnnnnnnnn");
    var collectionRes = await GetApiData.fnCollectionOFDay({
      apiHost, fromDate, toDate, unitId, visitTypeId, paymentModeId,
    });

    let arr = [], arrSpeciality = [], arrServiceGroup = [], colTotal = 0, specialitytotal = 0,
      serviceGroupTotal = 0;
    console.log(collectionRes.collectionOfDay, "colllllec");

    collectionRes.collectionOfDay.map((col, index) => {
      //payment mode
      arr.push({
        key: index,
        paymentMode: col.payment_mode_desc,
        dataArr: [col],
        totalAmount: parseInt(col.amount),
      });
      colTotal += parseInt(col.amount)
      //payment mode end----------------------------
    });

    arr.push({
      key: Math.random(),
      paymentMode: "Total",
      totalAmount: colTotal,
    });



    var result = await GetApiData.fnSpecialityWiseAndServicegroupWiseCollection({
      apiHost, fromDate, toDate, unitId, visitTypeId, paymentModeId
    });


    console.log(result, "respppppppppppk1");
    result.specialityWise.map((col, index) => {
      arrSpeciality.push({
        key: index,
        speciality: col.Department,
        dataArr: [col],
        totalAmount: parseInt(col.Amount),
      });
      specialitytotal += parseInt(+col.Amount)
    })
    arrSpeciality.sort((a, b) => b.totalAmount - a.totalAmount);





    var servicegroupwiseRes = result.serviceGroupWise

    console.log(servicegroupwiseRes, "grouphhhhh");
    servicegroupwiseRes.map((col, index) => {
      arrServiceGroup.push({
        key: index,
        serviceGroupName: col.Department,
        dataArr: [col],
        totalAmount: parseInt(col.Amount),
      });
      serviceGroupTotal += col.Amount
    })

    // arr.sort((a, b) => b.totalAmount - a.totalAmount);
    arrServiceGroup.sort((a, b) => b.totalAmount - a.totalAmount);



    const arrSpecialtyDesc = arrSpeciality.sort(
      (a, b) => +b["totalAmount"] - +a["totalAmount"]
    );

    console.log(arrSpeciality, "arrrrrrrrrrr", arrServiceGroup)
    arrSpeciality.push({
      key: Math.random(),
      speciality: "Total",
      dataArr: [],
      totalAmount: specialitytotal,
    });
    // arrSpeciality.push({
    //   key: Math.random(),
    //   speciality: "Total",
    //   totalAmount: Math.round(colTotal),
    // });
    const arrServiceGroupDesc = arrServiceGroup.sort(
      (a, b) => +b["totalAmount"] - +a["totalAmount"]
    );

    arrServiceGroup.push({
      key: Math.random(),
      serviceGroupName: "Total",
      totalAmount: Math.round(serviceGroupTotal),
    });
    setPaymentData(arr);

    setSpecialityData(arrSpecialtyDesc);
    setServiceGroupData(arrServiceGroupDesc);

    // fnShowCharts(arr, arrSpeciality, arrServiceGroup)

    var labelsArr = [],
      seriesArr = [];

    arr.map((pay) => {
      console.log(pay, "paymmmmm");
      labelsArr.push(pay["paymentMode"]);
      seriesArr.push(pay["totalAmount"]);
    });
    // const randomNumber = Math.floor(Math.random() * 10) + 1;

    labelsArr.pop(); // Removes the last element
    seriesArr.pop();
    var copyOptions = {
      ...options1,
      labels: labelsArr,
      tooltip: {
        y: {
          formatter: function (val) {
            return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
          },
        },
      },
    };
    setOptions1(copyOptions);
    setSeries1(seriesArr);

    // var _option2, specialityTypeArr=[],countValue=[];
    var categoriesArr = [], amountArr = [];

    arrSpeciality.map((spc, index) => {
      console.log(spc, "spcccccccccccccc");
      categoriesArr.push(spc.speciality.toUpperCase());
      amountArr.push(spc.totalAmount);
    });

    categoriesArr.pop();
    var Opt2 = {
      ...barChartOption,
      xaxis: {
        ...barChartOption.xaxis,
        categories: categoriesArr,
      },
      title: {
        text: "Specialty wise",
        align: "centre",
      },
    };
    setOptions2(Opt2);
    amountArr.pop();
    var data = [
      {
        data: amountArr,
      },
    ];
    setSeries2(data);

    var categoriesArr2 = [], amountArr2 = [];

    console.log(JSON.stringify(arrServiceGroup), "segrouppppp", arrServiceGroup);

    arrServiceGroup.map((spc, index) => {
      console.log(spc, "spccccccccccc");
      categoriesArr2.push(spc.serviceGroupName.toUpperCase());
      amountArr2.push(spc.totalAmount);
    });


    categoriesArr2.pop();



    var Opt3 = {
      xaxis: {
        ...barChartOption.xaxis,
        categories: categoriesArr2,
      },
      dataLabels: {
        enabled: false,
        position: 'bottom',
        style: {
          colors: ['#ff0000', '#00ff00', '#0000ff'],
          offsetY: -10, // Add 10 pixels of padding to the data labels
        },
        formatter: function (val, opts) {
          return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
        },

      },
      plotOptions: {
        bar: {
          barHeight: 40,
          horizontal: true,
          dataLabels: {
            // position: "bottom", // top, center, bottom
            // offsetX: -10,
          },
        },
      },
      title: {
        text: "Service Group Wise",
        align: "centre",
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
          },
        },
      },
    };
    setOptions3(Opt3);

    amountArr2.pop();

    var data = [
      {
        data: amountArr2,
      },
    ];

    setSeries3(data);
  };

  return (
    <>
      <div id="chart" style={{ minHeight: 200 }}>
        <Row
          align="middle"
          justify="space-between"
          gutter={[16]}
          style={{ marginBottom: 16 }}
        >
          <Col lg={6} md={8} sm={12} xs={12}>
            <div className=" table-chart-heading">Collection of the day</div>
          </Col>
          <Col lg={13} md={12} sm={16} xs={24}>
            <Row align="middle" justify="space-between" gutter={[8]}>
              <Col lg={7} md={8} sm={6} xs={12}>
                <RangePicker
                  className="w-100"
                  placeholder={[fromDate, toDate]}
                  disabledDate={(current) => {
                    return current && current > moment().endOf("day");
                  }}
                  onCalendarChange={(val) => {
                    console.log('val', val)
                    if (val) {
                      const [start, end] = val;
                      console.log('start', moment(start).format("YYYY-MM-DD"))
                      if (start && end) {
                        setFromDate(moment(start).format("YYYY-MM-DD"));
                        setToDate(moment(end).format("YYYY-MM-DD"));
                        fnGetCollection(
                          selectedVisitType,
                          selectedPaymentMode,
                          moment(start).format("YYYY-MM-DD"),
                          moment(end).format("YYYY-MM-DD")
                        );
                      }

                      // fnShowCharts(selectedVisitType, selectedVisitType)
                    }
                  }}
                />
              </Col>
              <Col lg={7} md={8} sm={6} xs={12}>
                {visitTypeArr.length > 0 && (
                  <Select
                    className="w-100"
                    onChange={async (value) => {
                      setSelectedVisitType(value);
                      await fnGetCollection(
                        value,
                        selectedPaymentMode,
                        fromDate,
                        toDate
                      );
                    }}
                    // mode="multiple"
                    placeholder="Select Visit Type"
                  >
                    <Option value="" key="">Select All</Option>
                    {visitTypeArr.map((u) => {
                      // return <Option value={u.unitid} key={u.unitid}>{u.unitname}</Option>
                      return (
                        <Option value={u.visit_type_id} key={u.visit_type_id}>
                          {u.visit_type_name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Col lg={7} md={8} sm={6} xs={12}>
                {paymentModeArr.length > 0 && (
                  <Select
                    className="w-100"
                    onChange={(value) => {
                      setSelectedPaymentMode(value);
                      fnGetCollection(
                        selectedVisitType,
                        value,
                        fromDate,
                        toDate
                      );
                    }}
                    // mode="multiple"
                    placeholder="Select Payment Mode"
                  >
                    <Option value="" key={""}>Select All</Option>
                    {paymentModeArr.map((u) => {
                      // return <Option value={u.unitid} key={u.unitid}>{u.unitname}</Option>
                      return (
                        <Option
                          value={u.payment_mode_id}
                          key={u.payment_mode_id}
                        >
                          {u.payment_mode_desc}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Col lg={3} md={6} sm={6} xs={12}>
                <Button
                  className="buttonColor"
                  type="link"
                  icon={<TableOutlined className="tableColor" />}
                  onClick={() => {
                    setIsChart(false);
                  }}
                ></Button>
                <Button
                  className="buttonColor"
                  type="link"
                  icon={<BarChartOutlined className="tableColor" />}
                  onClick={() => {
                    setIsChart(true);
                  }}
                ></Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {!isChart ? (
          <>
            <Row gutter={[16]}>
              <Col span={8}>
                <Table
                  columns={paymentCol}
                  dataSource={paymentData}
                  pagination={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  bordered
                  size="small"
                />
              </Col>
              <Col span={8}>
                <Table
                  scroll={
                    specialityData.length > 5
                      ? {
                        y: 250,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                  columns={specialityWiseCol}
                  dataSource={specialityData}
                  pagination={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  bordered
                  size="small"
                />
              </Col>
              <Col span={8}>
                <Table
                  scroll={
                    serviceGroupData.length > 5
                      ? {
                        y: 250,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                  columns={serviceGroupWise}
                  dataSource={serviceGroupData}
                  pagination={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  bordered
                  size="small"
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[16]}>
              <Col span={5}>
                {
                  console.log(options1, series1, "servvvvvvvvvv")
                }
                <ReactApexChart
                  options={options1}
                  series={series1}
                  type="pie"
                  width={380}
                />
                {/* {
                  console.log(paymentData, "patmmmmmm")
                } */}
                {paymentData.length > 0 && (
                  <Row>
                    { }
                    <Col
                      offset={9}
                      style={{ color: "#000", fontWeight: 500, marginTop: 15 }}
                    >
                      Total :{" "}
                      {
                        `${currencyInfo} ${(paymentData[paymentData.length - 1].totalAmount).toLocaleString("en-IN")}`
                      }
                      {/* {`${paymentData[paymentData.length - 1].totalAmount}`} */}
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={10}>
                <Chart
                  options={options2}
                  series={series2}
                  type="bar"
                  height={350}
                />
              </Col>
              <Col span={9}>
                <Chart
                  options={options3}
                  series={series3}
                  type="bar"
                  height={450}
                />
              </Col>

            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default CollectionOfDay;
