import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import {
  Row, Select, Space, Col, Button, Table, DatePicker, Checkbox,
} from "antd";
import { TableOutlined, BarChartOutlined } from "@ant-design/icons";
import "../../../../App.css";
import GetApiData from "../../../../utils/GetApiData";
import ReactApexChart from "react-apexcharts";

const paymentCol = [
  {
    title: "Payment modes",
    dataIndex: "paymentMode",
    key: "paymentMode",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Current",
    dataIndex: "totalAmountCurrent",
    key: "totalAmountCurrent",
  },
  {
    title: "Last week",
    dataIndex: "totalAmountPast",
    key: "totalAmountPast",
  },
];
const specialityWiseCol = [
  {
    title: "Speciality",
    dataIndex: "speciality",
    key: "speciality",

    render: (text) => <a>{text}</a>,
  },
  {
    title: "Current",
    dataIndex: "totalAmountCurrent",
    key: "totalAmountCurrent",
  },
  {
    title: "Last week",
    dataIndex: "totalAmountPast",
    key: "totalAmountPast",
  },
];

const serviceGroupWise = [
  {
    title: "Service group name",
    dataIndex: "serviceGroupName",
    key: "serviceGroupName",

    render: (text) => <a>{text}</a>,
  },
  {
    title: "Current",
    dataIndex: "totalAmountCurrent",
    key: "totalAmountCurrent",
  },
  {
    title: "Last week",
    dataIndex: "totalAmountPast",
    key: "totalAmountPast",
  },
];

const { Option } = Select;

const CollectionComparative = ({ apiHost, unitId, currencyInfo }) => {
  const [isChart, setIsChart] = useState(true);
  const [paymentData, setPaymentData] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [serviceGroupData, setServiceGroupData] = useState([]);
  const [visitTypeArr, setVisitTypeArr] = useState([]);
  const [paymentModeArr, setPaymentModeArr] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [checked, setChecked] = useState(true);
  const [pastCollectionChartData, setPastCollectionChartData] = useState([]);
  const [currentCollectionChartData, setCurrentCollectionChartData] = useState([]);

  const [options1, setOptions1] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    title: {
      text: "Mode of Payments",
      align: "centre",
    },
    legend: {
      position: "bottom",
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  });
  const [series1, setSeries1] = useState([]);
  const [series2, setSeries2] = useState([{ data: [] }]);
  const [series3, setSeries3] = useState([]);
  const [options3, setOptions3] = useState(options1);

  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      theme: {
        mode: "light",
        palette: `palette${Math.floor(Math.random() * 10) + 1}`,
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
    },
  });
  const [specialtyChartData, setSpecialtyChartData] = useState({
    series: [
      {
        name: "This Week",
        data: [],
      },
      {
        name: "Last Week",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
    },
  });
  const [servicesChartData, setServicesChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: "line",
      },
    },
    series: [],
  });
  const barChartOption = {
    chart: {
      height: 350,
      type: "bar",
    },
    tooltip: {
      x: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      y: {
        formatter: function (val) {
          return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
        },
        title: {
          formatter: function (seriesName) {
            // return seriesName
            return "";
          },
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30px",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        // ['John', 'Doe'],
        // ['Joe', 'Smith'],
        // ['Jake', 'Williams'],
        // 'Amber',
        // ['Peter', 'Brown'],
        // ['Mary', 'Evans'],
        // ['David', 'Wilson'],
        // ['Lily', 'Roberts'],
      ],
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
  };



  const getData = async (selectedVisitType, selectedPaymentMode) => {
    console.log(checked, "sortedDataVisit", selectedVisitType);
    var preToDate, prefromDate, currentFromDate = '', currentToDate;
    if (checked == true) {
      console.log("if check is true");
      if (moment().weekday() == 7) {
        console.log("current day is sunday");
        prefromDate = moment(preToDate).subtract(6, "days").format("YYYY-MM-DD");
        preToDate = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");

        currentFromDate = moment(new Date()).subtract(6, "days").format("YYYY-MM-DD");
        currentToDate = moment(new Date()).format("YYYY-MM-DD");



        // fnGetCollectionComparative(currentFromDate, currentToDate, prefromDate,
        //   preToDate, unitId, selectedVisitType, selectedPaymentMode
        // );
      } else {
        var curretCount = moment().weekday();
        console.log("current day is not sunday");


        currentFromDate = moment(new Date()).subtract(curretCount, "days").format("YYYY-MM-DD");
        currentToDate = moment(currentToDate).subtract(6, "days").format("YYYY-MM-DD");

        prefromDate = moment(currentFromDate).subtract(7, "days").format("YYYY-MM-DD");
        preToDate = moment(currentFromDate).subtract(1, "days").format("YYYY-MM-DD");



        console.log("uncheck block executed");
      }
    } else {
      var curretCount = moment().weekday();
      console.log("curretCount", curretCount);
      preToDate = moment(new Date()).subtract(curretCount, "days").format("YYYY-MM-DD");
      prefromDate = moment(preToDate).subtract(6, "days").format("YYYY-MM-DD");
      currentToDate = moment(new Date()).format("YYYY-MM-DD");
      currentFromDate = moment(new Date()).subtract(curretCount - 1, "days").format("YYYY-MM-DD");
      console.log("past Week", prefromDate, preToDate);
      console.log("Last Week", currentFromDate, currentToDate);
      console.log("if checked is false");
      // fnGetCollectionComparative(
      //   currentFromDate, currentToDate, prefromDate,
      //   preToDate, unitId, selectedVisitType, selectedPaymentMode
      // );
    }
    fnGetCollectionComparative(currentFromDate, currentToDate, prefromDate,
      preToDate, unitId, selectedVisitType, selectedPaymentMode
    );

    fnGetSpecialityWiseServiceGroupWise(currentFromDate, currentToDate, prefromDate,
      preToDate, unitId, selectedVisitType, selectedPaymentMode
    );

  };
  useEffect(() => {
    getData(selectedVisitType, selectedPaymentMode);
  }, [checked]);
  useEffect(() => {
    getData(selectedVisitType, selectedPaymentMode);
  }, []);
  const fnGetCollectionComparative = async (fromDate, toDate, prefromDate, preToDate,
    unitId, selectedVisitType, selectedPaymentMode
  ) => {
    var res = await GetApiData.fnCollectionComparative({
      apiHost, fromDate, toDate, prefromDate,
      preToDate, unitId, selectedVisitType, selectedPaymentMode,
    });
    console.log("rescolll", res);
    let tempArr = res.collectionComparative;
    let tempArr2 = tempArr.sort((a, b) => b.amount - a.amount);
    let arrPaymentMode = [],
      arrSpeciality = [],
      arrServiceGroup = [],
      pastCollectionData = [],
      currentCollectionData = [];
    var serviceGroupTotal = 0;
    console.log("tempArr2", tempArr2);
    tempArr2.map((col, index) => {



      let item = arrPaymentMode.findIndex(
        (i) => i.paymentMode == col.payment_mode_desc
      );

      //payment mode start
      if (item == -1) {
        console.log(col, "itewithhhhh")
        if (col.tag == 'Current') {
          arrPaymentMode.push({
            key: index,
            paymentMode: col.payment_mode_desc,
            totalAmountCurrent: Math.round(+col.amount),
            totalAmountPast: 0,
          });
        }
        else {
          arrPaymentMode.push({
            key: index,
            paymentMode: col.payment_mode_desc,
            totalAmountCurrent: 0,
            totalAmountPast: Math.round(+col.amount),
          });
        }

      } else {
        console.log(arrPaymentMode[item], "aitemmmmmmmmmmm");
        if (col.tag == "Current") {
          arrPaymentMode[item].totalAmountCurrent += Math.round(+col.amount);
        } else {
          arrPaymentMode[item].totalAmountPast += Math.round(+col.amount);
        }
      }
      arrPaymentMode.sort(
        (a, b) => b.totalAmountCurrent - a.totalAmountCurrent
      );





      //payment mode start end

      //  speciality wise-------------------------
      if (col.is_clinical_speciality == "Y" || col.is_surgical_code == "Y") {
        let specialityItemIndex = arrSpeciality.findIndex(
          (i) => i.speciality == col.Department
        );
        if (specialityItemIndex == -1) {
          arrSpeciality.push({
            key: index,
            speciality: col.Department,
            totalAmountCurrent: parseInt(+col.amount),
            totalAmountPast: 0,
          });
        } else {
          if (col.tag == "Current") {
            arrSpeciality[specialityItemIndex].totalAmountCurrent += parseInt(
              +col.amount
            );
          } else {
            arrSpeciality[specialityItemIndex].totalAmountPast += parseInt(
              +col.amount
            );
          }
        }
        //  speciality wise end ------------------------------
      } else {
        //  service group  wise end-------------------------
        let serviceGroupIndex = arrServiceGroup.findIndex(
          (i) => i.serviceGroupName == col.Department
        );
        if (serviceGroupIndex == -1) {
          arrServiceGroup.push({
            key: index,
            serviceGroupName: col.Department,
            totalAmountCurrent: parseInt(col.amount),
            totalAmountPast: 0,
          });
        } else {
          if (col.tag == "Current") {
            arrServiceGroup[serviceGroupIndex].totalAmountCurrent += parseInt(
              col.amount
            );
          } else {
            arrServiceGroup[serviceGroupIndex].totalAmountPast += parseInt(
              col.amount
            );
          }
        }
        //  service group wise end ------------------------------
      }
      if (arrSpeciality.length > 5) {
        const newArray = arrSpeciality.slice(0, 4);

        // Find the existing item with serviceGroupName: "Other"
        const otherItem = arrSpeciality.find(
          (item) => item.speciality === "Other"
        );
        // Calculate the total sum of totalAmountCurrent and totalAmountPast for the rest of the items
        const otherTotalCurrent = arrSpeciality
          .slice(4)
          .reduce((sum, item) => sum + item.totalAmountCurrent, 0);
        const otherTotalPast = arrSpeciality
          .slice(4)
          .reduce((sum, item) => sum + item.totalAmountPast, 0);
        if (otherItem) {
          otherItem.totalAmountCurrent = otherTotalCurrent;
          otherItem.totalAmountPast = otherTotalPast;
        } else {
          newArray.push({
            key: -1, // You can use any unique key here
            speciality: "Other",
            totalAmountCurrent: otherTotalCurrent,
            totalAmountPast: otherTotalPast,
          });
        }
        arrSpeciality = newArray;
      }
      arrSpeciality.sort((a, b) => b.totalAmountCurrent - a.totalAmountCurrent);
      console.log("arrSpecialityyyyyyyyyyyyy", arrSpeciality);
      if (arrServiceGroup.length > 5) {
        const newArray = arrServiceGroup.slice(0, 4);

        // Find the existing item with serviceGroupName: "Other"
        const otherItem = arrServiceGroup.find(
          (item) => item.serviceGroupName === "Other"
        );
        // Calculate the total sum of totalAmountCurrent and totalAmountPast for the rest of the items
        const otherTotalCurrent = arrServiceGroup
          .slice(4)
          .reduce((sum, item) => sum + item.totalAmountCurrent, 0);
        const otherTotalPast = arrServiceGroup
          .slice(4)
          .reduce((sum, item) => sum + item.totalAmountPast, 0);
        if (otherItem) {
          otherItem.totalAmountCurrent = otherTotalCurrent;
          otherItem.totalAmountPast = otherTotalPast;
        } else {
          newArray.push({
            key: -1, // You can use any unique key here
            serviceGroupName: "Other",
            totalAmountCurrent: otherTotalCurrent,
            totalAmountPast: otherTotalPast,
          });
        }
        arrServiceGroup = newArray;
      }
      arrServiceGroup.sort(
        (a, b) => b.totalAmountCurrent - a.totalAmountCurrent
      );
      //  service group  wise  end-------------------
    });
    var totalPaymentCurrentAmount = arrPaymentMode.reduce(
      (total, currentObject) => total + currentObject.totalAmountCurrent,
      0
    );
    var totalPaymenPastAmount = arrPaymentMode.reduce(
      (total, currentObject) => total + currentObject.totalAmountPast,
      0
    );



    console.log("arrPaymentMode", arrPaymentMode);
    console.log("arrServiceGroup", arrServiceGroup);
    console.log("arrSpeciality", arrSpeciality);
    // payment mode wise
    const newArr = arrPaymentMode.map((item) => item.totalAmountCurrent);
    const newArr2 = arrPaymentMode.map((item) => item.totalAmountPast);

    //// specialty wise
    const specPast = arrSpeciality.map((item) => item.totalAmountPast);
    const specCurrent = arrSpeciality.map((item) => item.totalAmountCurrent);
    const specNames = arrSpeciality.map((item) => item.speciality);
    const paymentMode = arrPaymentMode.map((item) => item.paymentMode);
    const servicesCurrent = arrServiceGroup.map(
      (item) => item.totalAmountCurrent
    );
    const servicesPast = arrServiceGroup.map((item) => item.totalAmountPast);
    const servicesName = arrServiceGroup.map((item) => item.serviceGroupName);
    console.log(newArr2, "newarrrrrrrrrrrr");
    setChartData({
      series: [
        {
          name: "This Week",
          data: newArr,
        },
        {
          name: "Last Week",
          data: newArr2,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        title: {
          text: "Collection-comparative",
          align: "centre",
        },
        theme: {
          mode: "light",
          palette: `palette${Math.floor(Math.random() * 10) + 1}`,
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30px",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: paymentMode,
          title: {
            text: "Payment methods",
          },
        },
        yaxis: {
          title: {
            text: currencyInfo,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            },
          },
        },
      },
    });
    arrPaymentMode.push({
      key: Math.random(),
      paymentMode: "Total",
      totalAmountCurrent: totalPaymentCurrentAmount,
      totalAmountPast: totalPaymenPastAmount,
      // totalAmount: parseFloat(colTotal).toFixed(2),
    });

    setCurrentCollectionChartData(newArr);
    setPastCollectionChartData(newArr2);
    console.log(arrPaymentMode, "payeeraaa");
    setPaymentData(arrPaymentMode);
    // setSpecialityData(arrSpeciality);
    // setServiceGroupData(arrServiceGroup);
  };


  const fnGetSpecialityWiseServiceGroupWise = async (fromDate, toDate, prefromDate, preToDate,
    unitId, selectedVisitType, selectedPaymentMode) => {
    var resSpeciality = await GetApiData.fnCollectionComparativeSpeciality_ServiceGRoupWise({
      apiHost, fromDate, toDate, prefromDate, preToDate, unitId, selectedVisitType,
      selectedPaymentMode
    });
    var sortedArr_Speciality = resSpeciality.collectionComparative.sort((a, b) => b.Amount - a.Amount);
    console.log(sortedArr_Speciality, "compareslppppp");


    var sortedArr_servicegr = resSpeciality.serviceGroupWise.sort((a, b) => b.Amount - a.Amount);

    let arrPaymentMode = [], arrSpeciality = [], arrServiceGroup = [];
    var totalSpecialtyCurrentAmount = 0, totalSpecialtyPastAmount = 0;
    sortedArr_Speciality.map((col, index) => {
      let specialityItemIndex = arrSpeciality.findIndex(
        (i) => i.speciality == col.Department
      );
      if (specialityItemIndex == -1) {
        if (col.tag == 'Current') {
          arrSpeciality.push({
            key: index,
            speciality: col.Department,
            totalAmountCurrent: parseInt(+col.Amount),
            totalAmountPast: 0,
          });
        }
        else {
          arrSpeciality.push({
            key: index,
            speciality: col.Department,
            totalAmountCurrent: 0,
            totalAmountPast: parseInt(+col.Amount),
          });
        }

      } else {
        if (col.tag == "Current") {
          arrSpeciality[specialityItemIndex].totalAmountCurrent += parseInt(
            +col.Amount
          );
        } else {
          arrSpeciality[specialityItemIndex].totalAmountPast += parseInt(
            +col.Amount
          );
        }
      }

      if (col.tag == 'Current') {
        totalSpecialtyCurrentAmount += parseInt(+col.Amount);
      }
      else {
        totalSpecialtyPastAmount += parseInt(+col.Amount);
      }



    })

    var totalServicesCurrentAmount = 0, totalServicesPastAmount = 0;
    sortedArr_servicegr.map((col, index) => {
      let serviceGroupIndex = arrServiceGroup.findIndex(
        (i) => i.serviceGroupName == col.Department
      );
      if (serviceGroupIndex == -1) {
        if (col.tag == 'Current') {

          arrServiceGroup.push({
            key: index,
            serviceGroupName: col.Department,
            totalAmountCurrent: parseInt(col.Amount),
            totalAmountPast: 0,
          });
        }
        else {
          arrServiceGroup.push({
            key: index,
            serviceGroupName: col.Department,
            totalAmountCurrent: 0,
            totalAmountPast: parseInt(col.Amount),
          });
        }



      } else {
        if (col.tag == "Current") {


          arrServiceGroup[serviceGroupIndex].totalAmountCurrent += parseInt(
            col.Amount
          );
        } else {
          arrServiceGroup[serviceGroupIndex].totalAmountPast += parseInt(
            col.Amount
          );
        }
      }

      if (col.tag == 'Current') {
        totalServicesCurrentAmount += col.Amount
      }
      else {
        totalServicesPastAmount += col.Amount
      }

    })

    arrSpeciality.sort((a, b) => b.totalAmountCurrent - a.totalAmountCurrent);
    arrServiceGroup.sort((a, b) => b.totalAmountCurrent - a.totalAmountCurrent);
    console.log(arrSpeciality, "spefffff");

    const specPast = arrSpeciality.map((item) => item.totalAmountPast);
    const specCurrent = arrSpeciality.map((item) => item.totalAmountCurrent);
    const specNames = arrSpeciality.map((item) => item.speciality.toUpperCase());


    const servicesCurrent = arrServiceGroup.map((item) => item.totalAmountCurrent);
    const servicesPast = arrServiceGroup.map((item) => item.totalAmountPast);
    const servicesName = arrServiceGroup.map((item) => item.serviceGroupName);


    setSpecialtyChartData({
      series: [
        {
          name: "This Week",
          data: specCurrent,
        },
        {
          name: "Last Week",
          data: specPast,
        },
      ],
      options: {
        title: {
          text: "Specialty wise-comparative",
          align: "centre",
        },
        chart: {
          type: "bar",
          height: 350,
        },
        theme: {
          mode: "light",
          palette: `palette${Math.floor(Math.random() * 10) + 1}`,
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "40px",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },

        xaxis: {
          categories: specNames,
          title: {
            text: "Specialty Names",
          },
        },
        yaxis: {
          title: {
            text: currencyInfo,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            },
          },
        },
      },
    });

    arrSpeciality.push({
      key: Math.random(),
      speciality: "Total",
      totalAmountCurrent: parseInt(totalSpecialtyCurrentAmount),
      totalAmountPast: parseInt(totalSpecialtyPastAmount),
      // totalAmount: parseFloat(colTotal).toFixed(2),
    });

    setServicesChartData({
      options: {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "Service group wise",
          align: "centre",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          formatter: function (val, opts) {
            return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
          },
        },
        theme: {
          mode: "light",
          palette: `palette${Math.floor(Math.random() * 10) + 1}`,
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50px",
            endingShape: "rounded",
          },
        },
        xaxis: {
          categories: servicesName,
        },
        yaxis: [
          {
            title: {
              text: currencyInfo,
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            },
          },
        },
      },
      series: [
        {
          name: "This Week",
          type: "column",
          data: servicesCurrent,
        },
        {
          name: "Last Week",
          type: "line",
          data: servicesPast,
        },
      ],
    });

    arrServiceGroup.push({
      key: Math.random(),
      serviceGroupName: "Total",
      totalAmountCurrent: parseInt(totalServicesCurrentAmount),
      totalAmountPast: parseInt(totalServicesPastAmount),
      // totalAmount: parseFloat(colTotal).toFixed(2),
    });

    setSpecialityData(arrSpeciality);
    setServiceGroupData(arrServiceGroup);
  }
  const fnGetVisitTypeAndPaymentModeList = async () => {
    var res = await GetApiData.fnGetVisitListAndPaymentModeList({ apiHost });
    var data = res.paymentModeList.length > 0 ? res.paymentModeList : [];
    var visitData = res.visitTypeList.length > 0 ? res.visitTypeList : [];
    var sortedData = data.sort((a, b) =>
      a.payment_mode_code > b.payment_mode_code
        ? 1
        : b.payment_mode_code > a.payment_mode_code
          ? -1
          : 0
    );
    var sortedDataVisit = visitData.sort((a, b) =>
      a.visit_type_name > b.visit_type_name ? 1 : b.visit_type_name > a.visit_type_name
        ? -1 : 0
    );
    console.log(sortedDataVisit, "sortedDataVisit");
    console.log(sortedData, "sortedData");
    if (res.paymentModeList) {
      setPaymentModeArr(sortedData);
      setVisitTypeArr(sortedDataVisit);
    }
  };
  useEffect(() => {
    fnGetVisitTypeAndPaymentModeList();
  }, []);
  const fnShowCharts = async (visitType, paymentMode) => {
    var labelsArr = [],
      seriesArr = [];
    paymentData.map((pay) => {
      labelsArr.push(pay["paymentMode"]);
      seriesArr.push(pay["current"]);
    });

    labelsArr.pop(); // Removes the last element
    seriesArr.pop();
    var copyOptions = {
      ...options1,
      labels: labelsArr,
    };
    setOptions1(copyOptions);
    setSeries1(seriesArr);

    // var _option2, specialityTypeArr=[],countValue=[];
    var categoriesArr = [],
      amountArr = [];

    specialityData.map((spc, index) => {
      categoriesArr.push(spc.speciality);
      amountArr.push(spc.current);
    });

    categoriesArr.pop();

    amountArr.pop();
    var data = [
      {
        data: amountArr,
      },
    ];
    setSeries2(data);

    var categoriesArr2 = [],
      amountArr2 = [];

    console.log(serviceGroupData, "segrouppppp");

    serviceGroupData.map((spc, index) => {
      categoriesArr2.push(spc.serviceGroupName);
      amountArr2.push(spc.current);
    });

    categoriesArr.pop();

    var Opt3 = {
      ...barChartOption,
      xaxis: {
        ...barChartOption.xaxis,
        categories: categoriesArr2,
      },
    };
    setOptions3(Opt3);
    amountArr2.pop();
    data = [
      {
        data: amountArr2,
      },
    ];
    setSeries3(data);
  };

  const onChangeCheckbox = (e) => {
    console.log(`checkedhhhhh ${e.target.checked}`);
    setChecked(e.target.checked);
  };

  return (
    <>
      <div id="chart" style={{ minHeight: 200 }}>
        <Row
          gutter={[16]}
          align="middle"
          justify="space-between"
          style={{ marginBottom: 16 }}
        >
          <Col lg={6} md={8} sm={12} xs={12}>
            <div className=" table-chart-heading">Collection comparative</div>
          </Col>
          <Col lg={12} md={12} sm={16} xs={24}>
            <Row align="middle" justify="space-between" gutter={[12]}>
              <Col lg={7} md={6} sm={12} xs={12}>
                <Checkbox checked={checked} onChange={onChangeCheckbox}>
                  Completed period
                </Checkbox>
              </Col>
              <Col lg={7} md={8} sm={12} xs={24}>
                {visitTypeArr.length > 0 && (
                  <Select
                    className="w-100"
                    onChange={async (value) => {
                      setSelectedVisitType(value);
                      getData(value, selectedPaymentMode)
                      // await fnGetCollection(
                      //   value,
                      //   selectedPaymentMode,
                      //   fromDate,
                      //   toDate
                      // );
                      // await fnShowCharts(value, selectedPaymentMode);
                    }}
                    // mode="multiple"
                    placeholder="Select Visit Type"
                  >
                    {visitTypeArr.map((u) => {
                      // return <Option value={u.unitid} key={u.unitid}>{u.unitname}</Option>
                      return (
                        <Option value={u.visit_type_id} key={u.visit_type_id}>
                          {u.visit_type_name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Col lg={7} md={8} sm={12} xs={24}>
                {paymentModeArr.length > 0 && (
                  <Select
                    className="w-100"
                    onChange={(value) => {
                      setSelectedPaymentMode(value);
                      getData(selectedVisitType, value)
                      // fnGetCollection(
                      //   selectedVisitType,
                      //   value,
                      //   fromDate,
                      //   toDate
                      // );
                      // fnShowCharts(selectedVisitType, value);
                    }}
                    // mode="multiple"
                    placeholder="Select Payment Mode"
                  >
                    {paymentModeArr.map((u) => {
                      // return <Option value={u.unitid} key={u.unitid}>{u.unitname}</Option>
                      return (
                        <Option
                          value={u.payment_mode_id}
                          key={u.payment_mode_id}
                        >
                          {u.payment_mode_desc}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <Button
                  className="buttonColor"
                  type="link"
                  icon={<TableOutlined className="tableColor" />}
                  onClick={() => {
                    setIsChart(false);
                  }}
                ></Button>
                <Button
                  className="buttonColor"
                  type="link"
                  icon={<BarChartOutlined className="tableColor" />}
                  onClick={() => {
                    setIsChart(true);
                    fnShowCharts(selectedVisitType, selectedPaymentMode);
                  }}
                ></Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16]}>
          {console.log(paymentCol, "paycollll", paymentData)}
          {!isChart ? (
            <>
              <Col span={8}>
                <Table
                  columns={paymentCol}
                  dataSource={paymentData}
                  pagination={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  bordered
                  size="small"
                />
              </Col>
              <Col span={8}>

                <Table
                  scroll={
                    specialityData.length > 5
                      ? {
                        y: 233,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                  columns={specialityWiseCol}
                  dataSource={specialityData}
                  pagination={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  bordered
                  size="small"
                />
              </Col>
              <Col span={8}>
                {
                  console.log(serviceGroupData, "grdatttttt")
                }
                <Table
                  scroll={
                    serviceGroupData.length > 5
                      ? {
                        y: 233,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                  columns={serviceGroupWise}
                  dataSource={serviceGroupData}
                  pagination={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  bordered
                  size="small"
                />
              </Col>
            </>
          ) : (
            <>

              <Col>
                {
                  console.log(specialtyChartData, "chartttttt")
                }

                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={specialtyChartData.series[0].data.length > 7 ?
                    specialtyChartData.series[0].data.length * 42 : 400}
                  width={370}
                />
              </Col>

              <Col>
                <ReactApexChart
                  options={specialtyChartData.options}
                  series={specialtyChartData.series}
                  type="bar"
                  // height={specialtyChartData.series[0].data.length > 7 ? 550 : 380}
                  height={specialtyChartData.series[0].data.length > 7 ?
                    specialtyChartData.series[0].data.length * 42 : 400}
                  width={460}
                />
              </Col>
              <Col>

                <ReactApexChart
                  options={servicesChartData.options}
                  series={servicesChartData.series}
                  type="line"
                  height={specialtyChartData.series[0].data.length > 7 ?
                    specialtyChartData.series[0].data.length * 42 : 400}
                  width={565}
                />
              </Col>

            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default CollectionComparative;
