import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Select,
  Space,
  Col,
  Button,
  Table,
  DatePicker,
  Checkbox,
} from "antd";
import { TableOutlined, BarChartOutlined } from "@ant-design/icons";
import "../../../../App.css";
import GetApiData from "../../../../utils/GetApiData";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;
const { RangePicker } = DatePicker;
const caseTypeCol = [
  {
    title: "Case Type",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Admissions",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Last Year",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
];
const bedAddmissionCol = [
  {
    title: "Bed Category",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Admissions",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Last Year",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
];
const occpancyCol = [
  {
    title: "Bed Category",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Operational Bed",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Occupancy Rate",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
];
const Admission = () => {
  const [currentView, setCurrentView] = useState("graphView");
  const [specialltyWiseChart, setSpecialtyWiseChart] = useState({
    series: [
      {
        name: "OP",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "IP",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Daycare",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "ER",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
    ],
    options: {
      chart: {
       
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      theme: {
        mode: "light",
        palette: "palette5",
        monochrome: {
          enabled: false,
          color: "#5653FE",
          shadeTo: "light",
          shadeIntensity: 0.95,
        },
      },
      labels: ["OP", "IP", "Daycare", "ER"],
    },
  });
  const handleSwitchView = (name) => {
    setCurrentView(name);
  };
  return (
    <div>
      <Row align="top" justify="space-between" className="mb-2">
        <Col lg={9} md={8} sm={12} xs={24}>
          <Row align="top" justify="space-evenly" gutter={[24, 12]}>
            <Col lg={12} md={12} sm={12}>
              <RangePicker
                onChange={(value) => {
                  console.log("value", value);
                }}
              />
            </Col>

            <Col lg={12} md={12} sm={12}>
              <Select placeholder="Bed Category" className="w-100"></Select>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={8} sm={12} xs={24}>
          <div className="text-center table-chart-heading">Admission</div>
        </Col>
        <Col lg={9} md={8} sm={12} xs={24}>
          <Row align="top" justify="end" gutter={[24, 12]}>
            <Col lg={6} md={4} sm={12}>
              <Row align="top" justify="space-evenly" gutter={[24, 12]}>
                <Col>
                  <Button
                    className="buttonColor"
                    onClick={() => {
                      handleSwitchView("tableView");
                    }}
                    type="link"
                    icon={<TableOutlined />}
                  />
                </Col>
                <Col>
                  <Button
                    className="buttonColor"
                    onClick={() => {
                      handleSwitchView("graphView");
                    }}
                    type="link"
                    icon={<BarChartOutlined />}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {currentView === "tableView" && (
        <>
          <Row align="top" justify="space-between" gutter={[16]}>
            <Col lg={8} md={12} sm={12} xs={24}>
              <Table
                columns={caseTypeCol}
                dataSource={[]}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                bordered
                size="small"
              />
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <Table
                columns={bedAddmissionCol}
                dataSource={[]}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                bordered
                size="small"
              />
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <Table
                columns={occpancyCol}
                dataSource={[]}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                bordered
                size="small"
              />
            </Col>
          </Row>
        </>
      )}
      {currentView === "graphView" && (
        <>
          <Row align="top" justify="space-between" gutter={[16]}>
            <Col lg={8} md={12} sm={12} xs={24}>
              <div className="text-center mb-2 table-sub-heading">
                Case Type
              </div>
              <ReactApexChart
                options={specialltyWiseChart.options}
                series={specialltyWiseChart.series}
                type="bar"
              />
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <div className="text-center mb-2 table-sub-heading">
                Bed Admission
              </div>
              <ReactApexChart
                options={specialltyWiseChart.options}
                series={specialltyWiseChart.series}
                type="bar"
              />
            </Col>
            <Col lg={8} md={12} sm={12} xs={24}>
              <div className="text-center mb-2 table-sub-heading">
                Bed Occupancy
              </div>
              <ReactApexChart
                options={specialltyWiseChart.options}
                series={specialltyWiseChart.series}
                type="bar"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Admission;
