import React, { useEffect, useState } from 'react'
import {
  Layout, Typography, Menu, Breadcrumb, Select, Input, DatePicker, Button, Anchor, Drawer, Collapse, Row, Col,
  TimePicker,
  InputNumber
} from 'antd'
import GetApiData from '../../utils/GetApiData'
// import { Page, Font, Text, Document } from '@react-pdf/renderer'
import moment from 'moment'
// import { PDFReader } from 'reactjs-pdf-reader'
import utilsFunction from '../../utils/utilsFunction'
// import { Document, Page } from 'react-pdf'

const { Panel } = Collapse

const style = {
  border: '1px solid #0092ff',
  padding: '8px 0',
  width: "98%",
  margin: "8px auto",
  minHeight: typeof window !== 'undefined' ? (window.innerHeight - 100) : null
}
const { RangePicker } = DatePicker
const { TimeRangePicker } = TimePicker


// Create style with font-family
const styles = {
  fontFamily: "Roboto"
}
const MisReport = ({ apiHost, unitId }) => {
  const [menuHeaderArr, setMenuHeaderArr] = useState([])
  const [subMenuList, setSubMenuList] = useState([])
  const [renderedElements, setRenderedelElements] = useState([])
  const [parameterList, setParameterList] = useState([])
  const [fromDate, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [timeFrom, setTimeFrom] = useState()
  const [timeTo, setTimeTo] = useState()
  const [value, setValue] = useState([null, null])
  const [expireDatePicker, setExpireDatePicker] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [patientList, setPatientList] = useState([])
  const [itemList, setItemList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [selectedPAtient, setSelectedPAtient] = useState('')
  const [selectedSubMenu, setSelectedSubMenu] = useState('')
  const [pdfUrl, setPdfUrl] = useState('');
  const [objUrl, setObjUrl] = useState(null);
  const [base64String, setBase64String] = useState(null);


  useEffect(() => {
    fnGetMISHEader()
  }, [])

  const fnGetMISHEader = async () => {
    var result = await GetApiData.fnGetMISHeaderMaster({ apiHost })
    setMenuHeaderArr(result.listObject)
  }

  const onSearchPatient = async (searchText) => {
    if (searchText.length > 0) {
      var resultPatientList = await GetApiData.fnGetPatientList({ apiHost, searchText })

      var newPatientData = []
      resultPatientList.map((pObj, index) => {
        var pObj1 = {
          value: JSON.stringify(pObj),
          label: `${pObj.firstName + " " + pObj.lastName + " | " + pObj.uhidNumber}`,
        }
        newPatientData.push(pObj1)
      })
      setPatientList([...newPatientData])
      console.log(patientList, 'search:', newPatientData)
    }

  }
  const onSearchItem = async (searchText) => {
    if (searchText.length > 2) {
      var resultItemList = await GetApiData.fnGetItemList({ apiHost, searchText })

      var newItemData = []
      resultItemList.map((pObj, index) => {
        var pObj1 = {
          value: JSON.stringify(pObj),
          label: `${pObj.medicationName}`,
        }
        newItemData.push(pObj1)
      })
      setItemList([...newItemData])

    }

  }

  const onSearchService = async (searchText) => {
    if (searchText.length > 2) {
      var resultServiceList = await GetApiData.fnGetServiceList({ apiHost, searchText })

      var newServiceData = []
      resultServiceList.map((pObj, index) => {
        var pObj1 = {
          value: JSON.stringify(pObj),
          label: `${pObj.serviceDescription}`,
        }
        newServiceData.push(pObj1)
      })
      setServiceList([...newServiceData])
      console.log(patientList, 'search:', newServiceData)
    }

  }




  useEffect(() => {
    console.log(patientList, "usepatientnnn")
    setPatientList(patientList)
  }, [patientList])
  const onChange = (value) => {
    console.log(`selected ${value}`)
    setSelectedPAtient(value)
  }

  const handleMultiSelect = (index, valueArr, elementObj) => {
    console.log(`selectedmultiii`, valueArr)
    // paramValue: '66904,66880,66900'
    var paramValue = []
    valueArr.map((obj, index) => {
      var parsedObj = JSON.parse(obj)
      paramValue.push(parsedObj.id)
    })
    paramValue = paramValue.join(",")
    handleChange(index, paramValue, elementObj)
    console.log(paramValue, "paraamvallll")
  }

  const fnRenderElements = (elementObj, index) => {


    var element = null, optionArr = []
    if (elementObj.controlName !== 'DatePickerTo' || elementObj.controlName !== 'TimePickerFrom') {
      console.log(elementObj, "elelllllaaa")
      elementObj.metaDataDetailsDtosList !== null && elementObj.metaDataDetailsDtosList.map((ddListObj, index) => {
        optionArr.push({
          value: JSON.stringify(ddListObj),
          label: ddListObj.name !== null ? ddListObj.name : ddListObj.label ? ddListObj.label : "",
        })
      })
    }


    switch (elementObj.controlName) {

      case "DatePickerFrom":
        element = element = <DatePicker
          placeholder='From Date'
          onChange={(val) => {
            console.log(val, "valllhhhghghg")
            handleChange(index, moment(val).format("YYYY-MM-DD"), elementObj)

          }}


          className="thirdDD"
          disabledDate={(current) => {
            return current && current > moment().endOf('day')
          }}
          style={{ marginRight: 15, marginBottom: 15 }}

        />
        break

      case "DatePickerTo":
        element = <DatePicker
          placeholder='To Date'
          onChange={(val) => {
            console.log(val, "valllhhhghghg")
            handleChange(index, moment(val).format("YYYY-MM-DD"), elementObj)

          }}


          className="thirdDD"
          disabledDate={(current) => {
            return current && current > moment().endOf('day')
          }}
          style={{ marginRight: 15, marginBottom: 15 }}

        />
        break
      case "DatePickerExpiry":
        element = <DatePicker
          placeholder='To Date'
          onChange={(val) => {

            handleChange(index, moment(val).format("YYYY-MM-DD"), elementObj)

          }}


          className="thirdDD"
          disabledDate={(current) => {
            return current && current > moment().endOf('day')
          }}
          style={{ marginRight: 15, marginBottom: 15 }}

        />
        break
      case "TimePickerFrom":
        element = <TimePicker.RangePicker style={{ marginRight: 15, marginBottom: 15 }} format={"HH:mm"} onChange={(e) => {
          console.log(e, "timmmmmeeeeeeee")
          setTimeFrom(moment(e[0]).format("HH:MM:ss"))
          setTimeTo(moment(e[1]).format("HH:MM:ss"))
        }} />
        break

      case 'unit':
        element = <Select
          placeholder={`Select ${elementObj.controlName}`}
          style={{
            width: 150, marginRight: 15, marginBottom: 15
          }}
          onSelect={(e) => {
            handleChange(index, e, elementObj)
          }}
          options={optionArr}
        />
        break

      case 'list': element = <Select
        placeholder={`Select ${elementObj.lable}`}
        onChange={(e) => {
          handleChange(index, e, elementObj)
        }}
        style={{ width: 250, marginRight: 15, marginBottom: 15 }}
        options={optionArr}
      />
        break

      case "text": element = <Select
        showSearch
        style={{
          width: 260,
          marginRight: 15
        }}
        placeholder="Search Patient"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearchPatient}
        filterOption={(input, option) => {
          return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        }
        options={patientList}
      // options={[]}


      />
        break

      case "ItemSearch": element = <>
        <Select
          showSearch
          style={{
            width: 260,
            marginRight: 15
          }}
          placeholder="Search Item"
          optionFilterProp="children"
          // onChange={onChange}
          onChange={(e) => {
            console.log(`selectedkjkjkj `, index, e, elementObj)
            handleChange(index, JSON.parse(e).drugId, elementObj)
          }}
          onSearch={onSearchItem}
          filterOption={(input, option) => {
            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          }
          options={itemList}
        // options={[]}


        />
      </>
        break
      case "textBox": element = <InputNumber placeholder={`${elementObj.lable}`}
        style={{
          width: 260,
          marginRight: 15
        }}
        onBlur={(e) => {
          if (e.target.value > 120) {
            alert("Please enter valid age")
            return
          }
          handleChange(index, e.target.value, elementObj)

        }} />

      case "Servicesearch": element = <>
        <Select
          showSearch
          style={{
            width: 260,
            marginRight: 15
          }}
          placeholder="Services"
          optionFilterProp="children"
          // onChange={onChange}
          onChange={(e) => {
            console.log(e, "searchhsss")
            handleChange(index, JSON.parse(e).serviceMasterId, elementObj)
          }}
          onSearch={onSearchService}
          filterOption={(input, option) => {
            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          }
          options={serviceList}
        // options={[]}


        />
      </>

      case "multiSelectlist":
        element = <>
          <Select
            mode="multiple"

            allowClear
            style={{
              width: '350px',
              marginRight: 15,
              marginBottom: 20,
            }}
            placeholder={`${elementObj.lable}`}

            onChange={(e) => {
              console.log(e, "multippppdfd")
              handleMultiSelect(index, e, elementObj)
            }}
            options={optionArr}
          />
        </>
    }
    console.log(patientList, "paswitchhhh")
    return element
  }
  const handleChange = (index, value, elementObj) => {

    var newObj = { ...elementObj }
    if (elementObj.controlName == "textBox" || elementObj.controlName == "DatePickerTo" ||
      elementObj.controlName == "DatePickerFrom" || elementObj.controlName == "ItemSearch" ||
      elementObj.controlName == "DatePickerExpiry" || elementObj.controlName == "Servicesearch"
      || elementObj.controlName == "multiSelectlist"
    ) {
      newObj.paramValue = value
    }
    else {
      newObj.paramValue = JSON.parse(value).id
    }

    console.log("couuuu", newObj)
    var parameterListcopy = [...parameterList]
    parameterListcopy.splice(index, 1, newObj)
    setParameterList(parameterListcopy)
    console.log(parameterListcopy, "copyyyyyy")
  }


  const fnGetReportDetailByReportId = async (submenu, reportId) => {
    setSelectedSubMenu(submenu)
    var result = await GetApiData.fnGetReportDetailsByReportId({ apiHost, reportId })
    result.map((r, index) => {
      console.log(r.controlName, "ctrlnnnnnnnnnnnnnnn")
    })
    setParameterList(result)


  }

  const fnGetSubMenu = async (selectedHeaderId) => {

    if (selectedHeaderId !== undefined) {

      var result = await GetApiData.fnGetSubMenuList({ apiHost, selectedHeaderId })
      setSubMenuList(result)
    }
    // Place your event handling logic here
  }
  const fnRenderHeaderMenu = () => {

    var headerMenu = <Collapse onChange={fnGetSubMenu} accordion>
      {
        menuHeaderArr.map((menu, index) => {
          return <Collapse.Panel header={<span style={{ fontWeight: 500, color: 'blue', fontSize: 15 }}>{menu.reportGroupHeaderName}</span>} key={menu.reportGroupHeaderId}>
            <>

              {
                subMenuList.map((submenu, index) => {
                  return <>
                    <Button type='link' className={selectedSubMenu == submenu ? 'misSelectedMenu' : 'misNonSelectedMenu'}
                      onClick={() => { setPdfUrl(''); fnGetReportDetailByReportId(submenu, submenu.reportId) }}>{submenu.reportName}</Button>
                    <br></br>
                  </>
                })
              }

            </>
          </Collapse.Panel>
        })
      }

    </Collapse>
    return headerMenu
  }

  const fnGetMISReport = async () => {
    console.log(parameterList, "parammmmmmm")

    var finalArr = []

    parameterList.map((value, index) => {
      console.log(value.controlName, "ctrillllllllllll")
      // var newObj = { paramName: 'FromDate', paramType: 'string', paramValue: '2023-04-06' }
      var newObj = { paramName: value.parameterName, paramType: value.paramType }
      switch (value.controlName) {
        case "DatePickerFrom": newObj.paramValue = value.paramValue; break
        case "DatePickerTo": newObj.paramValue = value.paramValue; break
        case "DatePickerExpiry": newObj.paramValue = value.paramValue; break
        case "unit": newObj.paramValue = (value.paramValue == null || value.paramValue == undefined) ? 0 : value.paramValue; break
        case "ItemSearch": newObj.paramValue = (value.paramValue == null || value.paramValue == undefined) ? 0 : value.paramValue; break
        // case "TimePickerFrom": newObj.paramValue = timeFrom.toString() break
        // case "TimePickerTo": newObj.paramValue = timeTo.toString() break
        case "TimePickerFrom": newObj.paramValue = timeFrom; break
        case "TimePickerTo": newObj.paramValue = timeTo; break

        default:
          newObj.paramValue = (value.paramValue == null || value.paramValue == undefined) ? 0 : value.paramValue

        // // case "text": newObj.//remaining
        // case "DatePickerExpiry": newObj.paramValue = expireDatePicker break
        // // case "ItemSearch": remaining
        // // case "Servicesearch"remaining
        // // case "multiSelectlist": remaining
        // case "org": newObj.paramValue = (value.paramValue == null || value.paramValue == undefined) ? 0 : value.paramValue


      }
      finalArr.push(newObj)
      console.log(finalArr, "finallllArrrr")
    })
  }

  const getReport = async () => {
    if (apiHost == 'http://localhost:8081' || apiHost == 'https://qaclone.his.clinivantage.co.in') {
      apiHost = 'https://axonic.his.clinivantage.co.in'
      // apiHost = 'https://smepp.clinivantage.co.in'
      // apiHost = 'https://demo.his.clinivantage.co.in';
    
    }
   
    else {
      var subDomain = apiHost.split('//')[1].split(".")[0];
      apiHost = `https://${subDomain}.his.clinivantage.co.in`
    }

   
  //   var raw= {
  //     "appName": "HIS",
  //     "appSecretKey": "f306b6eccd9d5069d2dc71afe139271a",
  //     "reportDesignFilePath": "getConsultationDoctorwiseonly.rpt",
  //     "reportFormat": "PDF",
  //     "exportFileName": "admission",
  //     "reportParams": [
  //         {
  //             "paramName": "FromDate",
  //             "paramType": "string",
  //             "paramValue": "2023-05-19"
  //         },
  //         {
  //             "paramName": "ToDate",
  //             "paramType": "string",
  //             "paramValue": "2023-05-19"
  //         },
  //         {
  //             "paramName": "Unitid",
  //             "paramType": "integer",
  //             "paramValue": 1
  //         },
  //         {
  //             "paramName": "Doctorid",
  //             "paramType": "integer",
  //             "paramValue": "608"
  //         }
  //     ]
  // }
  //    var myHeaders = new Headers()
  //   myHeaders.append("Content-Type", "application/json")
  //   myHeaders.append("Accept", "application/pdf")
  //   myHeaders.append('responseType', 'arraybuffer')
    
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: JSON.stringify(raw),
  //     redirect: 'follow',

  //   }

   
  //   // fetch("https://smepp.clinivantage.co.in/api/billing/unit/bill/Report/MIS", requestOptions)
  //   fetch(`${apiHost}/api/billing/unit/bill/Report/MIS`, requestOptions)
  //   .then(response => response.blob())
  //   .then(result => {
  //     var blob = new File([result], 'myBill.pdf', { type: "application/pdf" })
  //     var link = document.createElement('a')
  //     link.href = window.URL.createObjectURL(blob)
  //     // console.log(window.URL.createObjectURL(blob), "tgtgggggggggggggg")
  //     setPdfUrl(window.URL.createObjectURL(blob))
  //     // link.download = "myBill.pdf"
  //     // link.click()
  //   })
  //   .catch(error => console.log('error', error))
    
    
    
    
    
    

  
    var finalArr = []
    parameterList.map((value, index) => {
      console.log(value.controlName, "ctrillllllllllll", value)
      // var newObj = { paramName: 'FromDate', paramType: 'string', paramValue: '2023-04-06' }
      var newObj = { paramName: value.parameterName, paramType: value.paramType }
      newObj.paramValue = (value.paramValue == null || value.paramValue == undefined) ? 0 : value.paramValue;
     
      finalArr.push(newObj)
      console.log(finalArr, "finallllArrrr",JSON.stringify(finalArr))
    })
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("Accept", "application/pdf")
    myHeaders.append('responseType', 'arraybuffer')

    var raw = {
      orgId:1,
      unitId:1,
      crData:{
        "appName": "HIS",
        "appSecretKey": "f306b6eccd9d5069d2dc71afe139271a",
        // "reportDesignFilePath": "getConsultationDoctorwiseonly.rpt",
        "reportDesignFilePath": parameterList[0].action,
        "reportFormat": "PDF",
        "exportFileName": "admission",
        // "reportParams": finalArr,
        "reportParams": [
          {
            "paramName": "FromDate",
            "paramType": "string",
            "paramValue": "2023-04-07"
          },
          {
            "paramName": "ToDate",
            "paramType": "string",
            "paramValue": "2023-04-07"
          },
          {
            "paramName": "Unitid",
            "paramType": "integer",
            "paramValue": 1
          },
          {
            "paramName": "Doctorid",
            "paramType": "integer",
            "paramValue": "608"
          }
        ]
        
      }
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: 'follow',

    }

    // fetch("http://219.91.153.48:84/reports/display", requestOptions)
    // fetch(`${apiHost}/api/billing/unit/bill/Report/MIS`, requestOptions)
    fetch(`http://10.0.2.202:8552/reports/display`, requestOptions)
      .then(response => response.blob())
      .then(result => {
        var blob = new File([result], 'myBill.pdf', { type: "application/pdf" })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        // console.log(window.URL.createObjectURL(blob), "tgtgggggggggggggg")
        setPdfUrl(window.URL.createObjectURL(blob))
        // link.download = "myBill.pdf"
        // link.click()
      })
      .catch(error => console.log('error', error))


  }

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const getReportNew=async()=>{

   

    var finalArr = []
    parameterList.map((value, index) => {
      console.log(value.controlName, "ctrillllllllllll", value)
      // var newObj = { paramName: 'FromDate', paramType: 'string', paramValue: '2023-04-06' }
      var newObj = { paramName: value.parameterName, paramType: value.paramType }
      newObj.paramValue = (value.paramValue == null || value.paramValue == undefined) ? 0 : value.paramValue;
     
      finalArr.push(newObj)
      console.log(finalArr, "finallllArrrr",JSON.stringify(finalArr))
    })

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var crData = JSON.stringify({
      "appName": "HIS",
      "appSecretKey": "f306b6eccd9d5069d2dc71afe139271a",
      // "reportDesignFilePath": "getConsultationDoctorwiseonly.rpt",
      "reportDesignFilePath": parameterList[0].action,
      "reportFormat": "PDF",
      "exportFileName": "admission",
      "reportParams": finalArr})

     console.log(localStorage.getItem("organisationId"),unitId,"uniggggh");
    var raw = JSON.stringify({
      "orgId": localStorage.getItem("organisationId"),
      "unitId": unitId == "all_units" ? 1 : unitId,
      // "crData": "{\"appName\":\"HIS\",\"appSecretKey\":\"f306b6eccd9d5069d2dc71afe139271a\",\"reportDesignFilePath\":\"getConsultationDoctorwiseonly.rpt\",\"reportFormat\":\"PDF\",\"exportFileName\":\"admission\",\"reportParams\":[{\"paramName\":\"FromDate\",\"paramType\":\"string\",\"paramValue\":\"2023-04-07\"},{\"paramName\":\"ToDate\",\"paramType\":\"string\",\"paramValue\":\"2023-04-07\"},{\"paramName\":\"Unitid\",\"paramType\":\"integer\",\"paramValue\":1},{\"paramName\":\"Doctorid\",\"paramType\":\"integer\",\"paramValue\":\"608\"}]}"
      "crData": crData
    });
    
    if (apiHost == 'http://localhost:8081' || apiHost == 'https://qaclone.his.clinivantage.co.in') {
      apiHost = 'https://axonic.his.clinivantage.co.in'
      // apiHost = 'https://smepp.clinivantage.co.in'
      // apiHost = 'https://qa.his.clinivantage.dev';
      raw = JSON.stringify({
        "orgId": localStorage.getItem("organisationId"),
        "unitId":  unitId == "all_units" ? 1 : unitId,
        "crData":"{\"appName\":\"HIS\",\"appSecretKey\":\"f306b6eccd9d5069d2dc71afe139271a\",\"reportDesignFilePath\":\"getConsultationDoctorwiseonly.rpt\",\"reportFormat\":\"PDF\",\"exportFileName\":\"admission\",\"reportParams\":[{\"paramName\":\"FromDate\",\"paramType\":\"string\",\"paramValue\":\"2023-04-07\"},{\"paramName\":\"ToDate\",\"paramType\":\"string\",\"paramValue\":\"2023-04-07\"},{\"paramName\":\"Unitid\",\"paramType\":\"integer\",\"paramValue\":1},{\"paramName\":\"Doctorid\",\"paramType\":\"integer\",\"paramValue\":\"608\"}]}"
    })
    }
   
    else {
      var subDomain = apiHost.split('//')[1].split(".")[0];
      apiHost = `https://${subDomain}.his.clinivantage.co.in`
    }

   
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(`${apiHost}/api/global/printMISReport`, requestOptions)
      .then(response => response.text())
      .then((result) => {
        
        if(JSON.parse(result).object){
            setBase64String(JSON.parse(result).object);
        var bufferArray = base64ToArrayBuffer(JSON.parse(result).object);
        var blobStore = new Blob([bufferArray], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blobStore);
          return;
        }
        var data = window.URL.createObjectURL(blobStore);
      
        setPdfUrl(data)
        // setObjUrl(data)
        }
        
      })
      .catch(error => console.log('error', error));


  }

  return (<>
    <Row >
      <Col className="gutter-row" span={6}>
        {
          fnRenderHeaderMenu()
        }
      </Col>

      <Col className="gutter-row" span={18}>
        <div style={style}>
          <Row>
            {
              selectedSubMenu !== '' &&
              <h4 style={{ marginLeft: 10, fontSize: 16 }}>
                {
                  selectedSubMenu.reportName

                }
              </h4>
            }
          </Row>
          <Row style={{ padding: '6px 0 24px 10px' }}>
            <Col span={24}>


              {
                parameterList.map((elementObj, index) => {

                  return fnRenderElements(elementObj, index)


                })
              }


              {
                parameterList.length > 0 &&
                <>
                  {/* <Button onClick={() => {
                    // setShowMIS(true)
                    fnGetMISReport()
                  }}
                    className="secondDD thirdDD rangePicker"
                    style={{
                      width: 120, marginLeft: 15, background: '#23E4A3', fontWeight: 500
                    }}
                  >
                    View</Button> */}

                  <Button onClick={() => {
                    // setShowMIS(true)
                    // getReport()
                    getReportNew()
                  }}
                    className="secondDD thirdDD rangePicker"
                    style={{
                      width: 120, marginLeft: 15, background: '#23E4A3', fontWeight: 500
                    }}
                  >
                    View</Button>

                </>

              }



            </Col>
          </Row>
              {
                console.log(pdfUrl,"pdfrennnnn")
              }
          {
            pdfUrl !== '' &&
            <Row style={{}}>
              <Col></Col>
              <Col offset={3}>
                <iframe width={"900px"} height={"900px"} src={pdfUrl} title="description"></iframe>

              </Col>
            </Row>
          }

        </div>
      </Col>

    </Row >


  </>)
}

export default MisReport