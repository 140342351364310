import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Layout, Typography, Menu, Breadcrumb, Select, DatePicker, Button, Anchor, Drawer, Collapse, Row, Col,Card
} from 'antd';
import './Maincontainer.css';
import { LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import GetApiData from '../../utils/GetApiData';
import LoadingSvg from '../../Layout/LoadingSvg';
import Dashboard from '../Dashboard/Dashboard';


import NavBar from "../../Layout/NavBar/NavBar";
// import SideBar from "../../Layout/NavBar/Sidebar";
import TopicMenu from "../../Layout/NavBar/TopicMenu";


import DashboardOverview from '../Dashboard/DashboardOverview';
import MisReport from '../Mis/MisReport';
import CollectionOfDay from '../Dashboard/New/CEODashboard/CollectionOfDay';
import CollectionComparative from '../Dashboard/New/CEODashboard/CollectionComparative';
import CEODashboard from '../Dashboard/New/CEODashboard/CEODashboard';

const { Title } = Typography;
const { Header, Content, Footer } = Layout
const { RangePicker } = DatePicker;
const { Option } = Select;
const { SubMenu } = Menu;
const { Link } = Anchor;
const { Panel } = Collapse;

const Maincontainer = ({ fnLogout, unitList, apiHost, currencyInfo }) => {





    const [showLoader, setShowLoader] = useState(false);
    const [dropDownData, setDropDownData] = useState(null);
    const [selectedSponsorType, setSelectedSponsorType] = useState(null)
    const [selectedSponsor, setSelectedSponsor] = useState(null)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedDoctors, setSelectedDoctors] = useState(null)
    const [selectedWards, setSelectedWards] = useState(null)
    const [selectedBedCategory, setSelectedBedCategory] = useState(null)
    const [fromDate, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment(new Date()).subtract(7,"days").format("YYYY-MM-DD"));
    const [unitId, setUnitId] = useState('all_units');
    const [data, setData] = useState(null)
    const [filterCase, setFilterCase] = useState({});
    const [showScreen, setshowScreen] = useState('CEOdashBoard')
    // const [showScreen, setshowScreen] = useState('dashBoard')



    useEffect(()=>{
       
        fnGetReport()
        // var fromDate1 = moment(new Date()).format("YYYY-MM-DD");
        // var toDate1 = moment(new Date()).subtract(7,"days").format("YYYY-MM-DD")
        // console.log(fromDate1, toDate1,"uyuydatettt")
    },[])






    useEffect(() => {

        getDropDownList()
    }, unitList)

    const getDropDownList = async () => {
        if (!localStorage.getItem("isLogin") || !localStorage.getItem("organisationId")) {
            fnLogout();
        }

        // await fnCall_API('all_units', periodType)
        var filterDropDownData = await GetApiData.filterDropDownListData({ apiHost });
        setDropDownData(filterDropDownData)
        setShowLoader(false)

        // console.log(apiHost, "ffffffffilllrrrrrrrrrrrr",);
    }


    const fnGetReport = async () => {
        console.log(fromDate, toDate,"fndaterrrr",showScreen);
        setShowLoader(true)
        var startDate = moment(fromDate, "YYYY-MM-DD");
        var endDate = moment(toDate, "YYYY-MM-DD");


        if(showScreen=="CEOdashBoard"){
            
            var cashCollectionOfDayRes= await GetApiData.fnCollectionOFDay({ apiHost, fromDate, toDate, unitId})
            console.log(cashCollectionOfDayRes,"ressssssssssssssssssssq");
        }
        else if(showScreen=="dashBoard"){
            var daysDiff = +endDate.diff(startDate, 'days') + 1;
            var prefromDate = moment(fromDate, "YYYY-MM-DD").subtract(daysDiff, 'days').format("YYYY-MM-DD");



        var filterCase = {
            sponsorTypeId: selectedSponsorType,
            sponsorId: selectedSponsor,
            deptId: selectedDepartment,
            doctorId: selectedDoctors,
            wardId: selectedWards,
            bedCategoryId: selectedBedCategory

        }
        setFilterCase(filterCase)
        var resPatientCount = await GetApiData.fnGetPatientCount({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase });
        var resRCResult = await GetApiData.fnRevenueCollectionData({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase });
        var resExpenses = await GetApiData.fnGetExpenses({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase });
        var resIpBedOccupancy = await GetApiData.fnIpBedOccupancy({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase });
        var resPAtientExperiance = await GetApiData.fnPatientExperiance({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase });
        // var resAegingAnalysis = await GetApiData.fnAegingAnalysis({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase });
        var result = {
            patientCountData: resPatientCount,
            revenueCollectionData: resRCResult,
            expensesData: resExpenses,
            IpBedOccupancyData: resIpBedOccupancy,
            patientExperianceData: resPAtientExperiance,
            // ageingAnalysis: resAegingAnalysis
        }
        setData(result)
        }
        
        setShowLoader(false)
    }


    return (
        <div style={{ height: '100%' }}>

            <div className="desktopView">
                < Layout className="layout" >

                    <Header style={{ backgroundColor: "#326ea0", zIndex: 1, width: '100%' }} className="headerTop">
                        {/* <NavBar menu={Menu} /> */}

                        <div className="Logo" />
                        <Button
                            type='link'
                            onClick={() => {
                                setshowScreen('CEOdashBoard')
                            }}
                            // className="secondDD thirdDD rangePicker"
                            style={{
                                width: 120, margin: '0 25px 0 25px', color: "white", fontSize: 15,
                                fontWeight: 500, borderBottom: showScreen == 'CEOdashBoard' && "3px solid"
                            }}
                        >
                           CEO Dashboard</Button>
                        <Button
                            type='link'
                            onClick={() => {
                                setshowScreen('dashBoard')
                            }}
                            // className="secondDD thirdDD rangePicker"
                            style={{
                                width: 90, margin: '0 25px 0 25px', color: "white", fontSize: 15,
                                fontWeight: 500, borderBottom: showScreen == 'dashBoard' && "3px solid"
                            }}
                        >
                            Dashboard
                        </Button>
                          
                        <Button
                            type='link'
                            onClick={() => {
                                setshowScreen("MISReport")
                            }}
                            // className="secondDD thirdDD rangePicker"
                            style={{
                                width: 90, color: "white", fontSize: 15,
                                fontWeight: 500, borderBottom: showScreen == 'MISReport' && "3px solid"
                            }}
                        >
                            MIS Report</Button>



                        <div className="logoutIcon fright">
                            <LogoutOutlined style={{ width: '50px' }} onClick={fnLogout} />
                        </div>
                        <div className="fright dropdownList">

                            <div className="parentDropdown">

                                <Select
                                    className="firstDD" size={'small'} placeholder="Select Unit" defaultValue={'All Units'}
                                    onChange={async (val, opt) => {


                                        setUnitId(val)



                                    }}
                                >
                                    <Option value={"all_units"} key={"all_units"}>All Units</Option>
                                    {unitList.map(u => {
                                       // return <Option value={u.unitid} key={u.unitid}>{u.unitname}</Option>
                                        return <Option value={u.unitid} key={u.unitid}>"Demo"</Option>
                                    })}

                                </Select>


                                {

                                    <>




                                        <RangePicker
                                            className="thirdDD"
                                            placeholder={[fromDate, toDate]}
                                            disabledDate={(current) => {
                                                return current && current > moment().endOf('day');
                                            }}
                                            onCalendarChange={(val) => {
                                                if (val) {
                                                    const [start, end] = val
                                                    // console.log(new Date(start), new Date(end), "vvvvvvvvvvvvvvvv");
                                                    setFromDate(moment(start).format("YYYY-MM-DD"))
                                                    setToDate(moment(end).format("YYYY-MM-DD"))
                                                }


                                            }} />
                                        <Button
                                            onClick={fnGetReport}
                                            className="secondDD thirdDD rangePicker"
                                            style={{
                                                width: 120, marginLeft: 15, background: '#23E4A3',
                                                fontWeight: 500
                                            }}
                                        >
                                            Get Report</Button>
                                    </>
                                }

                            </div>





                        </div>


                    </Header>
                    {/* <Row style={{ backgroundColor: '#F0F2F5' }}> */}
                       {
                         showScreen == 'CEOdashBoard'  ?
                         <>
                            <Content style={{ padding: '0 50px', margin:'25px 0' }}>
                                <div className="site-layout-content ">
                                    <CEODashboard apiHost={apiHost} fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                                    toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                         unitId={unitId} currencyInfo={currencyInfo}>
                                        
                                    </CEODashboard>
                                    
                                </div>
                            </Content>
                         </>:
                         showScreen=='dashBoard' ? <>
                            
                            <Row style={{ display :'flex', flexDirection:'column',padding:"14px 28px 5px 28px"}} >
                       <Col> <LikeOutlined style={{ color: 'green', fontSize: 18 , paddingRight:5, paddingTop:2, width:25}} /> {"Indicates current figures for the selected period are more than the figures for immediate past period "+ " "}
                       </Col>
                       <Col><DislikeOutlined style={{ color: 'red', fontSize: 18,paddingRight:5,paddingTop:2 }} />
                       
                       Indicates current figures for the selected period are less than the figures for immediate past period 
                       </Col>
                       <Col>  <LikeOutlined style={{ color: 'orange', fontSize: 18 ,paddingRight:5,  paddingTop:2}} rotate={-90} />  Indicates current figures for the selected period are same as the figures for immediate past period   
                       </Col>
                        </Row>
                            <Row style={{ backgroundColor: '#fff' }}>

                                <Col span={24}>
                                    <Collapse ghost>
                                        {/* <Panel header="This is default size panel header" key="1"> */}
                                        <Panel header="Additional Filters" key="1">
                                            <>
                                                <Select style={{ width: 170, marginRight: 24, }}
                                                    onChange={value => setSelectedSponsorType(value)}
                                                    mode="multiple"
                                                    placeholder="Select Sposor Type"
                                                >
                                                    {
                                                        dropDownData && dropDownData.sponserTypeList &&
                                                        dropDownData.sponserTypeList.map((data, index) => {
                                                            return <option value={data.payment_entitlement_id}>{data.payment_entitlement_desc}</option>
                                                        })
                                                    }

                                                </Select>
                                                <Select style={{ width: 170, marginRight: 24 }}
                                                    onChange={(value) => setSelectedSponsor(value)}
                                                    mode="multiple"
                                                    placeholder="Select Sponsor "
                                                >
                                                    {
                                                        dropDownData && dropDownData.sponsorList &&
                                                        dropDownData.sponsorList.map((data, index) => {
                                                            return <option value={data.company_id}>{data.company_desc}</option>
                                                        })
                                                    }
                                                </Select>
                                                <Select style={{ width: 170, marginRight: 24 }}
                                                    onChange={(value) => {
                                                        setSelectedDepartment(value)
                                                    }}
                                                    mode="multiple"
                                                    placeholder="Select Department "
                                                >
                                                    {
                                                        dropDownData && dropDownData.departmentList &&
                                                        dropDownData.departmentList.map((data, index) => {
                                                            return <option value={data.speciality_id}>{data.speciality_name}</option>
                                                        })
                                                    }

                                                </Select>
                                                <Select style={{ width: 170, marginRight: 24 }}
                                                    onChange={(value) => setSelectedDoctors(value)}
                                                    placeholder="Select Doctor "
                                                    mode="multiple"
                                                >
                                                    {
                                                        dropDownData && dropDownData.doctorList &&
                                                        dropDownData.doctorList.map((data, index) => {
                                                            return <option value={data.doctor_id}>{data.doctor_name}</option>
                                                        })
                                                    }
                                                </Select>
                                                <Select style={{ width: 170, marginRight: 24 }}
                                                    onChange={(value) => setSelectedWards(value)}
                                                    placeholder="Select Ward "
                                                    mode="multiple"
                                                >
                                                    {
                                                        dropDownData && dropDownData.wardList
                                                        &&
                                                        dropDownData.wardList.map((data, index) => {
                                                            return <option value={data.ward_id}>{data.ward_name}</option>
                                                        })
                                                    }
                                                </Select>
                                                <Select style={{ width: 170, marginRight: 24 }}
                                                    onChange={(value) => setSelectedBedCategory(value)}
                                                    placeholder="Select Billing Bed Category "
                                                    mode="multiple"
                                                >
                                                    {
                                                        dropDownData && dropDownData.billingBedCategoryList
                                                        &&
                                                        dropDownData.billingBedCategoryList.map((data, index) => {
                                                            return <option value={data.billing_bed_category_id}>{data.billing_bed_desc}</option>
                                                        })
                                                    }

                                                </Select>
                                            </>
                                        </Panel>
                                    </Collapse>
                                </Col>


                            </Row>

                            {
                                showLoader ? <LoadingSvg /> :
                                    <Content style={{ padding: '0 50px' }}>


                                        <div className="site-layout-content ">
                                            {
                                                // fromDate !== null && getReport ?
                                                data !== null && <DashboardOverview data={data} fromDate={moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                                    toDate={moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                                    filterCase={filterCase} apiHost={apiHost}
                                                    unitId={unitId} currencyInfo={currencyInfo}
                                                ></DashboardOverview>
                                                // : <label>No Data Found</label>
                                            }

                                        </div>


                                    </Content>

                            }
                        </> :
                        <>
                        <div className='misDiv'>
                                <Content style={{ padding: '0 5px' }}>


                                    <div className="site-layout-content ">
                                        <MisReport apiHost={apiHost} unitId={unitId}></MisReport>
                                    </div>
                                </Content>
                            </div>
                       
                        </>
                       }     
                    




                    <Footer style={{ textAlign: 'center', fontWeight: 700 }}>Powered By Cliniq AI</Footer>

                </Layout >
            </div>
        </div >
    )
}

export default Maincontainer;

const disabledDate = (current) => {
    return current && current > moment().endOf('day');
}