import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Select,
  Col,
  Button,
  Table,
  DatePicker,
  Checkbox,
  Radio,
} from "antd";
import { TableOutlined, BarChartOutlined } from "@ant-design/icons";
import "../../../../App.css";
import GetApiData from "../../../../utils/GetApiData";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;
const { RangePicker } = DatePicker;
const doctorCol = [
  {
    title: "Doctor",
    dataIndex: "docName",
    key: "docName",
  },
  {
    title: "Type",
    dataIndex: "docType",
    key: "docType"
  },
  {
    title: "Gross Revenue",
    dataIndex: "totalGross",
    key: "totalGross",
  },
  // {
  //   title: "Discount",
  //   dataIndex: "totalDiscount",
  //   key: "totalDiscount",
  // },
  // {
  //   title: "Net Revenue",
  //   dataIndex: "totalNet",
  //   key: "totalNet",
  // },
];
const serviceGroupCol = [
  {
    title: "Service Group",
    dataIndex: "serviceName",
    key: "serviceName",
  },
  {
    title: "Gross Revenue",
    dataIndex: "totalGross",
    key: "totalGross",
  },
];
const specialtyRevenueCol = [
  {
    title: "Specialty",
    dataIndex: "specialityName",
    key: "specialityName",
  },
  {
    title: "Gross Revenue",
    dataIndex: "totalGross",
    key: "totalGross",
  },
  // {
  //   title: "Discount",
  //   dataIndex: "totalDiscount",
  //   key: "totalDiscount",
  // },
  // {
  //   title: "Net Revenue",
  //   dataIndex: "totalNet",
  //   key: "totalNet",
  // },
];
const DoctorwiseRevenue = ({ apiHost, unitId, currencyInfo }) => {
  const [currentView, setCurrentView] = useState("graphView");
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, "days").format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("DD-MM-YYYY"));
  const [specialityId, setSpecialityId] = useState("");
  const [doctorWiseChart, setdoctorWiseChart] = useState({
    series: [],
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          // columnWidth: "70%",
          endingShape: "rounded",
        },
      },
      labels: ["OP", "IP", "Daycare", "ER"],
    },
  });
  const [specialltyWiseChart, setSpecialtyWiseChart] = useState({
    series: [],
    options: {
      plotOptions: {
        bar: {
          horizontal: true,
          // columnWidth: "20px",
          endingShape: "rounded",
          barHeight: "20px",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      theme: {
        mode: "light",
        palette: "palette4",
        monochrome: {
          enabled: false,
          color: "#5653FE",
          shadeTo: "light",
          shadeIntensity: 0.95,
        },
      },
      labels: ["OP", "IP", "Daycare", "ER"],
    },
  });
  const [serviceWiseRevenueChart, setserviceWiseRevenueChart] = useState({
    series: [],
    options: {
      theme: {
        mode: "light",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  });
  const [doctorWiseRevenue, setDoctorWiseRevenue] = useState([]);
  const [serviceWiseRevenue, setServiceWiseRevenue] = useState([]);
  const [specialtyWiseRevenue, setSpecialtyWiseRevenue] = useState([]);
  const [doctorListDD, setDoctorListDD] = useState([]);
  const [specialtyListDD, setSpecialtyListDD] = useState([]);
  const [serviceGroupId, setServiceGroupId] = useState("");
  const [doctorId, setDoctorId] = useState("");

  const [orderValue, setOrderValue] = useState();
  const [allData, setAllData] = useState([]);


  useEffect(() => {


    fnGetSpecialtyList();

    fetchDoctorList();

  }, []);

  useEffect(() => {
    // fetchData();
    if (specialtyListDD.length > 0) {
      fetchAllRevenue(fromDate, toDate, unitId, specialtyListDD[0].speciality_id,
        serviceGroupId, doctorId, orderValue);
    }

  }, [specialtyListDD, doctorListDD])

  const options = [
    {
      label: <strong>Top 5</strong>,
      value: "desc",
    },
    {
      label: <strong>Bottom 5</strong>,
      value: "asc",
    },
  ];

  const onChangeRadioButton = (orderValue) => {
    setOrderValue(orderValue.target.value);
    console.log("checkedBoxxx ", allData);
    const sortedArray1 = allData[1].sort((a, b) => b.totalGross - a.totalGross);
    if (orderValue.target.value == "desc") {

      var series = [
        { name: "Total Gross", data: sortedArray1.map(e => e.totalGross) }
      ];
      var categories = sortedArray1.map(e => e.docName)
      console.log(sortedArray1, "sorteaaaa");
      setdoctorWiseChart({
        series: series,
        options: {
          ...doctorWiseChart.options,
          xaxis: {
            ...doctorWiseChart.options.xaxis,
            categories: categories
          }
        }
      })
      setDoctorWiseRevenue([...sortedArray1])

    }
    else {
      var sortedArrAsc = sortedArray1.reverse();
      var seriesAsc = [
        { name: "Total Gross", data: sortedArrAsc.map(e => e.totalGross) }
      ];
      var categoriesAsc = sortedArrAsc.map(e => e.docName)
      setdoctorWiseChart({
        series: seriesAsc,
        options: {
          ...doctorWiseChart.options,
          xaxis: {
            ...doctorWiseChart.options.xaxis,
            categories: categoriesAsc
          }
        }
      })
      setDoctorWiseRevenue([...sortedArrAsc])
    }
  };

  const handleSwitchView = (name) => {
    setCurrentView(name);
  };




  const fetchAllRevenue = async (fromDate, toDate, unitId, specialityId, serviceGroupId, doctorId, orderValue) => {
    var res = await GetApiData.fnGetDoctorRevenue({
      apiHost, fromDate, toDate, unitId, specialityId,
      serviceGroupId, doctorId, orderValue,
    });


    console.log(res, "docrevenuuuuu");
    var specialityArr = [], doctorListArr = [], serviceGroupArr = [], total = 0;

    res.docRevenue.map((item, index) => {

      // speciality
      let specialityIndex = specialityArr.findIndex(
        (i) => i.specialityName == item.doc_speciality
      );

      if (specialityIndex == -1) {
        specialityArr.push({
          specialityName: item.doc_speciality,
          totalGross: parseInt(item.Amount)
        })
      } else {
        specialityArr[specialityIndex].totalGross += Math.round(+item.Amount);
      }
      // speciality end

      var docIndex = doctorListArr.findIndex(
        (i) => i.docName.toUpperCase() == item.doctor_name.toUpperCase()
      );
      if (docIndex == -1) {
        doctorListArr.push({
          docName: item.doctor_name.toUpperCase(),
          docType: item.doctor_category,
          totalGross: parseInt(item.Amount)
        })
      }
      else {
        doctorListArr[docIndex].totalGross += Math.round(+item.Amount);
      }

      console.log(doctorListArr, "docoooo");

      var serviceIndex = serviceGroupArr.findIndex(
        (i) => i.serviceName == item.service_group_name
      );

      if (serviceIndex == -1) {
        serviceGroupArr.push({
          serviceName: item.service_group_name,

          totalGross: (Math.round(+item.Amount))
        })
      }
      else {

        serviceGroupArr[serviceIndex].totalGross += Math.round(+item.Amount);
      }
      total += Math.round(+item.Amount);
    });

    specialityArr = specialityArr.sort((a, b) => b.totalGross - a.totalGross);
    serviceGroupArr = serviceGroupArr.sort((a, b) => b.totalGross - a.totalGross);
    setSpecialtyWiseChart({
      series: [
        {
          name: "Total Gross",
          data: specialityArr.map((item) => item.totalGross),
        },

      ],
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "10px",
            endingShape: "rounded",

          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
          },
        },
        xaxis: {
          categories: specialityArr.map((item) => item.specialityName),
          labels: {
            hideOverlappingLabels: true,
            trim: true,
            // maxHeight: 10,
            style: {
              colors: [],
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        theme: {
          mode: "light",
          palette: `palette${Math.floor(Math.random() * 10) + 1}`,
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        title: {
          text: "Specialty Wise",
          align: "centre",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            },
          },
        },
      },
    });
    setdoctorWiseChart({
      series: [
        {
          name: "Total Gross",
          data: doctorListArr.map((item) => item.totalGross),
        },

      ],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
            dataLabels: {
              position: "top", // top, center, bottom,
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
          formatter: function (val, opts) {
            return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
          },
        },
        xaxis: {
          categories: doctorListArr.map((item) => item.docName),
          labels: {
            hideOverlappingLabels: true,
            trim: true,
            maxHeight: 90,
            style: {
              colors: [],
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => { return parseInt(value) },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        theme: {
          mode: "light",
          palette: `palette${Math.floor(Math.random() * 10) + 1}`,
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        title: {
          text: "Doctor Wise Revenue",
          align: "centre",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            },
          },
        },
      },
    });

    setserviceWiseRevenueChart({
      series: serviceGroupArr.map((item) => item.totalGross),
      options: {
        theme: {
          mode: "light",
          palette: `palette${Math.floor(Math.random() * 10) + 1}`,
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        legend: {
          position: "bottom",
        },
        labels: serviceGroupArr.map((item) => item.serviceName),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
        title: {
          text: "Service Group Wise",
          align: "centre",
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${currencyInfo} ${val.toLocaleString("en-IN")}`;
            },
          },
        },
      },
    });

    console.log("specialityWise", doctorListArr);
    specialityArr.push({
      specialityName: "Total",
      totalGross: total
    })
    doctorListArr.push({
      docName: "Total",
      totalGross: total
    })
    serviceGroupArr.push({
      serviceName: "Total",
      totalGross: total
    })
    setAllData([
      [...specialityArr],
      [...doctorListArr],
      [...serviceGroupArr],
    ]);

    setDoctorWiseRevenue([...doctorListArr])
    setSpecialtyWiseRevenue([...specialityArr])
    setServiceWiseRevenue([...serviceGroupArr])



  };

  const fetchDoctorList = async () => {
    var res = await GetApiData.getDoctorList({ apiHost });
    var sortedData = res.docListMaster.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
    console.log("sortedData", sortedData);
    setDoctorListDD(sortedData);
  };


  const fnGetSpecialtyList = async () => {
    var res = await GetApiData.getSpecialtyList({ apiHost });

    var sortedData = res.specListMaster.sort((a, b) =>
      a.speciality_name > b.speciality_name ? 1
        : b.speciality_name > a.speciality_name ? -1 : 0
    );
    console.log("sortedData", sortedData);
    setSpecialtyListDD(sortedData);
    setSpecialityId(sortedData[0].speciality_id);
    // console.log("ssssss", res);
  };
  const handleSpecialtyChange = async (specialityId) => {
    console.log('specialityId', specialityId)
    setSpecialityId(specialityId);
    fetchAllRevenue(fromDate, toDate, unitId,
      specialityId, serviceGroupId, doctorId, orderValue);
  };

  const handleServiceGroupChange = async (serviceGroupId) => {
    setServiceGroupId(serviceGroupId);
    fetchAllRevenue(fromDate,
      toDate, unitId, specialityId, serviceGroupId, doctorId, orderValue
    );
  };
  const handleDoctorChange = async (doctorId) => {
    console.log("doctor_id", doctorId);
    setDoctorId(doctorId);
    fetchAllRevenue(fromDate, toDate, unitId,
      specialityId, serviceGroupId, doctorId, orderValue
    );
  };



  return (
    <>
      <Row align="top" justify="space-between" className="mb-2">
        <Col lg={9} md={8} sm={12} xs={24}>
          <div className="table-chart-heading">Doctor Wise Revenue</div>
        </Col>
        <Col lg={15} md={14} sm={12} xs={24}>
          <Row align="top" justify="space-between" >
            <Col lg={6} md={12} sm={12}>
              <RangePicker
                placeholder={[fromDate, toDate]}
                onChange={async (value) => {
                  setOrderValue()
                  setFromDate(moment(value[0]).format("YYYY-MM-DD"));
                  setToDate(moment(value[1]).format("YYYY-MM-DD"))
                  await fetchAllRevenue(moment(value[0]).format("YYYY-MM-DD"),
                    moment(value[1]).format("YYYY-MM-DD"),
                    unitId, specialityId, serviceGroupId, doctorId, orderValue);
                }}
              />
            </Col>
            <Col lg={5} md={12} sm={12}>
              {specialtyListDD.length > 0 && (
                <Select
                  onChange={(value) => {
                    handleSpecialtyChange(value);
                    setOrderValue()
                  }}
                  placeholder="Specialty"
                  className="w-100"
                  defaultValue={
                    specialtyListDD.length > 0
                      ? specialtyListDD[0].speciality_id
                      : undefined
                  }
                >
                  {specialtyListDD.map((u) => {
                    return (
                      <Option value={u.speciality_id} key={u.speciality_id}>
                        {u.speciality_name.charAt(0).toUpperCase() +
                          u.speciality_name.slice(1).toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col>
            <Col lg={5} md={10} sm={12}>

              {doctorListDD.length > 0 && (
                <Select
                  onChange={(value) => {
                    handleDoctorChange(value);
                    setOrderValue()
                  }}
                  placeholder="Select Doctor"
                  className="w-100"
                >
                  <Option value="">Select All</Option>
                  {doctorListDD.map((u) => {
                    return (
                      <Option value={u.doctor_id} key={u.doctor_id}>
                        {u.first_name.charAt(0).toUpperCase() +
                          u.first_name.slice(1).toLowerCase() +
                          " " +
                          u.last_name.charAt(0).toUpperCase() +
                          u.last_name.slice(1).toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col>
            <Col lg={5} md={10} sm={12}>
              {specialtyListDD.length > 0 && (
                <Select
                  onChange={(value) => {
                    handleServiceGroupChange(value);
                    setOrderValue()
                  }}
                  placeholder="Service Group"
                  className="w-100"
                >
                  {specialtyListDD.map((u) => {
                    return (
                      <Option value={u.speciality_id} key={u.speciality_id}>
                        {u.speciality_name.charAt(0).toUpperCase() +
                          u.speciality_name.slice(1).toLowerCase()}
                        {/* {u.speciality_name} */}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col>
            {/* <Col lg={4} md={10} sm={12}>
              {paymentMethodListDD.length > 0 && (
                <Select
                  onChange={(value) => {
                    handlePaymentMethod(value);
                  }}
                  placeholder="Payment Method"
                  className="w-100"
                >
                  {paymentMethodListDD.map((u) => {
                    return (
                      <Option value={u.payment_mode_id} key={u.payment_mode_id}>
                        {u.payment_mode_desc}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Col> */}
            <Col lg={2} md={4} sm={12}>
              <Row align="top" justify="space-around" gutter={[8]}>
                <Col>
                  <Button
                    className="buttonColor"
                    onClick={() => {
                      setOrderValue()
                      handleSwitchView("tableView");
                    }}
                    type="link"
                    icon={<TableOutlined />}
                  />
                  <Button
                    className="buttonColor"
                    onClick={() => {
                      setOrderValue()
                      handleSwitchView("graphView");
                    }}
                    type="link"
                    icon={<BarChartOutlined />}
                  />
                </Col>
                <Col>

                </Col>
              </Row>
            </Col>
          </Row>
        </Col >
      </Row >

      <Row align="top" justify="space-between" className="mb-2">
        <Col lg={10} md={9} sm={12} xs={24}>
          <Row align="top" justify="space-evenly" gutter={[24, 12]}>
            <Col lg={12} md={12} sm={12}>
              {
                console.log(orderValue, "ordervvv")
              }
              <Radio.Group options={options} onChange={onChangeRadioButton} value={orderValue} />
            </Col>

            <Col lg={12} md={12} sm={12}></Col>
          </Row>
        </Col>
      </Row>

      {
        currentView === "tableView" && (
          <>
            <Row align="top" justify="space-between" gutter={[16]}>
              <Col lg={8} md={12} sm={12} xs={24}>
                <Table
                  columns={specialtyRevenueCol}
                  dataSource={specialtyWiseRevenue}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  bordered
                  size="small"
                  scroll={
                    specialtyWiseRevenue.length > 5
                      ? {
                        y: 233,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                />
              </Col>
              <Col lg={8} md={12} sm={12} xs={24}>
                <Table
                  columns={doctorCol}
                  dataSource={doctorWiseRevenue}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  scroll={
                    doctorWiseRevenue.length > 5
                      ? {
                        y: 233,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                  bordered
                  size="small"
                />
              </Col>
              <Col lg={8} md={12} sm={12} xs={24}>
                <Table
                  columns={serviceGroupCol}
                  dataSource={serviceWiseRevenue}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  scroll={
                    serviceWiseRevenue.length > 5
                      ? {
                        y: 233,
                        scrollToFirstRowOnChange: true,
                      }
                      : undefined
                  }
                  bordered
                  size="small"
                />
              </Col>

            </Row>
          </>
        )
      }
      {
        currentView === "graphView" && (
          <>
            <Row align="top" justify="space-between" gutter={[16]}>
              <Col lg={7} md={12} sm={12} xs={24}>

                <ReactApexChart
                  options={specialltyWiseChart.options}
                  series={specialltyWiseChart.series}
                  type="bar"
                  height={400}
                />
              </Col>
              <Col lg={7} md={12} sm={12} xs={24}>

                <ReactApexChart
                  options={doctorWiseChart.options}
                  series={doctorWiseChart.series}
                  type="bar"
                  height={400}
                />
              </Col>
              <Col lg={7} md={12} sm={12} xs={24}>
                <ReactApexChart
                  options={serviceWiseRevenueChart.options}
                  series={serviceWiseRevenueChart.series}
                  type="pie"
                />

                <Row>
                  {serviceWiseRevenue.length > 0 && (
                    <Col
                      offset={9}
                      style={{ color: "#000", fontWeight: 500, marginTop: 15 }}
                    >
                      Total :{" "}
                      {`${currencyInfo} ${serviceWiseRevenue[
                        serviceWiseRevenue.length - 1
                      ].totalGross.toLocaleString("en-IN")}`}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
};

export default DoctorwiseRevenue;
