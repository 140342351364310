import React from 'react';
import { Form, Input, Button, Checkbox, Card, Modal, Select } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import LoadingSvg from '../../Layout/LoadingSvg';
import "./Login.css";
import GetApiData from "../../utils/GetApiData";
export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            confirmLoading: false,
            login: false,
            svgVisible: false,
            userName: '',
            password: '',
            errorMsg: '',
            selectedUnitId: '',
            isSuccess: false,
            apiHost: this.props.apiHost,
            isLogout: this.props.isLogout,
            organizationName: this.props.organizationName,
            organisationId: this.props.organizationId,
            unitList: this.props.unitList,
            defaultUnitId: null,

        }
    }
    async componentDidMount() {
        //console.log(this.props.apiHost, "apihost")

    }
    fn_Login = async () => {
        if (this.state.userName == "") {
            this.setState({
                errorMsg: "Please Enter UserName"
            })
            return;
        }
        if (this.state.password == "") {
            this.setState({
                errorMsg: "Please Enter Password"
            })
            return;
        }

        if (this.state.selectedUnitId == "") {

            this.setState({
                errorMsg: "Please Select Unit"
            })
            return;
        }

        //console.log(this.state.organisationId, "loginORggggid")
        // let response = await GetApiData.Login({

        //     organizationId: this.state.organisationId,
        //     apiHost: this.props.apiHost,
        //     unitId: this.state.selectedUnitId,
        //     userName: this.state.userName,
        //     password: this.state.password,
        //     macAddress: ""
        // })
        // if (response && response.status && response.status == "success" && response.message == 'Login Successful') {
        //     console.log(response, "iiiiiiiirrrr")
        //     localStorage.setItem("isLogin", "yes");
        //     localStorage.setItem("organisationId", this.state.organisationId)
        //     // localStorage.setItem("MISUserDetails", JSON.stringify(response.object))


        //     this.setState({
        //         isSuccess: true,
        //         svgVisible: false
        //     })
        //     this.props.fn_LoginSuccess();
        //     // this.props.changeSelectedKey(true, this.props.organizationId);


        // }
        // if (response && response.status && response.status == "error" && response.message) {
        //     // this.setState({
        //     //     errorMsg: response.message
        //     // })
        //     localStorage.setItem("isLogin", "yes");
        //     localStorage.setItem("organisationId", this.state.organisationId)
        //     // localStorage.setItem("MISUserDetails", JSON.stringify(response.object))
        //       this.setState({
        //         isSuccess: true,
        //         svgVisible: false
        //     })


        //     this.props.fn_LoginSuccess();
        // }
        setTimeout(() => {
            localStorage.setItem("isLogin", "yes");
            localStorage.setItem("organisationId", this.state.organisationId)
            // localStorage.setItem("MISUserDetails", JSON.stringify(response.object))
            this.setState({
                isSuccess: true,
                svgVisible: false
            })


            this.props.fn_LoginSuccess();
        }, 1000)
    }

    render() {
        const { Option } = Select;
        return (
            <>
                <div className="bckImg" style={{ height: window.innerHeight < 400 ? window.innerHeight + 100 : window.innerHeight }}>
                    <div className="centredClass">
                        <div class="main-heading" ><span>Hospital Information System</span></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="wrapper">

                            <Card bordered={false} style={{ width: "300px" }}>

                                <Input placeholder="User Name" suffix={<UserOutlined />}
                                    onChange={(e) => {
                                        this.setState({
                                            userName: e.target.value.trim()
                                        })

                                    }}
                                />
                                <br />
                                <br />
                                <Input placeholder="Password" type="password" suffix={<LockOutlined />}
                                    onChange={(e) => {
                                        this.setState({
                                            password: e.target.value.trim()
                                        })
                                    }}
                                />

                                <br />
                                <br />
                                <Input disabled
                                    value={this.state.organizationName}
                                />


                                <br />
                                <br />

                                {

                                    <Select size={'middle'}
                                        defaultValue={this.state.defaultUnitId}
                                        placeholder="Select unit"
                                        onChange={(val, opt) => {
                                            //console.log(JSON.stringify(opt))
                                            localStorage.setItem("unitId", val)
                                            this.setState({
                                                selectedUnitId: val
                                            })

                                        }} style={{ width: '360px' }}>
                                        {this.state.unitList.map(u => {
                                            return <Option value={u.unitid} key={u.unitid}>{u.unitname}</Option>
                                        })}
                                    </Select>


                                }
                                <br /><br />
                                <br />
                                <div className="logindiv">
                                    <Button className="Login_btn" onClick={() => {
                                        this.setState({
                                            svgVisible: true
                                        })
                                        this.fn_Login()

                                    }}>Login</Button>
                                </div>
                            </Card>
                            <Modal
                                title="Forgot Password"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                confirmLoading={this.confirmLoading}
                                onCancel={this.handleCancel}
                                width="350px"
                            >
                                <p>
                                    Email ID:
                                </p>
                                <p>
                                    <Input placeholder="Password" suffix={<MailOutlined />} />
                                </p>
                            </Modal>
                            {
                                this.state.svgVisible && this.state.errorMsg == '' &&
                                <div className="svg"><LoadingSvg /></div>

                            }
                            {
                                this.state.isLogout &&
                                <div className="svg"><LoadingSvg /></div>
                            }
                            {
                                this.state.errorMsg !== '' &&
                                <div className="ërrorDiv">
                                    <p class="errorMessage">
                                        {
                                            this.state.errorMsg
                                        }
                                    </p>
                                </div>
                            }
                        </div>,


                    </div>
                </div >
            </>
        )
    }
}