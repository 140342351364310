import axios from 'axios'
import { reject } from 'lodash';
import moment from 'moment';
import utilsFunction from './utilsFunction';
// var FileSaver = require('file-saver');
// const FileDownload = require('js-file-download');
let _organization_id = +localStorage.getItem("organisationId")
const base64ToArrayBuffer = (data) => {
  var bString = window.atob(data);
  var bLength = bString.length;
  var bytes = new Uint8Array(bLength);
  for (var i = 0; i < bLength; i++) {
    var ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const covertToPdf = (pdfString) => {
  var bufferArray = base64ToArrayBuffer(pdfString);
  var blobStore = new Blob([bufferArray], { type: "application/pdf" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobStore);
    return;
  }
  var data = window.URL.createObjectURL(blobStore);
  console.log(data, "linkkkkkkkkkkkkk");
  return data;
}
const fndownloadPDF = (pdfString, fileName) => {



  var bufferArray = base64ToArrayBuffer(pdfString);
  var blobStore = new Blob([bufferArray], { type: "application/pdf" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobStore);
    return;
  }
  var data = window.URL.createObjectURL(blobStore);
  var link = document.createElement('a');
  document.body.appendChild(link);
  link.href = data;
  link.download = `${fileName}`;
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();

}



const daily = async ({ organizationId = 1, unitId = "all_units", fromDate = "2020-07-01", toDate = "2020-07-31", apiHost }) => {
  // //console.log(unitId, "all_units", "uuuubbbbbh", unitId !== "all_units")

  let organizationId_ = localStorage.getItem("organisationId")

  let parameter = {
    "organizationId": parseInt(organizationId_),
    "fromDate": new Date(fromDate).toLocaleDateString('en-CA'),
    // "fromDate": "2021-05-07",
    "toDate": new Date(toDate).toLocaleDateString('en-CA'),
    // "toDate": "2021-05-08",
    "period": "daily",
    "entitlement": true,
    "source": true,
    "revenue": true,
    "count": true,
    "patientCount": true,
    "payments": true,
    "outstandings": true,
    "discount": true,
    "expense": true,
    "statusWiseTestCount": true
  };

  if (unitId !== "all_units") {
    parameter["unitId"] = unitId
  }
  // //console.log(parameter, "dailyparaa")

  // //console.log(organizationId_, unitId, new Date(fromDate).toLocaleDateString('en-CA'), new Date(toDate).toLocaleDateString('en-CA'), apiHost, "qqqqqqqqqqqe")
  return new Promise((resolve, reject) => {
    // return axios.post(`https://biqa.his.clinivantage.co.in/api/global/getAllCollectionList`, parameter)
    return axios.post(`${apiHost}/api/global/getAllCollectionList`, parameter)
      .then(({ data }) => {
        //console.log("ooooooooooop11daily", data)
        resolve(data)
      }).catch(error => {
        if (error.response) {
          //console.log(error.response.data, "eror");
          //console.log(error.response.status);
          //console.log(error.response.headers);
        }
      })
  })
}

const monthly = async ({ startMonth = null, endMonth = null, apiHost, organizationId, unitId }) => {
  //console.log(startMonth, endMonth, apiHost, organizationId, unitId, "inapiclllll")
  let organizationId_ = localStorage.getItem("organisationId")
  let parameter = {
    "organizationId": parseInt(organizationId_),

    "fromDate": moment(startMonth).format('YYYY-MM'),
    // "fromDate": "2021-03",
    "toDate": moment(endMonth).format('YYYY-MM'),
    // "toDate": "2021-03",
    "period": "monthly",
    "entitlement": true,
    "source": true,
    "revenue": true,
    "count": true,
    "patientCount": true,
    "payments": true,
    "outstandings": true,
    "discount": true,
    "expense": true,
    "statusWiseTestCount": true
  };

  if (unitId !== "all_units") {
    parameter["unitId"] = unitId
  }

  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getAllCollectionList`, parameter)
      // return axios.post(`https://biqa.his.clinivantage.co.in/api/global/getAllCollectionList1`, parameter)
      .then(({ data }) => {
        //console.log(data, "monthlyyyy")
        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })

}

// const Login = async ({ userName, password, organizationId, apiHost, unitId }) => {
//   let organizationId_ = localStorage.getItem("organisationId")
//   //console.log(`${apiHost}/api/userLogin`, organizationId, "lllongin")
//   return new Promise((resolve, reject) => {
//     return axios.post(`${apiHost}/api/userLogin`, {
//       "organizationId": _organization_id,
//       "userName": userName,
//       "password": password,
//       "unitId": unitId,
//       "macAddress": ""
//     })
//       .then(({ data }) => {
//         //console.log(data, "login resp")
//         resolve(data)
//       }).catch(err => {
//         //console.log(err, "eeeeeeeeeeeeeeeee")
//       })
//   })
// }

const Login = async ({ userName, password, organizationId, apiHost, unitId }) => {


  if (apiHost == 'http://localhost:8081' || apiHost == 'https://bi.his.clinivantage.co.in'
    || apiHost == 'https://biqa.his.clinivantage.co.in'
    || apiHost == 'https://qaclone.his.clinivantage.co.in'
    || apiHost == 'demo.bi.his.clinivantage.co.in'
  ) {
    // apiHost = 'https://queenspp.his.clinivantage.co.in'
    apiHost = 'https://smetwopp.his.axonichealth.com'
    // apiHost = 'https://rmhr.his.clinivantage.co.in'
  }
  // else if (apiHost == 'https://biqa.his.clinivantage.co.in') {
  //   apiHost = 'https://intdev.his.clinivantage.dev'
  // }
  else {
    const subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  // apiHost = 'https://queenspp.his.clinivantage.co.in';
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/adt/userLogin`, {
      "organizationId": organizationId,
      "userName": userName,
      "password": password,
      "unitId": unitId,
      "macAddress": ""
    })
      .then(({ data }) => {

        resolve(data)
      }).catch(err => {

      })
  })
}


const downloadDiscountReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost,
  pdfAction = "view"
}) => {
  //console.log(apiHost, FromDate, ToDate, organization_id, unit_id, "hhhhhhhhhhh")
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://bi.his.clinivantage.co.in') {
    apiHost = 'https://nch.his.clinivantage.co.in'
  }
  else if (apiHost == 'https://biqa.his.clinivantage.co.in') {
    apiHost = 'https://intdev.his.clinivantage.dev'
  } else {
    const subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/discountPatientWiseBIReport`, {
      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": organization_id,
      "unitId": unit_id
    })
      .then(({ data }) => {
        // if (pdfAction == "download") {

        //   fndownloadPDF(data.object, "discountReport")
        // }
        resolve(data.object)


      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const downloadExpenseReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost, pdfAction = "view"
}) => {
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://bi.his.clinivantage.co.in') {
    apiHost = 'https://nch.his.clinivantage.co.in'
  } else if (apiHost == 'https://biqa.his.clinivantage.co.in') {
    apiHost = 'https://intdev.his.clinivantage.dev'
  } else {
    const subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  //console.log(apiHost, FromDate, ToDate, organization_id, unit_id, "expensehhhhhhhhhhh")
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/miscellaneousExpenseBIReport`, {
      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": organization_id,
      "unitId": unit_id


    })
      .then(({ data }) => {
        // if (pdfAction == "download") {
        //   fndownloadPDF(data.object, "expenseReport")
        // }
        //console.log(data.object)

        resolve(data.object)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}



const downloadRevenueReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost,
  pdfAction = "view"
}) => {
  //console.log(apiHost, FromDate, ToDate, organization_id, unit_id, "reveniereport")
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://bi.his.clinivantage.co.in') {
    apiHost = 'https://nch.his.clinivantage.co.in'
  } else if (apiHost == 'https://biqa.his.clinivantage.co.in') {
    apiHost = 'https://intdev.his.clinivantage.dev'
  } else {
    const subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/salesRevenureportBIReport`, {

      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": organization_id,
      "unitId": unit_id

    }
    )
      .then(({ data }) => {

        //console.log(data, "ooooooj")
        // if (pdfAction == "download") {
        //   fndownloadPDF(data.object, "revenueReport")
        // }


        resolve(data.object)

      })
      .catch(err => {

        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}





const downloadOutstandingReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost,
  pdfAction = "view"
}) => {
  //console.log(apiHost, FromDate, ToDate, organization_id, unit_id, "hhhhhhhhhhhqw", `${apiHost}/api/global/getOutstandingBIReport`)
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://bi.his.clinivantage.co.in') {
    apiHost = 'https://nch.his.clinivantage.co.in'
  } else if (apiHost == 'https://biqa.his.clinivantage.co.in') {
    apiHost = 'https://intdev.his.clinivantage.dev'
  } else {
    const subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getOutstandingBIReport`, {

      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": organization_id,
      "unitId": unit_id

    })
      .then(({ data }) => {
        //console.log(data, "reportrrrrrrr")
        // if (pdfAction == "download") {
        //   fndownloadPDF(data.object, "outstandingReport")
        // }


        resolve(data.object)

      })
      .catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const getOrganisationList = async ({ subDomain, apiHost }) => {
  let _subDomain = subDomain.trim();
  //console.log(`${apiHost}/adt/organizationMasterList/${_subDomain}`, "aaaaaaaaaa")
  return new Promise((resolve, reject) => {
    return axios.get(`${apiHost}/adt/organizationMasterList/${_subDomain}`)

      .then(({ data }) => {

        resolve(data.listObject)
      }).catch(err => {

      })
  })
}
// const getUnits = async ({ organizationId = 10, apiHost }) => {
//   //console.log(`${apiHost}/adt/getUnitByOrganization`, {
//     organizationId
//   }, "unitlllll")
//   return new Promise((resolve, reject) => {
//     return axios.post(`${apiHost}/adt/getUnitByOrganization`, {
//       organizationId
//     })
//       .then(({ data }) => {
//         //console.log(data, "ddddddddddddddt")
//         let respUnitsList = data.listObject.map(u => {
//           return {
//             unitId: u.unitId,
//             unitName: u.unitName,


//           }
//         })
//         resolve(respUnitsList)


//       })
//   })
// }



const getCurrencyInfo = async ({ unitId, apiHost }) => {
  //console.log(`${apiHost}/api/global/currencies/${unitId}`, "hhhhhu")
  if (apiHost == 'http://localhost:8081') {
    apiHost = 'https://nch.his.clinivantage.co.in'
  }
  return new Promise((resolve, reject) => {

    return axios.get(`${apiHost}/api/global/currencies/${unitId}`)

      .then(({ data }) => {
        //console.log(data, "curentcyyyyy")
        resolve(data.symbol)
      }).catch(err => {
        resolve(" ")
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}



const getCurrencyDetail = async ({ unitId, apiHost }) => {
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/currencies/`, {
      "unitId": unitId
    })
      .then(({ data }) => {
        console.log(data, "currre")
        if ((data[0].symbol).toLowerCase() == 'inr') {
          resolve('₹')
        } else {
          resolve(data[0].symbol)
        }

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const getOrganizationName = async ({ apiHost }) => {
  // //console.log(apiHost, "datttttt111")
  let subDomain = apiHost.split('//')[1].split(".")[0];

  // if (subDomain.includes('localhost')) subDomain = "nmc"
  // if (subDomain.includes('localhost')) subDomain = "rmhr"
  // if (subDomain.includes('localhost')) subDomain = "qa" // when connected to his-qa
  // if (subDomain.includes('localhost')) subDomain = "queenspp" // when connected to his-pp
  if (subDomain.includes('localhost')) subDomain = "smetwopp" // when connected to smetwopp
  if (subDomain.includes('biqa')) subDomain = "queenspp" // when connected to his-pp
  if (subDomain.includes('qaclone')) subDomain = "smetwopp"
  if (subDomain.includes('demo')) subDomain = "smetwopp"

  return new Promise((resolve, reject) => {
    // return axios.get(`${apiHost}/api/getOrganisationName`)
    return axios.post(`${apiHost}/api/getOrganisationName`, {
      "subDomain": subDomain
    }

    )

      .then(({ data }) => {

        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const getUnitList = async ({ apiHost, organizationId = localStorage.getItem("organisationId") }) => {
  return new Promise((resolve, reject) => {

    return axios.post(`${apiHost}/api/getUnitList`, {
      "organisationId": organizationId
    })

      .then(({ data }) => {

        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const filterDropDownListData = async ({ apiHost }) => {
  return new Promise(async (resolve, reject) => {

    return axios.post(`${apiHost}/api/getDropDownListData`).then(({ data }) => {
      resolve(data)
    }).catch(err => {
      //console.log(err, "eeeeeeeeeeeeeeeee")
    })
  })
}

const fnGetPatientCount = ({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase = {} }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;


  // console.log(fromDateArr, toDateArr, fromQTR, toQTR, "frrrmmmmmm");

  return new Promise(async (resolve, reject) => {

    return axios.post(`${apiHost}/api/global/getPAtientCount`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "filterCase": filterCase
    })
      .then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const fnRevenueCollectionData = ({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase = {} }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;


  return new Promise(async (resolve, reject) => {

    return axios.post(`${apiHost}/api/global/CollectionAndREvenue`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "filterCase": filterCase
    })
      .then(({ data }) => {

        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const fnIpBedOccupancy = ({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase = {} }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;


  return new Promise(async (resolve, reject) => {

    return axios.post(`${apiHost}/api/global/IpBedOccupancy`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "filterCase": filterCase
    })
      .then(({ data }) => {

        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const fnGetTopDoctors = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;

  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getTopDoctorList`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    })
      .then(({ data }) => {
        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const fnGetTopDepartments = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  // console.log(fromDate, toDate, unitId, topValue, "fnvluuuuu");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getTopDepartementList`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    })
      .then(({ data }) => {
        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const fnGetTopDiagnosis = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  // console.log(fromDate, toDate, unitId, topValue, "fnvluuuuu");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getTopDiagnosisList`, {
      // "fromDate": fromDate,
      "fromDate": '2022-01-01',
      // "toDate": toDate,
      "toDate": '2023-02-28',
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    })
      .then(({ data }) => {
        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const fnGetDeathsByDiagnosis = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;

  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getDeathsByDiagnosis`, {
      // "fromDate": fromDate,
      "fromDate": '2022-01-01',
      // "toDate": toDate,
      "toDate": '2023-02-28',
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    }).then(({ data }) => {
      resolve(data)
    }).catch(err => {

    })
  })

}

const fnGetItemCategoriesByRevenue = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getItemCategoriesByRevenue`, {

      // "fromDate": fromDate,
      "fromDate": '2022-01-01',
      // "toDate": toDate,
      "toDate": '2023-02-28',
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    }).then(({ data }) => {
      resolve(data)
    }).catch(err => {

    })
  })
}

const fnGetItemByRevenue = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getItemWiseByRevenue`, {
      "fromDate": fromDate,
      // "fromDate": '2022-01-01',
      "toDate": toDate,
      // "toDate": '2023-02-28',
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    }).then(({ data }) => {
      resolve(data)
    }).catch(err => {

    })
  })
}

const fnGetTopSurgeries = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  console.log(fromDate, toDate, unitId, topValue, "fnvluuuuu");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getSurgeriesList`, {
      "fromDate": fromDate,
      // "fromDate": '2022-01-01',
      "toDate": toDate,
      // "toDate": '2023-02-28',
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    })
      .then(({ data }) => {
        resolve(data)
      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const fnNABHQualityCheck = ({ apiHost, fromDate, toDate, unitId, topValue, tabValue }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/NABHQualityCheck`, {
      "fromDate": fromDate,
      // "fromDate": '2020-01-01',
      "toDate": toDate,
      // "toDate": '2025-01-01',
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "topValue": topValue,
      "tabValue": tabValue
    }).then(({ data }) => {
      resolve(data)
    }).catch(err => {
      //console.log(err, "eeeeeeeeeeeeeeeee")
    })
  })

}


const fnPatientExperiance = ({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase = {} }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;


  return new Promise(async (resolve, reject) => {

    return axios.post(`${apiHost}/api/global/PatientExperiance`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "filterCase": filterCase
    })
      .then(({ data }) => {

        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}
const fnAegingAnalysis = ({ selectedDate, propsData }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;


  return new Promise(async (resolve, reject) => {

    return axios.post(`${propsData.apiHost}/api/global/aegingAnalysis`, {

      "selectedDate": selectedDate,
      "orgId": organisationId,
      "unitId": propsData.unitId == "all_units" ? undefined : propsData.unitId,
      "filterCase": propsData.filterCase
    })
      .then(({ data }) => {

        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}



const fnGetExpenses = ({ apiHost, fromDate, toDate, prefromDate, unitId, filterCase = {} }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;


  // console.log(fromDateArr, toDateArr, fromQTR, toQTR, "frrrmmmmmm");

  return new Promise(async (resolve, reject) => {

    return axios.post(`${apiHost}/api/global/GetExpenses`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "filterCase": filterCase
    })
      .then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const fnCollectionOFDay = ({ apiHost, fromDate, toDate, unitId, visitTypeId = '', paymentModeId = '' }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;

  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/CollectionOfDayPaymentModeWise`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "visitTypeId": visitTypeId,
      "paymentMode": paymentModeId
    })
      .then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const fnSpecialityWiseAndServicegroupWiseCollection = ({ apiHost, fromDate, toDate, unitId,
  visitTypeId = '', paymentModeId = '' }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;

  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/CollectionOfDaySpecialityWiseAndServicegroup`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "visitTypeId": visitTypeId,
      "paymentMode": paymentModeId
    })
      .then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}




const fnCollectionComparative = ({ apiHost, fromDate, toDate, prefromDate, preToDate, unitId,
  selectedVisitType = '', selectedPaymentMode = '' }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  console.log(apiHost, fromDate, toDate, prefromDate, preToDate, unitId, selectedVisitType, selectedPaymentMode, "sweeeejjjkkk");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/CollectionComparative`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "preToDate": preToDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "visitTypeId": selectedVisitType,
      "paymentMode": selectedPaymentMode
    })
      .then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const fnCollectionComparativeSpeciality_ServiceGRoupWise = ({ apiHost, fromDate, toDate, prefromDate,
  preToDate, unitId, selectedVisitType = '', selectedPaymentMode = '' }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  console.log(selectedVisitType, selectedPaymentMode, "sweeeejjjkkk");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/CollectionComparaSpecialityWiseServiceGroupwise`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "prefromDate": prefromDate,
      "preToDate": preToDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "visitTypeId": selectedVisitType,
      "paymentMode": selectedPaymentMode
    })
      .then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}






const fnGetAllRevenue = ({ apiHost, fromDate, toDate, unitId, visitTypeId = '', specialityId = "",
  paymentModeId = '', deptId = '' }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  console.log(apiHost, fromDate, toDate, unitId, visitTypeId, paymentModeId, "sweeeejjjkkk");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/RevenueClassification`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "specialityId": specialityId,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "visitTypeId": visitTypeId,
      "dept_id": deptId

    })
      .then(({ data }) => {
        console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}
const fnGetDoctorRevenue = ({ apiHost, fromDate, toDate, unitId,
  specialityId = '', serviceGroupId = '', doctorId = '', orderValue = 'desc' }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;

  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/doctorWiseRevenue`, {
      "fromDate": fromDate,
      "toDate": toDate,
      "orgId": organisationId,
      "unitId": localStorage.getItem("unitId"),
      "specialityId": specialityId,
      "serviceGroupId": serviceGroupId,
      "doctorId": doctorId,
      "orderValue": orderValue
    })
      .then(({ data }) => {
        console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const fnGetVisitListAndPaymentModeList = ({ apiHost, }) => {
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/global/VisitTypeListAndPaymentModeList`, {}
    ).then(({ data }) => {
      resolve(data)
    }).catch(err => {

    })
  })
}
const getSpecialtyList = async ({ apiHost, unitId }) => {
  console.log(unitId, "getuuunittt");
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/globat/specialtyListMaster`, {
      unitId: localStorage.getItem("unitId")
    }
    ).then(({ data }) => {
      resolve(data)
    }).catch(err => {

    })
  })
}
const getDoctorList = async ({ apiHost }) => {
  return new Promise(async (resolve, reject) => {
    return axios.post(`${apiHost}/api/globat/doctorListMaster`, {}
    ).then(({ data }) => {
      resolve(data)
    }).catch(err => {

    })
  })
}
const fnGetMISHeaderMaster = ({ apiHost }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  var misUsertDetails = JSON.parse(localStorage.getItem("MISUserDetails"))
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://qaclone.his.clinivantage.co.in' ||
    'demo.bi.his.clinivantage.co.in') {
    apiHost = 'https://axonic.his.clinivantage.co.in'

  }

  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }

  try {
    return new Promise(async (resolve, reject) => {
      return axios.post(`${apiHost}/api/global/unit/getReportGroupHeaderMaster`, {
        // "organizationId": 87,
        "organizationId": +organisationId,
        // "unitId": 116,
        // "unitId": misUsertDetails.unitId,
        "unitId": 1,
        // "userId": misUsertDetails.userId
        "userId": 1113
      }).then(({ data }) => {
        console.log(data, "eeeeeeeeeeeeeeeee")
        resolve(data)

      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
    })
  }
  catch (e) {

  }
}

const fnGetSubMenuList = ({ apiHost, selectedHeaderId }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://qaclone.his.clinivantage.co.in'

  ) {
    apiHost = 'https://axonic.his.clinivantage.co.in'
    // apiHost = 'https://smepp.clinivantage.co.in'
    // apiHost= 'https://demo.his.clinivantage.co.in'
  }
  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  try {
    return new Promise((resolve, reject) => {
      return axios.post(`${apiHost}/api/global/unit/getReportDetailsList`, {
        "organizationId": +organisationId,
        // "organizationId": 87,
        "reportGroupHeaderId": selectedHeaderId,
        // "reportGroupHeaderId": 891
      }).then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        if (data.listObject) {
          resolve(data.listObject)
        } else {
          resolve([])
        }


      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
    })
  } catch (e) {

  }
}


const fnGetPatientList = ({ apiHost, searchText }) => {
  var misUsertDetails = JSON.parse(localStorage.getItem("MISUserDetails"))
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  if (apiHost == 'http://localhost:8081') {
    // apiHost = 'https://axonic.his.clinivantage.co.in'
    apiHost = 'https://smepp.clinivantage.co.in'
  }
  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  try {
    return new Promise(async (resolve, reject) => {
      return axios.post(`${apiHost}/adt/getPatientListLike`, {
        "firstName": searchText,
        // "unitId": +misUsertDetails.unitId,
        "unitId": 1,
        "organizationId": +organisationId
      }).then(({ data }) => {
        // console.log(data, "eeeeeeeeeeeeeeeee")
        if (data.listObject) {
          resolve(data.listObject)
        } else {
          resolve([])
        }


      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
    })
  } catch (e) {

  }
}

const fnGetItemList = ({ apiHost, searchText }) => {
  var misUsertDetails = JSON.parse(localStorage.getItem("MISUserDetails"))
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  if (apiHost == 'http://localhost:8081') {
    // apiHost = 'https://axonic.his.clinivantage.co.in'
    apiHost = 'https://smepp.clinivantage.co.in'
  }
  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  try {
    return new Promise(async (resolve, reject) => {
      return axios.get(`${apiHost}/api/opd/getDrugList`, {
        params: {

          "searchDrug": searchText,
          // "unitId": +misUsertDetails.unitId,
          "unitId": 1,
          "orgId": +organisationId
        }
      }).then(({ data }) => {
        console.log(data, "itemliiiii")
        if (data.listObject) {
          resolve(data.listObject)
        } else {
          resolve([])
        }


      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
    })
  } catch (e) {

  }
}

const fnGetServiceList = ({ apiHost, searchText }) => {
  var misUsertDetails = JSON.parse(localStorage.getItem("MISUserDetails"))
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  if (apiHost == 'http://localhost:8081') {
    // apiHost = 'https://axonic.his.clinivantage.co.in'
    apiHost = 'https://smepp.clinivantage.co.in'
  }
  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  try {
    return new Promise(async (resolve, reject) => {
      return axios.post(`${apiHost}/api/packages/services/orderSet/search`, {
        "serviceDescription": searchText,
        "groupId": null,
        "subGroupId": null,
        "orgId": +organisationId,
        // "orgId": 88,
        "unitId": 1
        // "unitId": +misUsertDetails.unitId,
      }

      ).then(({ data }) => {
        console.log(data, "itemliiiii")
        if (data.listObject) {
          resolve(data.listObject)
        } else {
          resolve([])
        }


      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
    })
  } catch (e) {

  }
}


const fnGenerateRPT = ({ apiHost, raw }) => {
  // console.log(apiHost, raw, "ttttttttttttttttt");
  var apiUrl = "";
  if (apiHost == 'http://localhost:8081') {
    apiUrl = 'http://219.91.153.48:84/reports/display'
  }
  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiUrl = `https://${subDomain}.his.clinivantage.co.in/api/billing/unit/bill/Report/MIS`
  }
  // apiUrl = 'http://219.91.153.48:84/reports/display'
  try {

    return new Promise(async (resolve, reject) => {
      return axios.post(`${apiUrl}`, {
        ...raw
      })
        .then((response) => {

          var blob = new File([response], 'myBill.pdf', { type: "text/pdf" });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "myBill.pdf";
          link.click();

          // // //Create a Blob from the PDF Stream
          // const file = new Blob([response.data], { type: "application/pdf" });
          // //Build a URL from the file
          // const fileURL = URL.createObjectURL(file);
          // //Open the URL on new Window
          // const pdfWindow = window.open();
          // pdfWindow.location.href = fileURL;
        })
        .catch((error) => {
          console.log(error);
        });
    })
  } catch (e) {

  }
}

const fnGetReportDetailsByReportId = ({ apiHost, reportId }) => {
  var organisationId = localStorage.getItem("organisationId") ? localStorage.getItem("organisationId") : 1;
  var misUsertDetails = JSON.parse(localStorage.getItem("MISUserDetails"))
  if (apiHost == 'http://localhost:8081' || apiHost == 'https://qaclone.his.clinivantage.co.in') {
    apiHost = 'https://axonic.his.clinivantage.co.in'
    // apiHost = 'https://smepp.clinivantage.co.in';
    // apiHost = 'https://demo.his.clinivantage.co.in'
  }
  else {
    var subDomain = apiHost.split('//')[1].split(".")[0];
    apiHost = `https://${subDomain}.his.clinivantage.co.in`
  }
  try {
    return new Promise(async (resolve, reject) => {
      return axios.post(`${apiHost}/api/global/unit/getReportDetailsByReportId`, {
        // "organizationId": 87,
        "organizationId": +organisationId,
        // "unitId": misUsertDetails.unitId,
        "unitId": 1,
        // "unitId": 116,
        "reportId": reportId
        // "reportId": 5534
      }).then(({ data }) => {
        if (data.listObject && data.listObject.length > 0) {
          resolve(data.listObject)
        }
        else {
          resolve([])
        }
        // console.log(data, "eeeeeeeeeeeeeeeee")


      }).catch(err => {
        //console.log(err, "eeeeeeeeeeeeeeeee")
      })
    })
  }
  catch (e) {

  }
}

const getDashboardDetailsfn = async ({
  FromDate,
  ToDate,
  organisationId = localStorage.get("organisationId"),
  dateType,
  apiHost,
  unitId = "all_units"
}) => {
  // //console.log(moment(FromDate).format(`'${dateType}'`), "dateTypedateType", moment(ToDate).format(`'${dateType}'`), organisationId, unitId)
  try {

    return new Promise((resolve, reject) => {
      // return axios.get(`https://biqa.his.clinivantage.co.in/api/getDashboardDetails`, {
      return axios.get(`${apiHost}/api/getDashboardDetails`, {
        params: {

          "fromDate": moment(FromDate).format(`'${dateType}'`),
          "toDate": moment(ToDate).format(`'${dateType}'`),
          "organization_id": organisationId,
          "dateType": `'${dateType}'`,
          "unitId": unitId == "all_units" ? 0 : unitId
        }
      }
      )
        .then(({ data }) => {

          resolve(data)

        })
        .catch(error => {
          // resolve(error.response.status)
          if (error.response) {
            // //console.log(error.response.data, "eror");
            //console.log(error.response.status);
            // //console.log(error.response.headers);
          }
        })
    })
  } catch (e) {
    //console.log(e, "eeeeeeeeeeeexpress")
  }
}




export default {
  daily, monthly,
  // getUnits, 
  Login, getOrganisationList, downloadDiscountReport, downloadExpenseReport
  , downloadRevenueReport, downloadOutstandingReport, getCurrencyInfo, fndownloadPDF,
  getDashboardDetailsfn, getOrganizationName, getUnitList, getCurrencyDetail,
  filterDropDownListData,
  fnGetPatientCount,
  fnGetExpenses,
  fnRevenueCollectionData,
  fnIpBedOccupancy,
  fnPatientExperiance,
  fnAegingAnalysis,
  fnGetTopDoctors,
  fnGetTopSurgeries,
  fnGetTopDepartments,
  fnGetTopDiagnosis,
  fnGetDeathsByDiagnosis,
  fnGetItemCategoriesByRevenue,
  fnGetItemByRevenue,
  fnNABHQualityCheck,
  fnGetMISHeaderMaster,
  fnGetReportDetailsByReportId,
  fnGetSubMenuList,
  fnGetPatientList,
  fnGetItemList,
  fnGenerateRPT,
  fnGetServiceList,
  fnCollectionOFDay,
  fnSpecialityWiseAndServicegroupWiseCollection,
  fnCollectionComparativeSpeciality_ServiceGRoupWise,
  fnGetVisitListAndPaymentModeList,
  fnCollectionComparative,
  fnGetAllRevenue,
  getSpecialtyList,
  fnGetDoctorRevenue,
  getDoctorList,
}