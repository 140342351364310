import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Select, Space, Col, Button, Table, Modal, Collapse, Divider, Tabs } from 'antd';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import '../../../App.css'
import utilsFunction from '../../../utils/utilsFunction';
import GetApiData from '../../../utils/GetApiData';
import LoadingSvg from '../../../Layout/LoadingSvg';
const { Panel } = Collapse;
const { TabPane } = Tabs;
var _ = require('lodash');
class TopTwenty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series1: [],

      options1: {},
      // for pie chart
      series2: [],
      options2: {},
      series3: [44, 55, 13, 43, 22, 20],
      options3: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['OP', 'IP', 'Emergency', 'Daycare', 'Pharmacy(Op/Walkin)', 'Misc/other/scrap'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      isChart: false,
      isGross: true,
      dataSource: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Count',
          dataIndex: 'count',
          key: 'count',
        },

      ],
      viewModal: false,
      grossData: [],
      drillData: [],


      items: [
        {
          key: '0',
          // label: `Doctors By Patient Count`,
          label: <div>
            <p>Doctors
              {/* By  */}
            </p>
            {/* <p>Count</p> */}
          </div>,
          children: `Content of Tab Pane 1`,
        },
        // {
        //   key: '1',
        //   // label: `Doctors By Patient Revenue`,
        //   label: <div>
        //     <p>Doctors By </p>
        //     <p>Patient Revenue</p>
        //   </div>,
        //   children: `Content of Tab Pane 2`,
        // },
        {
          key: '1',
          // label: `Department By Patient Count`,
          label: <div>
            <p>Departments
              {/* By  */}
            </p>
            {/* <p> Count</p> */}
          </div>,
          children: `Content of Tab Pane 2`,
        },
        // {
        //   key: '3',
        //   // label: `Department By Patient Revenue`,
        //   label: <div>
        //     <p>Department List
        //       {/* By  */}
        //     </p>
        //     {/* <p>Patient Revenue</p> */}
        //   </div>,
        //   children: `Content of Tab Pane 4`,
        // },
        {
          key: '2',
          // label: `Diagnosis By Patient Count`,
          label: <div>
            <p>Diagnosis
              {/* By */}
            </p>
            {/* <p>Count</p> */}
          </div>,
          children: `Content of Tab Pane 5`,
        },
        // {
        //   key: '5',
        //   // label: `Deaths By Diagnosis`,
        //   label: <div>
        //     <p>Deaths By</p>
        //     <p>Diagnosis</p>
        //   </div>,
        //   children: `Content of Tab Pane 6`,
        // },
        {
          key: '3',
          // label: `Item Categories By Revenue`,
          label: <div>
            <p>Items </p>
            {/* <p>Item Categories </p>
            <p>By Revenue</p> */}
          </div>,
          children: `Content of Tab Pane 7`,
        },

        // {
        //   key: '7',
        //   // label: `Item By Revenue`,
        //   label: <div>
        //     <p>Item By </p>
        //     <p>Revenue</p>
        //   </div>,
        //   children: `Content of Tab Pane 8`,
        // },
        {
          key: '4',
          // label: `Surgeries By Patient Count`,
          label: <div>
            <p>Surgeries
              {/* By */}
            </p>
            {/* <p>Count</p> */}
          </div>,
          children: `Content of Tab Pane 9`,
        },
        // {
        //   key: '9',
        //   // label: `Surgeries By Revenue`,
        //   label: <div>
        //     <p>Surgeries By </p>
        //     <p>Revenue</p>
        //   </div>,
        //   children: `Content of Tab Pane 10`,
        // },
        {
          key: '5',
          // label: `Surgeries By Revenue`,
          label: <div>
            <p>NABH Quality Indicators</p>

          </div>,
          children: `NABH Quality`,
        },
      ],
      topValue: 5,
      activeTabKey: '0',

    }


  }

  componentDidMount() {
    this.setState({
      showLoader: true
    })
    this.fnCalculateTopDoctors(this.props.apiHost, this.props.fromDate, this.props.toDate,
      this.props.unitId, this.state.topValue, this.state.activeTabKey)
    console.log(this.props, "proppppppppppppp");
  }



  fnCalculateTopDoctors = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {

    this.setState({
      showLoader: true
    })
    var itemCopy = this.state.items;
    itemCopy[0].children = <LoadingSvg ></LoadingSvg>
    // itemCopy[1].children = <LoadingSvg ></LoadingSvg>
    var resTop = await GetApiData.fnGetTopDoctors({ apiHost, fromDate, toDate, unitId, topValue, tabValue });
    var updatedCol = this.state.columns;
    updatedCol[1]={
      ...updatedCol[1],
      render:(amount)=>(
        <>
          {
             amount
          }
        </>
      )
    }
    console.log(updatedCol,"pploccl");
    var updatedCol2 = [{
      title: 'By Patient Revenue',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Revenue',
      dataIndex: 'count',
      key: 'count',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
    },
    ];
    updatedCol[0].title = "By Patient Count ";
    updatedCol[1].title = "Count";
    // updatedCol2[0].title = "Doctor List By Patient Revenue ";
    // updatedCol2[1].title = "Revenue";

    var dataSource = [], dataSource2 = [];

    if (Object.keys(resTop).length !== 0) {
      // if (tabValue == 0) {
      resTop.doctorsByPatientCount && resTop.doctorsByPatientCount.length > 0 &&
        resTop.doctorsByPatientCount.map((docData, index) => {
          var docObj = {
            key: index,
            name: docData.doctor_name,
            count: (+docData.doctor_patient_count).toLocaleString('en-IN')
          }
          dataSource.push(docObj)

        })


      resTop.doctorsByPatientRevenue && resTop.doctorsByPatientRevenue.length > 0 &&
        resTop.doctorsByPatientRevenue.map((docData, index) => {
          var docObj = {
            key: index,
            name: docData.doctor_name,
            count: (+docData.doctor_patient_revenue).toLocaleString('en-IN')
          }
          dataSource2.push(docObj)

        })

      itemCopy[0].children = this.fnReturnTable(dataSource, updatedCol, dataSource2, updatedCol2)

      // } else {
      // updatedCol[1].title = "Revenue";
      // resTop.doctorsByPatientRevenue && resTop.doctorsByPatientRevenue.length > 0 &&
      //   resTop.doctorsByPatientRevenue.map((docData, index) => {
      //     var docObj = {
      //       key: index,
      //       name: docData.doctor_name,
      //       count: docData.doctor_patient_revenue
      //     }
      //     dataSource.push(docObj)

      //   })


      // itemCopy[1].children = this.fnReturnTable(dataSource, updatedCol)

      // }

    }



    this.setState({
      items: itemCopy,
      showLoader: false,
      columns: updatedCol,

    })
  }

  fnCalculateTopDepartments = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    console.log(tabValue, "depppptt", topValue);
    this.setState({
      showLoader: true
    })
    var itemCopy = this.state.items;
    itemCopy[1].children = <LoadingSvg ></LoadingSvg>
    var dataSource = [], dataSource2 = [];
    var resTop = await GetApiData.fnGetTopDepartments({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });
    var updatedCol = this.state.columns;
     updatedCol[1]={
      ...updatedCol[1],
      render:(amount)=>(
        <>
          {
             amount
          }
        </>
      )
    }
    updatedCol[0].title = "By Patient Count";
    updatedCol[1].title = "Count";
    var updatedCol2 = [{
      title: 'By Patient Revenue',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Revenue',
      dataIndex: 'count',
      key: 'count',
      render:(amount)=>(
        <>
          {
            this.props.currencyInfo+ " "+ amount
          }
        </>
      )
    },
    ];


    if (Object.keys(resTop).length !== 0) {

      // if (tabValue == 2) {
      resTop.departmentsByPatientCount &&
        resTop.departmentsByPatientCount.length > 0 &&
        resTop.departmentsByPatientCount.map((deptData, index) => {
          var docObj = {
            key: index,
            name: deptData.department_name,
            count: (+deptData.department_patient_count).toLocaleString('en-IN')
          }
          dataSource.push(docObj)
        })


      resTop.departmentsByPatientRevenue &&
        resTop.departmentsByPatientRevenue.length > 0 &&
        resTop.departmentsByPatientRevenue.map((deptData, index) => {
          var docObj = {
            key: index,
            name: deptData.department_name,
            count: (+deptData.department_patient_revenue).toLocaleString('en-IN')
          }
          dataSource2.push(docObj)
        })

      itemCopy[1].children = this.fnReturnTable(dataSource, updatedCol, dataSource2, updatedCol2)


      // } else {
      //   updatedCol[1].title = "Revenue";
      //   resTop.departmentsByPatientRevenue &&
      //     resTop.departmentsByPatientRevenue.length > 0 &&
      //     resTop.departmentsByPatientRevenue.map((deptData, index) => {
      //       var docObj = {
      //         key: index,
      //         name: deptData.department_name,
      //         count: deptData.department_patient_revenue
      //       }
      //       dataSource.push(docObj)
      //     })

      //   console.log("deptteresss", this.state.columns);
      //   itemCopy[3].children = this.fnReturnTable(dataSource, updatedCol)
      // }

    }
    this.setState({
      showLoader: false,
      items: itemCopy,
      columns: updatedCol,

    })
  }

  fnCalculateTopSurgeries = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    this.setState({
      showLoader: true
    })
    var itemCopy = this.state.items;

    itemCopy[4].children = <LoadingSvg ></LoadingSvg>
    var dataSource = [], dataSource2 = [];
    var resTop = await GetApiData.fnGetTopSurgeries({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });

    var updatedCol = this.state.columns;
    updatedCol[1]={
      ...updatedCol[1],
      render:(amount)=>(
        <>
          {
             amount
          }
        </>
      )
    }
    updatedCol[0].title = "By Patient Count";
    updatedCol[1].title = "Count";
    var updatedCol2 = [
      {
        title: 'By Patient Revenue',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Revenue',
        dataIndex: 'count',
        key: 'count',
        render:(amount)=>(
          <>
            {
              this.props.currencyInfo+ " "+ amount
            }
          </>
        )
      }
    ]



    if (Object.keys(resTop).length !== 0) {
      // if (tabValue == 8) {
      resTop.surgeriesByCount && resTop.surgeriesByCount.length > 0 &&
        resTop.surgeriesByCount.map((deptData, index) => {
          var docObj = {
            key: index,
            name: deptData.surgery_name,
            count: (+deptData.count).toLocaleString('en-IN')
          }
          dataSource.push(docObj)
        })


      resTop.surgeriesByRevenue && resTop.surgeriesByRevenue.length > 0 &&
        resTop.surgeriesByRevenue.map((deptData, index) => {
          var docObj = {
            key: index,
            name: deptData.surgery_name,
            count: (+deptData.surgery_revenue).toLocaleString('en-IN')
          }
          dataSource2.push(docObj)
        })

      console.log("deptteresss", updatedCol);
      itemCopy[4].children = this.fnReturnTable(dataSource, updatedCol, dataSource2, updatedCol2)
      // }
      // else {
      //   updatedCol[1].title = "Surgery Revenue"
      //   resTop.surgeriesByRevenue && resTop.surgeriesByRevenue.length > 0 &&
      //     resTop.surgeriesByRevenue.map((deptData, index) => {
      //       var docObj = {
      //         key: index,
      //         name: deptData.surgery_name,
      //         count: deptData.surgery_revenue
      //       }
      //       dataSource.push(docObj)
      //     })

      //   // console.log("deptteresss", this.state.columns);
      //   itemCopy[9].children = this.fnReturnTable(dataSource, updatedCol)
      // }
    }
    this.setState({
      items: itemCopy,
      columns: updatedCol,
      showLoader: false
    })
  }

  fnCalculateNABHQualityCheck = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    this.setState({
      showLoader: true
    })
    var itemCopy = this.state.items;
    itemCopy[5].children = <LoadingSvg ></LoadingSvg>
    var dataSource = []
    var resTop = await GetApiData.fnNABHQualityCheck({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });
    var updatedCol = this.state.columns;
    updatedCol[0].title = "Quality Indicator";
    updatedCol[1].title = "Value";


    if (Object.keys(resTop).length !== 0) {

      resTop._NABH && resTop._NABH.length > 0 &&
        resTop._NABH.map((deptData, index) => {
          var docObj = {
            key: index,
            name: deptData.incident_report_desc,
            count: (+deptData.value).toLocaleString('en-IN')
          }
          dataSource.push(docObj)
        })

      // console.log("deptteresss", this.state.columns);
      itemCopy[5].children = this.fnReturnTable(dataSource, updatedCol, [], [], tabValue)


    }
    this.setState({
      items: itemCopy,
      columns: updatedCol,
      showLoader: false
    })

  }

  fnCAlculateTopDiagnosis = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    console.log("diagnossi");
    this.setState({
      showLoader: true
    })
    var itemCopy = this.state.items;
    itemCopy[2].children = <LoadingSvg ></LoadingSvg>
    var dataSource = [], dataSource2 = [];

    var resTop = await GetApiData.fnGetTopDiagnosis({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });


    var updatedCol = this.state.columns;
    updatedCol[1]={
		  ...updatedCol[1],
		  render:(amount)=>(
			<>
			  {
				 amount
			  }
			</>
		  )
		} 
    updatedCol[0].title = "By Patient Count";
    updatedCol[1].title = "Count";
    var updatedCol2 = [{
      title: 'Deaths By Diagnosis',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Death Count',
      dataIndex: 'count',
      key: 'count'
    }]






    if (Object.keys(resTop).length !== 0) {

      resTop.diagnosisByCount && resTop.diagnosisByCount.length > 0 &&
        resTop.diagnosisByCount.map((deptData, index) => {
          var docObj = {
            key: index,
            name: deptData.diagnosis_name,
            count: (+deptData.patient_count).toLocaleString('en-IN')
          }
          dataSource.push(docObj)
        })


      resTop.deathByDiagnosis && resTop.deathByDiagnosis.length > 0 &&
        resTop.deathByDiagnosis.map((deptData, index) => {
          // console.log(docObj, "objjjjjjjjjjjjj");
          var docObj = {
            key: index,
            name: deptData.diagnosis_name,
            count: (+deptData.death_count).toLocaleString('en-IN')
          }

          dataSource2.push(docObj)
        })

      itemCopy[2].children = this.fnReturnTable(dataSource, updatedCol, dataSource2, updatedCol2)



    }
    this.setState({
      items: itemCopy,
      columns: updatedCol,
      showLoader: false
    })
  }

  fnCalculateDeathsByDiagnosis = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    this.setState({
      showLoader: true
    })

    var dataSource = [];

    var resTop = await GetApiData.fnGetDeathsByDiagnosis({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });


    var updatedCol = this.state.columns;
    updatedCol[0].title = "Diagnosis Name";
    updatedCol[1].title = "Death Count";
    var itemCopy = this.state.items;

    if (Object.keys(resTop).length !== 0) {
      if (tabValue == 5) {
        resTop.deathByDiagnosis && resTop.deathByDiagnosis.length > 0 &&
          resTop.deathByDiagnosis.map((deptData, index) => {
            // console.log(docObj, "objjjjjjjjjjjjj");
            var docObj = {
              key: index,
              name: deptData.diagnosis_name,
              count: (+deptData.death_count).toLocaleString('en-IN')
            }

            dataSource.push(docObj)
          })

        itemCopy[5].children = this.fnReturnTable(dataSource, updatedCol)
        // console.log(itemCopy, "dataSourcessss", dataSource, resTop.deathByDiagnosis.length);

      }

    }
    this.setState({
      items: itemCopy,
      columns: updatedCol,
      showLoader: false
    })

    // console.log(resTop, "toppppppppppppdiadddd");
  }
  fnCalculateItemCategoriesByRevenue = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    this.setState({
      showLoader: true
    })
    var itemCopy = this.state.items;
    itemCopy[3].children = <LoadingSvg ></LoadingSvg>
    var dataSource = [], dataSource2 = [];
    var resTop = await GetApiData.fnGetItemCategoriesByRevenue({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });
    var updatedCol = this.state.columns;
    updatedCol[0].title = "By Category";
    updatedCol[1].title = "Revenue";
    console.log(updatedCol, "topppqqpppppppppdiadddd");
    updatedCol[1]={
      ...updatedCol[1],
      render:(amount)=>(
        <>
          {
            this.props.currencyInfo+ " "+ amount
          }
        </>
      )
    }
    var updatedCol2 = [
      {
        title: 'By Item',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Revenue',
        dataIndex: 'count',
        key: 'count',
        render:(amount)=>(
          <>
            {
              this.props.currencyInfo+ " "+ amount
            }
          </>
        )
      }
    ]



    if (Object.keys(resTop).length !== 0) {

      resTop.itemCategoriesByRevenue && resTop.itemCategoriesByRevenue.length > 0 &&
        resTop.itemCategoriesByRevenue.map((deptData, index) => {
          // console.log(docObj, "objjjjjjjjjjjjj");
          var docObj = {
            key: index,
            name: deptData.category,
            count: deptData.item_category_revenue.toLocaleString('en-IN')
          }

          dataSource.push(docObj)
        })

      resTop.itemByRevenue && resTop.itemByRevenue.length > 0 &&
        resTop.itemByRevenue.map((deptData, index) => {
          // console.log(docObj, "objjjjjjjjjjjjj");
          var docObj = {
            key: index,
            name: deptData.item_name,
            count: deptData.item_wise_revenue.toLocaleString('en-IN')
          }

          dataSource2.push(docObj)
        })
      itemCopy[3].children = this.fnReturnTable(dataSource, updatedCol, dataSource2, updatedCol2)
      // console.log(itemCopy, "dataSourcessss", dataSource, resTop.deathByDiagnosis.length);



    }
    this.setState({
      items: itemCopy,
      columns: updatedCol,
      showLoader: false
    })
  }

  fnCalculateItemByRevenue = async (apiHost, fromDate, toDate, unitId, topValue, tabValue) => {
    this.setState({
      showLoader: true
    })
    var dataSource = [];
    var resTop = await GetApiData.fnGetItemByRevenue({
      apiHost, fromDate, toDate, unitId, topValue, tabValue
    });
    console.log(resTop, "oooopppp");
    var updatedCol = this.state.columns;
    updatedCol[0].title = "Item  Name";
    updatedCol[1].title = "Revenue";
    var itemCopy = this.state.items;

    if (Object.keys(resTop).length !== 0) {

      resTop.itemByRevenue && resTop.itemByRevenue.length > 0 &&
        resTop.itemByRevenue.map((deptData, index) => {
          // console.log(docObj, "objjjjjjjjjjjjj");
          var docObj = {
            key: index,
            name: deptData.item_name,
            count: deptData.item_wise_revenue.toLocaleString('en-IN')
          }

          dataSource.push(docObj)
        })

      itemCopy[7].children = this.fnReturnTable(dataSource, updatedCol)
      // console.log(itemCopy, "dataSourcessss", dataSource, resTop.deathByDiagnosis.length);



    }
    this.setState({
      items: itemCopy,
      columns: updatedCol,
      showLoader: false
    })
  }


  fnReturnTable = (dataSource, columns, dataSource2 = [], columns2 = [], tabValue) => {

    console.log(dataSource, "dfdfff", columns, tabValue);
    const scroll = {
      y: 197, // set the maximum height of the table to 240px
      scrollToFirstRowOnChange: true, // enable scrolling to the first row when the page changes
    };
    return <>
      <Row gutter={[16,0]} >
        <Col span={tabValue !== '5'? 12 : 24} >
          <Table bordered scroll={dataSource.length > 5 ? scroll : undefined} pagination={false}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            dataSource={dataSource} columns={columns} size='small' />

        </Col>
        {
           tabValue !== '5' && <Col span={12} >
           
             <Table bordered scroll={dataSource2.length > 5 ? scroll : undefined} pagination={false}
               rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
               dataSource={dataSource2} columns={columns2} size='small' />
           
 
         </Col>
        }
        
      </Row>


    </>
  }


  onChange = async (key) => {
    console.log(this.state.topValue, "kkkkkkkkke", key);

    this.setState({
      activeTabKey: key
    })
    switch (key) {
      case "0":
        // console.log("Hello Amruta");
        await this.fnCalculateTopDoctors(this.props.apiHost, this.props.fromDate, this.props.toDate,
          this.props.unitId, this.state.topValue, key);
        break;
      // case "1":
      //   await this.fnCalculateTopDoctors(this.props.apiHost, this.props.fromDate, this.props.toDate,
      //     this.props.unitId, this.state.topValue, key);
      //   break;

      case "1":

        await this.fnCalculateTopDepartments(this.props.apiHost, this.props.fromDate, this.props.toDate,
          this.props.unitId, this.state.topValue, key);
        break;
      // case "3":
      //   await this.fnCalculateTopDepartments(this.props.apiHost, this.props.fromDate, this.props.toDate,
      //     this.props.unitId, this.state.topValue, key);
      //   break;

      case "2":
        await this.fnCAlculateTopDiagnosis(this.props.apiHost, this.props.fromDate, this.props.toDate,
          this.props.unitId, this.state.topValue, key);
        break;

      // case "3":
      //   await this.fnCalculateDeathsByDiagnosis(this.props.apiHost, this.props.fromDate, this.props.toDate,
      //     this.props.unitId, this.state.topValue, key);
      //   break;

      case "3":
        await this.fnCalculateItemCategoriesByRevenue(this.props.apiHost, this.props.fromDate, this.props.toDate,
          this.props.unitId, this.state.topValue, key);
        break;

      // case "7":
      //   await this.fnCalculateItemByRevenue(this.props.apiHost, this.props.fromDate, this.props.toDate,
      //     this.props.unitId, this.state.topValue, key);
      //   break;

      case "4":
        await this.fnCalculateTopSurgeries(this.props.apiHost, this.props.fromDate, this.props.toDate,
          this.props.unitId, this.state.topValue, key);
        break;

      // case "9":
      //   await this.fnCalculateTopSurgeries(this.props.apiHost, this.props.fromDate, this.props.toDate,
      //     this.props.unitId, this.state.topValue, key);
      //   break;

      case "5":
        await this.fnCalculateNABHQualityCheck(this.props.apiHost, this.props.fromDate, this.props.toDate,
          this.props.unitId, this.state.topValue, key);
        break;
    }
  };


  render() {
    return (


      <div id="chart" style={{ minHeight:100 }}>

        <Row style={{ marginBottom: 10 }}>
          <Col span={this.state.isChart ? 10 : 3} style={{ fontWeight: 'bold', fontSize: 15 }}>
            Top <Select
              defaultValue="5"
              style={{
                width: 66,
              }}
              onChange={(e) => {
                this.setState({
                  topValue: e
                })

              }}

            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select> Elements
          </Col>
          <Col>

            <Button
              onClick={() => {
                this.setState({
                  showLoader: true,
                  // activeTabKey: '0'
                })
                this.onChange(this.state.activeTabKey)
                // var apiHost = this.props.apiHost;
                // this.fnCalculateTopDoctors(this.props.apiHost, this.props.fromDate, this.props.toDate,
                //   this.props.unitId, this.state.topValue, )
                // this.fnCalculateTopvalue(this.state.topValue)

              }}
              className="secondDD thirdDD rangePicker"
              style={{
                width: 120, marginLeft: 15, background: '#23E4A3',
                fontWeight: 500
              }}
            >
              Get Report</Button>
          </Col>
          {
            // this.state.isChart ?
            //   <>
            //     <Col span={8} style={{ fontWeight: 'bold', fontSize: 15 }}>Revenue Break Down</Col>
            //     <Col span={3} style={{ fontWeight: 'bold', fontSize: 15 }}>Collection Break Down</Col>
            //   </> :
            //   <>
            //     <Col span={1} >
            //       <Button className='buttonColor buttonColor2' type="link"

            //         onClick={() => {
            //           this.setState({
            //             isGross: true
            //           }, () => {
            //             this.fnCalculateGrossTable(this.state.grossData)
            //           })
            //         }}
            //       >Gross</Button>
            //     </Col>
            //     <Col span={2} >
            //       <Button className='buttonColor buttonColor2' type="link"

            //         onClick={() => {
            //           this.setState({
            //             isGross: false
            //           }, () => {
            //             this.fnCalculateDrillDownTable()
            //           })
            //         }}
            //       > Drill Down </Button>

            //     </Col>
            //   </>

          }


          {/* <Col
            // offset={this.state.isChart ? 0 : 12} 
            style={{ marginLeft: this.state.isChart ? "5.7%" : "55.70%" }}>

            <Button className='buttonColor' type="link" icon={<SearchOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>

          </Col> */}
          {/* <Col
            // offset={this.state.isChart ? 1 : 17}
            style={{ marginLeft: this.state.isChart ? '8%' : '48.1%' }}
          >
            <Button className='buttonColor' type="link" icon={<TableOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>
          </Col>
          <Col  >
            <Button className='buttonColor' type="link"
              icon={<BarChartOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: true
                })
              }}
            ></Button>

          </Col> */}
        </Row>
        <Row>

          <Col span={24}>
            {

              <Tabs activeKey={this.state.activeTabKey} items={this.state.items} onChange={this.onChange} />

            }

          </Col>
        </Row>
        <Row>
          {
            // this.state.isChart ?
            //   <>
            //     <Col span={8}><Chart options={this.state.options1} series={this.state.series1} type="line" height={350} /></Col>
            //     <Col span={8} ><Chart options={this.state.options2} series={this.state.series2} type="pie" height={280} /></Col>
            //     <Col span={8}><Chart options={this.state.options2} series={this.state.series3} type="pie" height={270} /></Col>

            //   </>
            //   : <>
            //     <Col span={24}>
            //       <Table bordered
            //         rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            //         dataSource={this.state.dataSource} columns={this.state.columns} size='small' />
            //     </Col>

            //   </>
          }
        </Row>


      </div>


    );
  }
}


export default TopTwenty;