import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Select, Space, Col, Button, Table, Modal, Collapse, Divider } from 'antd';
import { TableOutlined, BarChartOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';

import '../../../App.css'
import utilsFunction from '../../../utils/utilsFunction';
const { Panel } = Collapse;
var _ = require('lodash');
class RevenueAndCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series1: [],

      options1: {},
      // for pie chart
      series2: [],
      options2: {},
      series3: [44, 55, 13, 43, 22, 20],
      options3: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['OP', 'IP', 'Emergency', 'Daycare', 'Pharmacy(Op/Walkin)', 'Misc/other/scrap'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      isChart: false,
      isGross: true,
      dataSource: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Revenue',
          dataIndex: 'revenue',
          key: 'revenue',
          render:(amount)=>(
            <>
              {
                this.props.currencyInfo+ " "+ amount
              }
            </>
          )
        },
        {
          title: '',
          dataIndex: 'revenueResult',
          key: 'revenueResult',
        },
        {
          title: 'Collection',
          dataIndex: 'collection',
          key: 'collection',
           render:(amount)=>(
              <>
                {
                  this.props.currencyInfo+" "+amount
                }
              </>
            )
        },
        {
          title: '',
          dataIndex: 'collectionResult',
          key: 'collectionResult',
           
        },

      ],
      viewModal: false,
      grossData: [],
      drillData: []
    };
  }

  componentDidMount() {
    // console.log(this.props, "rcdataaaaa");
    this.setState({
      grossData: this.props.grossData,
      drillData: this.props.drillData
    })
    this.fnCalculateGrossTable(this.props.grossData);
    this.fnCalculateGraph(this.props.grossData);
    this.fnCalculateGraphForRevenueBreakDown(this.props.grossData);
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      this.setState({
        grossData: this.props.grossData,
        drillData: this.props.drillData
      })
      this.fnCalculateGrossTable(this.props.grossData)
      this.fnCalculateGraph(this.props.grossData);
      this.fnCalculateGraphForRevenueBreakDown(this.props.grossData)
    }
  }


  fnCalculateGrossTable = (dataArr) => {
    var dataSource = [];
    var currentData = [], previousData = []
    // console.log(dataArr, "ddddacallll");
    dataArr.map((d, index) => {
      if (d.tag == 'Current') {
        currentData.push(d)
      } else {
        previousData.push(d)
      }

    })

    // console.log(previousData, currentData, "hijjuyuyuyuyu");


    currentData.length > 0 && currentData.map((data, index) => {
      var matchData = [];
      previousData.length > 0 && previousData.map((old, index) => {

        if (data.category == old.category) {

          matchData.push(old)
        }
      })


      var result = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />
      var collectionResult = result;
      if (matchData.length > 0 && +data.revenue_amount < matchData[0].revenue_amount) {
        result = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
      }
      else if (matchData.length > 0 && +data.revenue_amount == matchData[0].revenue_amount) {
        result = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
      }
      if (matchData.length > 0 && +data.collection_amount < matchData[0].collection_amount) {
        collectionResult = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
      }
      else if (matchData.length > 0 && +data.collection_amount == matchData[0].collection_amount) {
        collectionResult = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
      }


      var dataObj = {
        key: index,
        name: data.category,
        revenue: (+data.revenue_amount).toLocaleString('en-IN'),
        collection: (+data.collection_amount).toLocaleString('en-IN'),
        revenueResult: result,
        collectionResult: collectionResult
      }
      dataSource.push(dataObj)
    })

    this.setState({
      dataSource: dataSource
    })
  }
  // uniqueKeyValues = (arr, key, year, qtr, month) => {

  //   if (key == 'qtr') {
  //     var yearData = arr.filter((obj) => { if (obj.year == year) return obj });
  //     return [... new Set(yearData.map((obj) => { return obj[key] }))];
  //   } else if (key == 'month') {
  //     var monthData = arr.filter((obj) => { if (obj.year == year && obj.qtr == qtr) return obj });
  //     return [... new Set(monthData.map((obj) => { return obj[key] }))];
  //   }
  //   else if (key == 'fn') {
  //     var fnDAta = arr.filter((obj) => { if (obj.year == year && obj.qtr == qtr && obj.month == month) return obj });
  //     return [... new Set(fnDAta.map((obj) => { return obj[key] }))];
  //   }
  //   return [... new Set(arr.map((obj) => { return obj[key] }))];
  // }
  fnCalculateDrillDownTable = () => {
    var currentData = this.state.drillData.filter((data, index) => {
      return data.tag == 'Current'
    });
    var previosData = this.state.drillData.filter((data, index) => {
      return data.tag == 'Old'
    });
    // console.log(currentData, "drilllqqqllll111");
    var uniqueYearArr = utilsFunction.uniqueKeyValues(currentData, 'year')
    // console.log(uniqueYearArr, "drilllllllllllyear");
    var finalArr = [];
    var indexValue = 0;
    uniqueYearArr.map((year, index) => {
      indexValue++;

      var uniqueQTROfAYear = utilsFunction.uniqueKeyValues(currentData, 'qtr', year);
      console.log(uniqueQTROfAYear, "yearrrrrrrrrrr");
      var yearParentObj = {
        key: indexValue,
        name: year,
        revenue: (currentData.reduce((total, obj) => obj.year == year && (+obj.revenue_amount) + total, 0)).toLocaleString('en-IN'),
        collection: (currentData.reduce((total, obj) => obj.year == year && (+obj.collection_amount) + total, 0)).toLocaleString('en-IN'),
        revenueResult: '',
        children: []
      }
      var qtrDataArr = [];

      uniqueQTROfAYear.sort().map((qtr, index) => {
        indexValue++;
        var qtrObj = {
          key: indexValue,
          name: qtr == 1 ? 'QTR1' : 'QTR2',
          revenue: (currentData.reduce((total, obj) => obj.year == year && obj.qtr == qtr && (+obj.revenue_amount) + total, 0)).toLocaleString('en-IN'),
          collection: (currentData.reduce((total, obj) => obj.year == year && obj.qtr == qtr && (+obj.collection_amount) + total, 0)).toLocaleString('en-IN'),

          children: []
        }

        var uniqueMonthOfAQTR = utilsFunction.uniqueKeyValues(currentData, 'month', year, qtr);
        var qtrChild = []; // for month array
        uniqueMonthOfAQTR.sort().map((monthName, index) => {
          var monthRevenue = 0, monthCollection = 0;
          currentData.map((obj, index) => {
            if (obj.year == year && obj.qtr == qtr && obj.month == monthName) {
              monthRevenue += (+obj.revenue_amount);
              monthCollection += (+obj.collection_amount)
            }
          })
          indexValue++;
          var monthObj = {
            key: indexValue,
            name: utilsFunction.getMonthName(monthName),
            revenue: monthRevenue.toLocaleString('en-IN'),
            collection: monthCollection.toLocaleString('en-IN'),
            revenueResult: '',
            children: []
          }
          qtrChild.push(monthObj)
          console.log(year, qtr, monthName, "azzzzzzzzzzzz");
          var uniqueFNOfMonth = utilsFunction.uniqueKeyValues(currentData, 'fn', year, qtr, monthName);
          console.log(currentData, "fnnnnamttttnnnnnn", uniqueFNOfMonth);
          var FNArr = [];
          uniqueFNOfMonth.sort().map((fnValue, index) => {


            // console.log(gfg, "dddddddddfffff");
            indexValue++;
            var FNObj = {
              key: indexValue,
              name: fnValue == 1 ? 'Fortnight1' : 'Fortnight2',
              revenue: (_.sumBy(currentData, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr && obj.month == monthName)
                  return +obj.revenue_amount;
              })).toLocaleString('en-IN'),
              collection: (_.sumBy(currentData, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr && obj.month == monthName)
                  return +obj.collection_amount;
              })).toLocaleString('en-IN'),
              revenueResult: '',
              children: []
            }
            var RCTypeArr = [];
            var currentGrossData = this.state.grossData.filter((d, index) => {
              return d.tag == 'Current'
            })
            // console.log(this.props.countData, "lklklklklk");
            currentGrossData.map((RCType, index) => {
              indexValue++;
              var matchData = previosData.filter((data, index) => {
                if (data.category == RCType.category && data.fn == fnValue && data.year == year
                  && data.qtr == qtr
                  && data.month == monthName) {
                  return data
                }
              })

              var revenueResult = <LikeOutlined style={{ color: 'green', fontSize: 18 }} />;
              var collectionResult = revenueResult;
              // console.log(matchData.length, "mmmmmmmmmmmmmmmmmmm", matchData.revenue_amount);
              var revenue_currentSum = _.sumBy(currentData, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr
                  && obj.month == monthName && obj.category == RCType.category)
                  return +obj.revenue_amount;
              })
              var collection_currentSum = _.sumBy(currentData, function (obj) {
                if (obj.fn == fnValue && obj.year == year && obj.qtr == qtr
                  && obj.month == monthName && obj.category == RCType.category)
                  return +obj.collection_amount;
              })
              if (matchData.length > 0 && revenue_currentSum < matchData[0].revenue_amount) {
                revenueResult = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
              }
              else if (matchData.length > 0 && revenue_currentSum == matchData[0].revenue_amount) {
                revenueResult = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
              }
              if (matchData.length > 0 && revenue_currentSum < matchData[0].collection_amount) {
                collectionResult = <DislikeOutlined style={{ color: 'red', fontSize: 18 }} />
              }
              else if (matchData.length > 0 && revenue_currentSum == matchData[0].collection_amount) {
                collectionResult = <LikeOutlined style={{ color: 'orange', fontSize: 18 }} rotate={-90} />
              }


              var RCType1 = {
                key: indexValue,
                name: RCType.category,
                revenue: revenue_currentSum.toLocaleString('en-IN'),
                collection: collection_currentSum.toLocaleString('en-IN'),
                revenueResult: revenueResult,
                collectionResult: collectionResult
              }
              if (revenue_currentSum == undefined && collection_currentSum == undefined) {

              } else {
                console.log(RCType1, "rctyyyyyyyyyyyyyyyy");
                RCTypeArr.push(RCType1)
              }

            })
            FNObj.children = RCTypeArr;
            FNArr.push(FNObj)
          })
          monthObj.children = FNArr
        })

        qtrObj.children = qtrChild
        qtrDataArr.push(qtrObj)
      })
      yearParentObj.children = qtrDataArr


      finalArr.push(yearParentObj)
    })
    // console.log(finalArr, "finallllll");
    this.setState({
      dataSource: finalArr
    })
  }

  fnCalculateGraph = (countData) => {
    // console.log(countData, "counttttttttttttt");
    var filterData = countData.filter((cdata, index) => {
      return cdata.tag == 'Current'
    })

    var revenueCountValue = filterData.map(value => (+value.revenue_amount));
    var countType = filterData.map(value => value.category);

    var collectionCountValue = filterData.map(value => (+value.collection_amount));


    // console.log(revenueCountValue, revenueCountType, "revvvvvvvvvvvvv", collectionCountValue);

    // var data = [{
    //   data: countValue
    // }]
    this.setState({
      series1: [
        {
          name: "Revenue",
          data: revenueCountValue
        },
        {
          name: "Collection",
          data: collectionCountValue
        }
      ],
      options1: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          // text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        yaxis: [
          {
           
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#141414',
              },
              formatter: (value) => {
    
                // let amount;
    
                // if (currencyInfo.toLowerCase() == "₹" || currencyInfo.toLowerCase() == 'inr') {
                //   amount = parseFloat(value).toFixed(2)
                // }
                // else {
                //   amount = utilsFunction.thousands_separators(parseFloat(value).toFixed(2))
                // }
                return this.props.currencyInfo + "  " + value;
                // return currencyInfo + "  " + new Intl.NumberFormat('en-IN').format(value)
                // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value)
              },
              minWidth: 80
            },
            title: {
              text: "Amount",
              style: {
                color: '#141414',
              }
            },
            tooltip: {
              theme: "dark"
            }
          }
        ],
    
        
      }
    })
  }

  fnCalculateGraphForRevenueBreakDown = (grossData) => {

    var currentGrossData = grossData.filter((cdata, index) => {
      return cdata.tag == 'Current'
    })
    var revenueArr = currentGrossData.map(value => +value.revenue_amount);
    var collectionArr = currentGrossData.map(value => +value.collection_amount);
    var categoryArr = currentGrossData.map(value => value.category);

    // console.log(revenueArr, categoryArr, "vattttt", grossData);
    // revenueArr = currentGrossData.map(value => +value.revenue_amount);

    this.setState({
      series2: revenueArr,
      series3: collectionArr,
      options2: {
        chart: {
          width: 380,
          type: 'pie',
        },
        // labels: ['OP', 'IP', 'Emergency', 'Daycare', 'Pharmacy(Op/Walkin)', 'Misc/other/scrap'],
        labels: categoryArr,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        yaxis: [
          {
           
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#141414',
              },
              formatter: (value) => {
    
                // let amount;
    
                // if (currencyInfo.toLowerCase() == "₹" || currencyInfo.toLowerCase() == 'inr') {
                //   amount = parseFloat(value).toFixed(2)
                // }
                // else {
                //   amount = utilsFunction.thousands_separators(parseFloat(value).toFixed(2))
                // }
                return this.props.currencyInfo + "  " + value;
                // return currencyInfo + "  " + new Intl.NumberFormat('en-IN').format(value)
                // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value)
              },
              minWidth: 80
            },
            title: {
              text: "Amount",
              style: {
                color: '#141414',
              }
            },
            tooltip: {
              theme: "dark"
            }
          }
        ],
      },
    })
  }
  render() {
    return (


      <div id="chart" style={{ minHeight: 200 }}>

        <Row>
          <Col span={this.state.isChart ? 10 : 4} style={{ fontWeight: 'bold', fontSize: 15 }}>Revenue And Collection</Col>
          {
            this.state.isChart ?
              <>
                <Col span={8} style={{ fontWeight: 'bold', fontSize: 15 }}>Revenue Break Down</Col>
                <Col span={3} style={{ fontWeight: 'bold', fontSize: 15 }}>Collection Break Down</Col>
              </> :
              <>
                <Col span={1} >
                  <Button className='buttonColor buttonColor2' type="link"

                    onClick={() => {
                      this.setState({
                        isGross: true
                      }, () => {
                        this.fnCalculateGrossTable(this.state.grossData)
                      })
                    }}
                  >Gross</Button>
                </Col>
                <Col span={2} >
                  <Button className='buttonColor buttonColor2' type="link"

                    onClick={() => {
                      this.setState({
                        isGross: false
                      }, () => {
                        this.fnCalculateDrillDownTable()
                      })
                    }}
                  > Drill Down </Button>

                </Col>
              </>

          }


          {/* <Col
            // offset={this.state.isChart ? 0 : 12} 
            style={{ marginLeft: this.state.isChart ? "5.7%" : "55.70%" }}>

            <Button className='buttonColor' type="link" icon={<SearchOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>

          </Col> */}
          <Col
            // offset={this.state.isChart ? 1 : 17}
            style={{ marginLeft: this.state.isChart ? '8%' : '66.2%' }}
          >
            <Button className='buttonColor' type="link" icon={<TableOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: false
                })
              }}
            ></Button>
          </Col>
          <Col  >
            <Button className='buttonColor' type="link"
              icon={<BarChartOutlined className="tableColor" />}
              onClick={() => {
                this.setState({
                  isChart: true
                })
              }}
            ></Button>

          </Col>
        </Row>

        <Row>
          {
            this.state.isChart ?
              <>
                <Col span={8}><Chart options={this.state.options1} series={this.state.series1} type="line" height={350} /></Col>
                <Col span={8} ><Chart options={this.state.options2} series={this.state.series2} type="pie" height={280} /></Col>
                <Col span={8}><Chart options={this.state.options2} series={this.state.series3} type="pie" height={270} /></Col>

              </>
              : <>
                <Col span={24}>
                  <Table bordered scroll={this.state.dataSource.length > 5 ? {
                    y: 240, scrollToFirstRowOnChange: true
                  } : undefined} pagination={false}
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                    dataSource={this.state.dataSource} columns={this.state.columns} size='small' />
                </Col>

              </>
          }
        </Row>


      </div>


    );
  }
}


export default RevenueAndCollection;