import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Select,
  Space,
  Col,
  Button,
  Table,
  DatePicker,
  Checkbox,
} from "antd";
import { TableOutlined, BarChartOutlined } from "@ant-design/icons";
import "../../../../App.css";
import GetApiData from "../../../../utils/GetApiData";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;
const { RangePicker } = DatePicker;
const registrationCol = [
  {
    title: "Registration",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Today",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Previous Day",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Last Week",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Last Month",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Last Quarter",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Last Six months",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
  {
    title: "Year Till Date",
    dataIndex: "visit_type",
    key: "paymentMode",
    render: <div>OP</div>,
  },
];
const Registration = () => {
  const [currentView, setCurrentView] = useState("graphView");
  const [specialltyWiseChart, setSpecialtyWiseChart] = useState({
    series: [
      {
        name: "OP",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "IP",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Daycare",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "ER",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "65%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      theme: {
        mode: "light",
        palette: "palette5",
        monochrome: {
          enabled: false,
          color: "#5653FE",
          shadeTo: "light",
          shadeIntensity: 0.95,
        },
      },
      labels: ["OP", "IP", "Daycare", "ER"],
    },
  });
  const handleSwitchView = (name) => {
    setCurrentView(name);
  };
  return (
    <>
      <Row align="top" justify="space-between" className="mb-2">
        <Col lg={9} md={8} sm={12} xs={24}>
          <Row align="top" justify="space-evenly" gutter={[24, 12]}>
            <Col lg={12} md={12} sm={12}>
              <RangePicker
                onChange={(value) => {
                  console.log("value", value);
                }}
              />
            </Col>

            <Col lg={12} md={12} sm={12}>
              <Select placeholder="Bed Category" className="w-100"></Select>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={8} sm={12} xs={24}>
          <div className="text-center table-chart-heading">Registration</div>
        </Col>
        <Col lg={9} md={8} sm={12} xs={24}>
          <Row align="top" justify="space-evenly" gutter={[24, 12]}>
            <Col lg={18}>
              <Select placeholder="Registration Type" className="w-100"></Select>
            </Col>
            <Col lg={2}>
              <Button
                className="buttonColor"
                onClick={() => {
                  handleSwitchView("tableView");
                }}
                type="link"
                icon={<TableOutlined />}
              />
            </Col>
            <Col lg={2}>
              <Button
                className="buttonColor"
                onClick={() => {
                  handleSwitchView("graphView");
                }}
                type="link"
                icon={<BarChartOutlined />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {currentView === "tableView" && (
        <>
          <Row align="top" justify="space-between" gutter={[16]}>
            <Col lg={24} md={22} sm={22} xs={24}>
            <div className="text-center mb-2 table-sub-heading">
                Registration
              </div>
              <Table
                columns={registrationCol}
                dataSource={[]}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                bordered
                size="small"
              />
            </Col>
          </Row>
        </>
      )}
      {currentView === "graphView" && (
        <>
          <Row align="top" justify="space-between" gutter={[16]}>
            <Col lg={24} md={22} sm={22} xs={24}>
              <div className="text-center mb-2 table-sub-heading">
                Registration
              </div>
              <ReactApexChart
                options={specialltyWiseChart.options}
                series={specialltyWiseChart.series}
                type="bar"
                height={400}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Registration;
